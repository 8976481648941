.cards {
  .row {
    gap: 1rem;
  }

  .cards-cont {
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;

    .top-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      img {
        width: 35px;
        height: auto;
      }

      p {
        flex: 1;
        color: #00aeef;
        font-size: 16px;
        margin: 0;
        font-weight: bold;

        span {
          display: block;
          color: #ff0000;
          font-size: 10px;
          font-style: italic;
        }
      }
    }

    .bottom-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;

      p {
        color: #00aeef;
        font-weight: bold;
        margin: 0;
        padding: 0;
      }

      p.big {
        font-size: 30px;
      }
    }
  }
}
