.item-details {
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    Button.btn.close-button {
      background-color: #ff3d3d;
      border-color: #ff3d3d;
    }
  }

  h4 {
    font-weight: bold;
  }
}
