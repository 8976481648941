.recent-activites {
  height: 100%;
  display: flex;
  padding: 0;
  border-radius: 0px;
  align-items: flex-start;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;

  .header {
    background-color: #b4b4b4;
    padding: 1.5rem;
    width: 100%;

    h3 {
      color: #000;
      font-weight: bold;
      font-size: 1rem;
      padding: 0;
      margin: 0;
    }
  }

  .body {
    background-color: #fff;
    padding: 1.5rem;
    width: 100%;
  }
}
