.chart-of-account-table {
  .tableUI {
    .table {
      tbody {
        tr {
          td:nth-of-type(3) {
            font-weight: bold;
          }
          .available {
            width: 100%;
            padding: 0.5em 1em;
            color: #ffffff;
            font-size: 14px;
            border-radius: 10px;
            background: #08a502;
          }
          .waiting {
            width: 100%;
            padding: 0.5em 1em;
            color: #ffffff;
            font-size: 14px;
            border-radius: 10px;
            background: #E2BD00;
          }
          .threedots:after {
            content: "\2026";
            font-size: 30px;
          }
          .status {

          }
        }
      }
    }
  }
}
