.waitingListTable {
  .tableUI {
    .table {
      thead {
        tr {
          th {
          }
        }
      }

      tbody {
        tr {
          td:nth-of-type(2) {
            font-weight: bold;
          }
        }
      }
    }
  }
}
