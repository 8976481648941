.view-borrowed-item {
  .modal-dialog.modal-lg {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .view-borrowed-item-header {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .item-information-form {
        padding: 2em;

        .unit-item {
          padding: 0.5rem 0;
          border-bottom: solid 1px #999;
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.add-button {
            background-color: #02961c;
            border: none;
          }

          .btn.clear-button {
            background-color: #b4b4b4;
            border: none;
          }

          .btn.submit-return-button {
            background-color: #009714;
            border: none;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 1.5rem;
        }

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 1;
            margin: 0 !important;
            font-weight: bold;
            font-size: 1.2rem;
          }

          input {
            color: #006397;
            flex: 2;
            border: none;
            padding: 0;
            border-radius: unset;
            font-weight: bold;
            font-size: 1.2rem;
          }
        }

        .form-group {
          label,
          input {
            flex: 1;
            font-weight: bold;
            font-size: 1.2rem;
          }
        }

        p.status.borrowed {
          color: #000;
          background: #0097144d;
          color: #009714;
          display: block;
          padding: 1rem;
          width: min-content;
        }

        p.status.completed {
          color: #fff;
          padding: 1rem;
          display: block;
          width: min-content;
          background: #06b10c;
        }
      }
    }
  }
}
