.profile {
  .user-profile {
    height: 100%;
    display: flex;
    padding: 1.5rem;
    border-radius: 0px;
    align-items: center;
    background-color: #fff;
    // align-items: flex-start;
    justify-content: space-between;

    .image-container {
      flex: 1;
      position: relative;
      padding-right: 1rem;

      .profile-update {
        bottom: 3rem;
        display: flex;
        right: 0.5rem;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        align-items: center;
        justify-content: center;
        background-color: #00a8ff;

        label {
          width: 4rem;
          height: 4rem;
          display: flex;
          cursor: pointer;
          align-items: center;
          justify-content: center;
        }
      }

      img {
        width: 250px;
        height: 250px;
        object-fit: cover;
        border-radius: 50%;
        object-position: center;
      }
    }

    .profile-container {
      flex: 2;
      height: 100%;
      display: flex;
      padding-left: 1rem;
      flex-direction: column;
      justify-content: center;
      // border-left: solid 2px #00a8ff;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        h3 {
          margin: 0;
          padding: 0;
          font-size: 1rem;
          color: #198754;
          font-weight: bold;
        }

        .buttons-container {
          gap: 10px;
          display: flex;
          align-items: center;

          Button {
            // border: none;
            border-radius: 0;
            border-width: 2px;
            font-size: 14px;
            font-weight: 600;
            padding: 5px 15px;
            text-transform: capitalize;
            // background-color: #00a8ff;
          }

          Button.cancel-button {
            // background-color: #a2a4a6;
          }
        }
      }

      .inline-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        label {
          margin: 0;
          padding: 0;
          flex: 0 0 10rem;
          font-size: 14px;
        }

        input,
        select {
          border-radius: 0;
          /* border-top: none;
          border-left: none;
          border-right: none; */
          font-size: 14px;

          &:focus {
            box-shadow: none;
          }
        }

        .multiple-select {
          width: 100%;
        }
      }
    }
  }
}
