.leave-credits {
  .modal-content {
    .modal-header {
      background-color: #00aeef;
    }

    .modal-body {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;

      button {
        flex: 1;
      }

      .main-content {
        gap: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .vertical-align {
          flex: 1;

          label {
            font-weight: bold;
          }

          input.border-seven {
            height: 46px;
            border-radius: 7px !important;
          }
        }
      }
    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 32px;
    }

    &.btn.save {
      background-color: #07c700;
    }

    &.btn.cancel {
      background-color: #c4c4c4;
    }
  }
}