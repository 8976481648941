.view-adjustment-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .view-adjustment-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .adjustment-form {
        padding: 2em;

        .request-details {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .inline-group {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            label {
              flex: 2;
              margin: 0 !important;
              font-weight: bold;
            }

            input {
              flex: 2;
              color: #006397;

              &::placeholder {
                color: #00aeef;
              }
            }

            input.no-border {
              border: none;
              padding: 0;
              font-weight: bold;
            }
          }
        }

        .advanced-search {
          .accordion-item {
            border: none;

            .accordion-header {
              .accordion-button {
                font-weight: bold;
                padding: 1rem 1.5rem;
                color: #e9ca00;
                background-color: #fff;
                box-shadow: none;
                border-bottom: 2px solid #e9ca00;

                &.collapsed {
                  color: #000;
                  border-bottom: none;

                  &::after {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                  }
                }

                &::after {
                  margin-right: auto;
                  position: absolute;
                  left: 0;
                  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E9CA00'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                }
              }
            }

            .accordion-body {
              padding: 1rem 0;

              .tableUI {
                border-top: none;

                .table {
                  tbody {
                    tr {
                      td {
                        span {
                          display: block;
                          background-color: #00a8ff;
                          color: #fff;
                          text-align: center;
                          padding: 2px 0;
                          border-radius: 3px;
                          width: fit-content;
                          padding: 0 8px;
                          margin: unset;

                          &.new {
                            background-color: #00a8ff;
                          }

                          &.in-progress {
                            background-color: #e49f00;
                          }

                          &.for-approval {
                            background-color: #016aa1;
                          }
                        }

                        .view-button {
                          text-transform: capitalize;
                          display: block;
                          background-color: #00a8ff;
                          border: none;
                          color: #fff;
                          text-align: center;
                          padding: 2px 0;
                          border-radius: 3px;
                          width: fit-content;
                          padding: 0 10px;
                          margin: unset;
                          min-width: 90px;

                          &.no-image {
                            background-color: #6c7478;
                            cursor: not-allowed;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .receiving-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: solid 1px #212529;
        padding-top: 1rem;

        h3,
        h4 {
          font-weight: bold;
          font-size: 20px;
        }

        p {
          text-align: left;
          font-weight: bold;
          width: 100%;

          span {
            display: block;
            color: #ff5d5d;
          }
        }

        label {
          font-weight: bold;
        }
      }
    }
  }
}
