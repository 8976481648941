label {
    font-size: 12px;
}

select,
input,
textarea {
    border-radius: 0 !important;
    font-size: 14px !important;
}

.buttons-cont {
    display: flex;
    justify-content: space-between;

    button {
        border-radius: 3px;
        font-size: 14px;
    }
}