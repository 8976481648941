.createInpatientBedTable {
  .tableUI {
    .table {
      thead {
        tr {
          th {
          }
        }
      }

      tbody {
        tr {
          td:last-child {
            button {
              color: #00a8ff;
            }
          }
        }
      }
    }

    .status {
      padding: 0.5em 0.75em;
      color: #ffffff;
      font-size: 14px;
      border-radius: 10px;
    }

    .status--available {
      background: #08a502;
    }

    .status--cleaning {
      background: #00dcff;
    }

    .status--discharge {
      background: #ff5b5b;
    }

    .status--occupied {
      background: #8cd684;
    }

    .form-control:disabled {
      background: #ffffff;
    }
  }
}
