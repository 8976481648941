.view-request-for-repair-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }
  .align-items-center {
    align-self: center;
  }
  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .view-request-for-repair-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .adjustment-form {
        padding: 2em;
        .image-container {
          height: 350px;
          width: 350px;
        }
        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 2;
            margin: 0 !important;
            font-weight: bold;
          }

          input {
            flex: 2;

            &::placeholder {
              color: #00aeef;
            }
          }

          input.no-border {
            border: none;
            padding: 0;
          }
        }

        hr {
          background-color: #00a8ff;
          height: 2px;
        }

        .form-checkbox {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .form-check {
            margin-bottom: 25px;
          }
        }

        .bg-grey {
          background-color: #d6d6d6;
        }

        .uploaded-image {
          width: 266px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: auto;

          label {
            text-align: center;
            font-weight: bold;
            font-size: 15px;

            span {
              display: block;
              font-weight: normal;
              font-size: 12px;
            }
          }

          .image {
            padding: 1rem 2rem;
            border: solid 1px #c8c8c8;
            border-radius: 10px;
            position: relative;
            z-index: 10;
            background: #fff;

            img {
              width: 200px;
            }
          }

          Button.btn.btn-primary {
            background-color: #00a8ff;
            text-transform: capitalize;
            border: none;
            width: 100%;
            position: relative;
            top: -14px;
            z-index: 9;
            padding-top: 19px;
            font-size: 12px;
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-start;
          gap: 1em;
          height: 100%;
          align-items: flex-end;

          button.btn.btn-primary {
            border: none;

            &.print {
              background-color: #ff0000;
            }

            &.cancel {
              background-color: #b4b4b4;
            }
          }
        }

        label {
          font-weight: bold;
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-start;
          gap: 1em;
          height: 100%;
          align-items: flex-end;

          button.btn.btn-search {
            margin-bottom: 13px;
          }
        }

        .header {
          p {
            font-weight: bold;
          }
        }

        .tableUI {
          border-top: none;

          h3 {
            font-weight: bold;
            font-size: 20px;
            margin: 0;
            padding: 1rem 0 0;
          }
        }

        .table {
          tbody {
            td {
              span {
                display: block;
                background-color: #00a8ff;
                color: #fff;
                text-align: center;
                padding: 2px 0;
                border-radius: 7px;
                width: fit-content;
                padding: 0 10px;
                margin: 0 auto;
                min-width: 90px;

                &.receiving {
                  background-color: #06b10c;
                }

                &.in-progress {
                  background-color: #e49f00;
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.goods-receipt {
            background-color: #07c900;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            border: none;
          }
        }

        .adjustment-item-list {
          label,
          h6 {
            font-weight: bold;
          }
        }

        .count-control {
          display: flex;
          align-items: flex-end;
          height: 100%;
          gap: 10px;
          justify-content: flex-start;

          svg {
            margin-bottom: 20px;
            cursor: pointer;
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;
          height: 100%;
          align-items: flex-end;

          button.btn.btn-primary {
            border: none;

            &.print {
              background-color: #ff0000;
            }

            &.save {
              background-color: #06b10c;
            }

            &.cancel {
              background-color: #b4b4b4;
            }
          }
        }
      }

      .receiving-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: solid 1px #212529;
        padding-top: 1rem;

        h3,
        h4 {
          font-weight: bold;
          font-size: 20px;
        }

        p {
          text-align: left;
          font-weight: bold;
          width: 100%;

          span {
            display: block;
            color: #ff5d5d;
          }
        }

        label {
          font-weight: bold;
        }
      }
    }
  }
}
