.diagnosisFilter {
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;

    & > button {
      margin-left: 1em;
    }
  }
}
