.visitInformationTab {
  .patientProfile__header--visitInformation {
    h4 {
      margin: 0;
      font-weight: bold;
    }

    .align-items-center.justify-content-between.d-flex.current-medical-information {
      border: none;
      padding: 0;
    }
    
    button.btn.btn-addField {
      border: #07c900;
    }

    .patientProfile__content {
      .wrapper.visit-information {
        height: 100%;
        align-items: center;
      }

      span.value {
        input.false.form-control {
          background: #fff;
          border: none;
        }
      }

      span.value {
        select.false.form-select {
          background: #fff;
          border: none;
        }
      }
    }

    .save-cont {
      display: flex;
      margin-bottom: 1rem;
      justify-content: flex-end;

      Button.btn {
        background-color: #07c900;
        border: none;
      }
    }
  }

  .tableUI {
    border: none;

    .healthCareTeamTable {
      tbody {
        tr {
          td:nth-of-type(2) {
            font-weight: bold;
          }
        }
      }
    }

    .payorTable {
      tbody {
        tr {
          td {
            background: #efefef;
          }

          td:first-child {
            button {
              svg {
                width: 18px;
                margin-right: 1em;
              }
            }
          }

          td:nth-of-type(2) {
            font-weight: bold;
          }
        }

        tr:nth-of-type(2n) {
          td {
            padding: 0;
            background: #ffffff;
          }
        }
      }
    }

    .doctorsNotesTable,
    .nursesNotesTable {
      tbody {
        tr {
          td:first-child {
            font-weight: bold;
          }

          td:last-child {
            width: 1%;

            button {
              width: 100%;

              svg {
                width: 24px;
              }
            }
          }
        }
      }
    }
  }

  .payorData__collapse {
    width: 100%;
    padding: 1em;
  }
}
