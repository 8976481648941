@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.sidebar {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100px;
  border-top-right-radius: 25px;
  max-width: 20%;
  background: #6ac9de;
  z-index: 1000;

  .sidebar__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 320px;
    margin-bottom: 2em;

    .header-cont {
      display: flex;
      align-items: center;

      .hide-sidebar {
        padding: 0;
        width: auto;
        background: none;
        border: none;
        display: none;

        svg {
          width: 1.5rem;
          margin: auto;
        }
      }
    }

    .sidebar__brand {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 74px;
      padding: 1em;
      color: #ffffff;
      font-size: 14px;
    }

    .sidebar__logo {
      width: 100%;
      padding: 0 2em;
    }
  }

  &__content {
    height: calc(100vh - 230px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #6ac9de;
    }

    &::-webkit-scrollbar-thumb {
      background: #35626e;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #35626e;
    }
  }

  .nav {
    .nav-item {
      width: 100%;
      margin: 0.125em 0;

      .nav-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0.5em 1.5em;
        margin-top: 1em;

        svg {
          width: 30px;
          margin-right: 1em;
          fill: #ffffff;
        }

        span {
          color: #ffffff;
          line-height: 110%;
        }
      }
    }

    .nav-item.active {
      background: #ffffff;
      border-right: 5px solid #35636e;

      .nav-link {
        svg {
          fill: #6ac9de;
        }

        span {
          color: #6ac9de;
        }
      }
    }

    h3 {
      font-family: "Poppins", sans-serif;
      font-weight: 800;
      color: #fff;
      font-size: 12px;
      margin: 1rem 0;
      padding: 0.5rem 1.5rem;
    }

    .dropdown-nav {
      width: 100%;

      .accordion-item {
        background: none;
        border: none;

        .accordion-button {
          background: none;
          color: #fff;
          padding: 5px 1rem;

          .nav-link {
            color: #fff;
          }

          &::after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          }

          &:not(.collapsed)::after {
            background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          }
        }

        .accordion-body {
          padding: 1rem 0;

          .nav-item {
            a.nav-link {
              color: #fff;
              padding: 0.5em 1.25em;

              p.item-and-total {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                margin: 0;

                span {
                  font-size: 12px;
                  background-color: #6ac9de;
                  padding: 4px;
                  border-radius: 4px;
                }
              }

              p.item-and-total.Denied.Users {
                span {
                  background-color: #a70515;
                }
              }

              p.item-and-total.New.Users {
                span {
                  background-color: #35a100;
                }
              }
            }
          }

          .active.nav-item {
            a.nav-link {
              color: #00a8ff;

              p.item-and-total {
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    max-width: 300px;

    .sidebar__header {
      margin-bottom: 1rem;

      .sidebar__brand {
        height: 55px;
        font-size: 12px;
      }

      .sidebar__logo {
        width: 100%;
        padding: 0 2em;
        max-width: 15rem;
      }
    }

    &__content {
      height: calc(100vh - 195px);
      overflow-y: auto;
    }

    .nav {
      .nav-item {
        .nav-link {
          padding: 0.5em 1.25em;

          svg {
            width: 25px;
          }

          span {
            font-size: 14px;
          }
        }
      }

      .dropdown-nav {
        .accordion-item {
          .accordion-body {
            .nav-item {
              a.nav-link {
                padding: 0.5em 1.25em;
              }
            }
          }
        }
      }
    }

    .dropdown-nav {
      .accordion-item {
        .accordion-header {
          .accordion-button {
            .svg__wrapper {
              svg {
                width: 25px;
              }
            }
          }

          .nav-link {
            font-size: 14px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    position: absolute;
    left: 0;
    z-index: 999;

    .sidebar__header {
      .header-cont {
        justify-content: space-between;
        width: 100%;

        .hide-sidebar {
          display: block;
          margin: 0 1rem;

          svg {
            width: 1.5rem;
            margin: auto;
          }
        }
      }
    }
  }
  

  #sidebar-footer {
    position: relative;
    top: 13rem;
    color: #fff;
    font-weight: bold;
    margin-left: 4rem;
   
  }


}