.item-details {
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    Button.btn.close-button,
    Button.btn.cancel-button {
      background-color: #b4b4b4;
      border-color: #b4b4b4;
    }

    Button.btn.return-button {
      background-color: #ff616c;
      border: #ff616c;
    }

    Button.btn.save-button {
      background-color: #00a8ff;
      border: #00a8ff;
    }
  }

  h4 {
    font-weight: bold;
  }

  .form-group {
    label {
      font-weight: bold;
    }
  }

  hr {
    border-top: solid 2px #000;
  }

  .content-container {
    padding: 2rem;

    h3 {
      font-weight: bold;
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    p {
      font-weight: bold;

      span {
        color: #006397;
      }
    }

    .returned-container {
      text-align: center;

      h3 {
        margin-bottom: 1.5rem;
      }

      .reason {
        color: #FF0012;
      }

      .form-group {
        text-align: left;
      }

      .returned-by {
        color: #006397;
      }
    }
  }
}
