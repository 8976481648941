.Filter {
    .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        gap: 1em;
    }

    label {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }

    .basic-search {
        .bg-grey.form-control {
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }

        .btn.btn-search {
            margin-left: 1em !important;
            border-radius: 10px !important;
        }
    }

    .advanced-search {
        .accordion-item {
            border: none;

            .accordion-header {
                .accordion-button {
                    font-weight: bold;
                    padding: 1rem 1.5rem;
                    color: #00a8ff;
                    background-color: #fff;
                    box-shadow: none;
                    border-bottom: 2px solid #000;

                    &.collapsed {
                        color: #000;
                        border-bottom: none;
                    }

                    &::after {
                        margin-right: auto;
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }

    .form-check {
        position: relative;

        input {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
        }
    }
}