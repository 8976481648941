.addNewServiceModal {
  .modalUI {
    .modal-dialog {
      max-width: 720px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      background: #00a8ff;

      h4 {
        margin: 0;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .modal-body {
      padding: 2em;
    }
  }

  .addNewServiceModal__body {
    padding: 3em 2em;
    border: 1px solid #00a8ff;
    border-radius: 10px;

    h6 {
      margin: 0;
      text-align: center;
    }
  }
}
