.dashboardBanner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  width: 100%;
  max-width: 97vw;
  height: 25%;
  min-width: 400px;
  margin-top: 6%;
  // margin-left: 18%;

  @media (max-width: 1200px) {
    max-width: 95vw;
    width: 100%;
    justify-content: center;
    height: 19%;
    margin-top: 8%;

    @media (max-width: 960px) {
      margin-top: 10%;
    }
  }

  &>.dashboardBanner__logo {
    width: 140px;

    // @media (max-width: 1100px) {
    //   display: none;
    // }
  }

  &>h1 {
    margin: 0 1em 0 0;
    color: #ffffff;
    line-height: 60px;
    font-size: 40px;
    font-weight: 900;
  }

  &>span {
    font-size: 32px;
    font-weight: 600;
    line-height: 48px;
    margin-right: 1em;
  }

  &>svg {
    display: flex;
    position: static;
    // flex-grow: 1;
    // width: 340px;
    width: 20%;
    margin-left: 10%;

    // @media (max-width: 1300px) {
    //   display: none;
    // }
  }
}