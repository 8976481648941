.roomReservationTable {
  .tableUI {
    .table {
      thead {
        tr {
          th {}
        }
      }

      tbody {
        tr {
          td {}

          td:nth-of-type(3) {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.check {
  cursor: pointer;
  fill: #00c41a !important;
}