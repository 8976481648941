.containerSignup {
  width: 100%;
  max-width: 768px;

  .containerSignup__header {
    width: 100%;
    margin-bottom: 2em;

    .containerSignup__logo {
      width: 100%;
    }
  }

  .containerSignup__body {
    width: 100%;
    padding: 2em;
    border-radius: 20px;
    background: #ffffff;
  }
}
