.items-for-replenishment {
    .submit-request {
        display: flex;
        justify-content: flex-end;
        
        button.btn {
            background-color: #07C900;
            border: none;
        }
    }
}
.purchase-button{
    border: none;
    background-color: #07C900;
}