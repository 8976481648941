.view-status-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .view-status {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .view-status-form {
        padding: 2em;

        .patient-profile {
          background-color: #00a8ff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 1.5em;
          border-radius: 10px;
          flex-direction: column;

          h3,
          p {
            margin: 0;
            color: #fff;
          }

          h3 {
            font-weight: bold;
            font-size: 30px;
          }

          p {
            font-size: 16px;
            font-weight: normal;
          }

          .first-row,
          .second-row {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          }

          .second-row {
            h3 {
              font-size: 16px;
              font-weight: normal;
            }
          }
        }

        h2 {
          font-size: 20px;
          font-weight: bold;

          &.header {
            border-bottom: 1px solid #00a8ff;
            padding-bottom: 10px;
          }
        }

        label {
          font-weight: bold;
        }

        input.bg-gray {
          background-color: #C8C8C8;
        }
      }
    }
  }
}
