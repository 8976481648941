.bedManagementList {
  margin-bottom: 0.5em;

  .filterUI {
    gap: 1rem;
    flex-wrap: wrap;

    &>div {
      flex-grow: 1;
      max-width: 200px;
      flex-basis: 200px;
    }

    .buttonWrapper {
      flex-grow: 1;
      display: flex;
      max-width: 217px;
      flex-basis: 217px;
      flex-wrap: nowrap;
      align-self: flex-end;
      align-items: flex-end;
      justify-content: space-between;

      &>button {
        width: 100%;
      }

      &>button:first-child {
        margin-right: 0.75em;
      }
    }
  }
}