

.ACLP-content {
    border: 1px solid #6e6e6e23 ;
    border-radius: 10px;
    padding: 20px;
    box-shadow: -7px 7px 10px rgba(128, 128, 128, 0.202);
}


.ACLP-s-header {
    color: #acacac;
    font-weight: 300 !important;
}

.Back-btn {
    position: relative;
    right: 15px;
    border: none;
    background-color: transparent;
    color: #6BC9DD;

}

.Back-icon {
    margin-bottom: 2px;
    font-size: 30px;
    color: #6BC9DD;
}