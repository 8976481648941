.physicalDischargeModal {
  .modalUI {
    .modal-dialog {
      max-width: 720px;
      top: 0;
    }

    .modal-header {
      display: flex;
      align-items: center;
      background: #00a8ff;

      h4 {
        margin: 0;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .modal-body {
      padding: 2em;
    }
  }

  .physicalDischargeModal__header {
    padding: 1em;
    border: 1px solid #00a8ff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    h1 {
      margin: 0;
      color: #ff0000;
      font-weight: bold;
    }
  }

  .physicalDischargeModal__body {
    padding: 1em;
    border: 1px solid #00a8ff;
    border-top: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    .confirmation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 2em 0;

      .form-label {
        text-align: center;
      }

      .form-control,
      .form-select {
        max-width: 70%;
        margin: 0 auto;
      }
    }

    .confirmation.inline-group {
      margin: 1em 0;
      flex-direction: row;

      label {
        flex: 1;
        margin: 0;
        text-align: left;
      }

      .select-condition {
        flex: 1;
      }
    }

    .newborn-container {
      // max-height: 10rem;
      // overflow-y: scroll;
    }
  }
}
