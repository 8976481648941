.cash-flow-statement {
  padding: 1rem 0;

  .cash-flow-details {
    padding: 1em 0 0;


    .asset-name::after {
      content: ":";
      float: right;
    }

    h5 {
      font-size: 15px;
      font-weight: bold;
    }

    .bold {
      width: 100%;
      display: block;
      height: 1px;
      background-color: #000;
    }

    .hr {
      border-bottom: solid 1px #707070;
      width: 100%;
      height: 1px;
    }

    .hr-bottom {
      border-bottom: solid 2px #707070;
      width: 100%;
      height: 1px;
    }

    .inline-label {
      position: relative;
      width: 100%;

      label {
        position: absolute;
        background-color: #fff;
        font-size: 13px;
        top: -9px;
        left: 14px;
        font-weight: bold;
      }
    }

    .icon {
      svg {
        width: 2em;
        height: 2em;
        cursor: pointer;
      }
    }

    .inline-label.width-80 {
      width: 80%;
      margin-left: auto;
    }

    .inline-label.width-70 {
      width: 70%;
    }

    .left-auto {
      margin-left: auto;
    }

    .right-auto {
      margin-right: auto;
    }

    .total-cont {
      p {
        display: inline-flex;
        gap: 1rem;
        margin: 0;
        font-weight: bold;
        font-size: 25px;

        span {
          color: #ff0000;
          font-size: 25px;
        }
      }
    }

    .tableUI {
      border-top: none;

      .table {
        td.less-padding {
          padding: 0 1em !important;

          .input-form {
            display: block;
            height: auto;
            padding: 5px;
            border-radius: 10px;
            border: solid 1px #b4b4b4;
            width: 80%;
          }
        }
      }
    }
  }
}
