.employees-list {
  .tabsUI {
    .nav.nav-tabs {
      border: none;

      .nav-item {
        margin-right: 0.5em;
      }

      .nav-link {
        color: #b4b4b4;
        font-size: 15px;
        background-color: transparent;
        border: none;
        padding: 1em;
      }

      .nav-link:hover,
      .nav-link:focus,
      .nav-link:active {
        border-color: transparent;
      }

      .nav-link.active {
        color: #00a8ff;
        border-color: #00a8ff;
        border: none;
        border-bottom: 4px solid #00a8ff;
        background-color: transparent;
      }
    }

    .tab-content {
      .tab-pane.active {
        padding: 0;
      }
    }
  }

  .employee-tab {
    .add-employee-btn {
      color: #fff;
      background-color: #00aeef;
      border-color: #00aeef;
      padding: 5px 16px;
      // height: 50px;
    }
  }

  .add-employee,
  .edit-employee {
    h3 {
      background-color: #00aeef;
      color: #fff;
      text-transform: uppercase;
      padding: 1rem 2rem;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      font-size: 20px;
    }

    .employee-details {
      padding: 0 1rem;

      h5 {
        color: #00aeef;
        font-size: 20px;
      }

      h6 {
        color: #000000;
        font-size: 17px;
      }

      .inline-label {
        position: relative;
        width: 100%;

        label {
          position: absolute;
          background-color: #fff;
          font-size: 13px;
          top: -9px;
          left: 14px;
        }
      }

      .inline-label.width-80 {
        width: 80%;
        margin-left: auto;
      }

      .inline-label.width-70 {
        width: 70%;
      }

      .left-auto {
        margin-left: auto;
      }

      .right-auto {
        margin-right: auto;
      }

      .file-upload {
        input {
          display: none;
        }

        label {
          z-index: 99;
        }

        label.header-text {
          top: -19px;
        }

        .upload {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: relative;
          z-index: 10;
          border: solid 1px #b2b2b2;
          border-radius: 10px;
          height: 50px;
          left: 0;

          span {
            background-color: #07c700;
            border-radius: 0 10px 10px 0;
            color: #fff;
            padding: 0 2rem;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }

      .profile-upload {
        display: flex;
        height: 100%;
        justify-content: center;

        input {
          display: none;
        }

        label {
          display: block;
          cursor: pointer;
          position: static;
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        border-top: solid 1px #e4e4e4;
        padding-top: 1rem;

        button.btn.save-btn {
          background-color: #07c700;
          color: #fff;
          border-color: #07c700;
        }

        button.btn.cancel-btn {
          background-color: #c4c4c4;
          color: #fff;
          border-color: #c4c4c4;
        }
      }
    }
  }

  .employee-profile {
    .profile-header {
      box-shadow: 0 0.125rem 60px rgba(0, 0, 0, 0.075);
      display: flex;
      flex-direction: column;
      border-radius: 0 0 10px 10px;

      img {
        height: 120px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
      }
    }

    .header-information {
      background-color: #fff;
      padding: 0 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0 0 10px 10px;

      .profile-picture {
        width: 130px;
        height: 130px;
        position: relative;
        top: -50px;
      }

      .p-header {
        text-align: center;
        font-weight: bold;
        margin: 0;

        span {
          color: #00aeef;
          display: block;
          font-weight: normal;
        }
      }

      .go-back {
        color: #00a8ff;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        margin-top: 5rem;
      }
    }

    .employee-details {
      border-radius: 10px;
      box-shadow: 0 0.125rem 60px rgba(0, 0, 0, 0.075);
      padding: 1rem;

      .tabsUI {
        position: relative;
      }

      .edit-profile-cont {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button.btn.edit-profile {
          background-color: #1ac100;
          color: #fff;
          border: none;
        }
      }

      .employee-profile-tab,
      .leave-credits-tab {
        h4 {
          font-size: 18px;
        }

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 2;
            margin: 0 !important;
            font-weight: bold;
          }

          input {
            flex: 2;

            &::placeholder {
              color: #00AEEF;
            }
          }

          input.no-border {
            border: none;
            padding: 0;
          }
        }

        .input.no-border {
          border: none;
          padding: 0;
        }
      }

      .shifting-tab {
        .shifting-schedule {
          margin: 0;
        }

        .tableUI {
          border: none;

          .table {
            margin: 10px 0 1em;
          }
        }
      }
    }
  }
}

.employee-calendar {
  .react-calendar {
    margin: auto 0;
    width: 100%;
    border: none;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;

    .react-calendar__navigation {
      .react-calendar__navigation__label {
        background-color: #00aeef;

        span {
          color: #fff;
        }
      }

      .react-calendar__navigation__prev2-button,
      .react-calendar__navigation__prev-button,
      .react-calendar__navigation__next-button,
      .react-calendar__navigation__next2-button {
        font-size: 30px;
        // background-color: #00aeef;
        color: #00aeef;
        border-radius: 50%;
      }
    }

    .react-calendar__viewContainer {
      .react-calendar__month-view {
        .react-calendar__tile--active {
          background-color: #00aeef;
        }
      }
    }
  }
}

.recent-activities {
  background-color: #fff;

  h3 {
    color: #fff;
    background-color: #00aeef;
    font-size: 20px;
    padding: 10px;
    border-radius: 7px 7px 0 0;
  }

  .list {
    ul {
      margin: 0;
      padding: 1rem 0;

      li {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: space-around;

        div {
          p {
            margin: 0;
          }
        }
      }
    }
  }
}