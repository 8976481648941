.operation-report {
  background-color: #ffffff;
  .header {
    display: flex;
    text-align: center;
    padding: 1em 1.5em;
  }

  .body {
    .tableUI table {
      margin: 0 auto !important;
    }
  }
}
