.patientDisbursementTable {
  .tableUI {
    .table {
      thead {
        tr {
          th {}
        }
      }

      tbody {
        tr {
          td {}

          td:nth-of-type(3) {
            font-weight: bold;
          }
        }
      }
    }
  }

  .btn-disburse {
    font-size: 12px;
    border: none;
    border-radius: 2px;
    padding: 5px 8px;

    background-color: #00A8FF;

    &:hover {
      border: none;
      background-color: #00A8FF;
    }

    &:disabled {
      background-color: #D5D5D5;
      cursor: not-allowed;
    }

  }
}

.check {
  cursor: pointer;
  fill: #00c41a !important;
}

