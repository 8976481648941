.diagnosis-list-table {
  .tableUI {
    .table {
      tbody {
        tr {
          td:nth-of-type(3) {
            font-weight: bold;
          }

          td {
            span.badge.blue {
              background-color: #0069f2;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
      }
    }
  }

  .bg-success {
    background: #06b10c !important;
  }
}
