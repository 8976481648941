.medical-history {
    h3 {
      color: #00a8ff;
      font-weight: bold;
      background: none;
      border: none;
      position: relative;
      font-size: 1.2rem;
      text-transform: capitalize;
    }

    Button.btn.back-button {
      color: #00a8ff;
      font-weight: bold;
      background: none;
      border: none;
      position: relative;
      text-transform: capitalize;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        margin: auto;
        top: 0;
        border-right: 12px solid #00a8ff;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .avatar-container {
        border-radius: 50%;
        border: solid 2px #707070;
        padding: 1rem;

        svg {
          height: 160px;
          width: 160px;
          object-fit: contain;
        }
      }

      .profile-name {
        text-align: center;

        h4 {
          margin: 0 0 0.5rem;
          font-size: 2rem;
        }

        h6 {
          font-size: 1.5rem;
          margin: 0 0 1rem;
          text-transform: capitalize;
          font-weight: bold;

          span {
            color: #00a8ff;
          }
        }

        .info {
          display: flex;
          justify-content: space-around;
          align-items: center;
          gap: 1rem;

          p {
            margin: 0;
            font-weight: bold;
          }
        }
      }
    }

    .card-container {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      label {
        background-color: #00a8ff;
        display: block;
        color: #fff;
        font-weight: bold;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        margin-bottom: 1rem;
      }

      .card {
        border-radius: 5px;
        border: solid 1px #00a8ff;
        padding: 1rem;
        cursor: pointer;

        p {
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-weight: bold;

          span {
            font-size: 2rem;
          }

          span.small {
            font-size: 1.2rem;
            line-height: 1;
          }
        }

        .arrow {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          svg {
            width: 20px;
          }
        }
      }
    }
  }