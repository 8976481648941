
.dropdown-button {
    border: none;
    border-radius: 25px;
    background-color: white;
    height: 30px;
    width: 210px;

    &:hover {
        box-shadow: 0px 7px 10px rgba(128, 128, 128, 0.326);
      }
}

.dropdown-options {
    position: absolute;
    background-color: white; // Background color of the menu
    border: 1px solid #cccccc76; // Optional: Add a border for better visual separation
    width: 100%;

    border-top: white;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    margin-top: 4px;
    padding: 5px;

    box-shadow: 0px 7px 10px rgba(128, 128, 128, 0.326);
  }
  
  .dropdown-item {
    // padding: 4px;
    line-height: 30px;
    padding-left: 15px;
    cursor: pointer;
  

    &:hover {
      background-color: #6DC9DD80; // Background color on hover
      border-radius: 30px;
    //   margin-left: 5px;
    }
  }

  .separator {
    position: relative;
    right: 7px;
    border-bottom: 1px solid #6dc9dd58;

  }