.overtime-list {
  .modal-content {
    .modal-header {
      background-color: #00aeef;
    }

    .modal-body {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;
      
      .main-content {
        gap: 5rem;
        width: 100%;
        justify-content: space-between;

        .vertical-align {
          flex: 1;

          label {
            font-weight: bold;
          }

          input.border-seven {
            height: 46px;
            border-radius: 7px !important;
          }
        }

        .accordion-header {
          font: normal normal medium 15px/25px Poppins;
          letter-spacing: 0px;
          opacity: 1;
          background: #00A8FF 0% 0% no-repeat padding-box;
          width: 100%;
          height: 2%;
          padding: 0;
          .accordion-button,
          .accordion-button.collapsed {
            padding-left: 2%;
            color: #00aaff;
            border-radius: 5px;
            background: none;
            box-shadow: none;
        }
        }
        .accordion-body {
          padding-left: 2%;
          margin: 0;
          width: 100%;
          flex: 1;
          
          .badge {
            font-size: 12px;
            color: #3c3c3c;

            &.green {
              background: #5aca57;
              color: #047400;
              font-size: 16px;
              margin-top: -5%;
              width: 100px;
              padding: 10%;
            }

            &.red {
              background-color: #FF8282;
              color: #700000;
              font-size: 16px;
              width: 100px;
              padding: 10%;
              margin-top: -5%;
            }

            &.yellow {
              background-color: #FFCC00;
            }
          }

        }
        .link {
          color: #00A8FF;
          font-weight: bold;
          background: none;
          text-decoration: underline;
        }
      }
    }
  }

  h6 {
    margin: 0;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    padding: 1%;
  }

  h5 {
    margin: 0;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    font: normal normal medium 15px/25px Poppins;
    letter-spacing: 0px;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 33px;
      justify-content: end;
    }

    &.btn.save {
      background-color: #07c700;
    }

    &.btn.cancel {
      background-color: #c4c4c4;
    }
  }
  .bg-success {
    background: #06b10c !important;
    width: 100px;
    padding: 3%;
    margin-right: 10px;
    margin-top: -4%;
  }
  .bg-danger {
    background: #FF0000 !important;
    width: 100px;
    padding: 3%;
    margin-top: -4%;
  }
}