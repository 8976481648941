.other-details {
  background-color: #fff;
  border-radius: 0px;
  padding: 1.5rem;
  display: flex;
  // align-items: flex-start;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 1rem;

  .text-container {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h3 {
        color: #198754;
        font-weight: bold;
        font-size: 1rem;
        padding: 0;
        margin: 0;
      }

      .buttons-container {
        gap: 10px;
        display: flex;
        align-items: center;

        Button {
          // border: none;
          border-radius: 0;
          border-width: 2px;
          font-size: 14px;
          font-weight: 600;
          padding: 5px 15px;
          text-transform: capitalize;
          // background-color: #00a8ff;
        }

        Button.cancel-button {
          // background-color: #a2a4a6;
        }
      }
    }

    .inline-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      label {
        flex: 0 0 16rem;
        padding: 0;
        margin: 0;
        font-size: 14px;
      }

      input,
      select {
        border-radius: 0;
       /*  border-left: none;
        border-top: none;
        border-right: none; */
        font-size: 14px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}
