.formLogin {
  .formLoginUI {
    .formContent {
      .form__body {
        .form-control {
          height: 56px;
          border-radius: 0 10px 10px 0;
        }

        .no-right-border {
          border-radius: none;
        }

        input[type='password']::-ms-reveal,
        input[type='password']::-ms-clear {
          display: none;
        }

        input::-ms-reveal,
        input::-ms-clear {
          display: none;
        }
      }
    }
  }
}
