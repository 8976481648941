.order-request-form {
  .tableUI {
    border-top: none;

    tbody {
      tr {
        td {
          select.unit-type-select.form-select {
            padding: 5px 7px;
            font-size: 12px;
            width: 7rem;
            display: block;
          }

          font-size: 12px;
        }
      }
    }

    .filter {
      .buttons {
        gap: 10px;

        Button.btn.btn-primary {
          background-color: #c8c8c8;
          border: none;
          color: #fff;

          &.active {
            background-color: #0079c9;
          }
        }
      }
    }
  }
}
