.item-details-modal {
  .modal-dialog.modal-lg {
    max-width: 900px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .item-details-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .item-details-form {
        padding: 2em;

        h3 {
          font-size: 20px;
          font-weight: bold;
        }

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 2;
            margin: 0 !important;
            font-weight: bold;
          }

          input {
            flex: 2;
            color: #006397;
            font-weight: bold;
            background: transparent;

            &::placeholder {
              font-weight: bold;
              color: #006397;
            }
          }

          input.no-border {
            border: none;
            padding: 0;
          }

          .quantities-header {
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          .quantities {
            display: flex;
            flex-direction: column;
            flex: 2;
          }
        }

        .item-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .buttons {
            display: flex;
            align-items: center;
            gap: 1rem;

            .edit-item {
              background-color: #009714;
              border: none;
            }

            .price-update {
              background-color: #00a8ff;
              border: none;
            }
          }
        }
      }
    }
  }
}
