.dashboardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em;
  padding: 1em;
  border-radius: 10px;
  background: #ffffff;

  span {
    font-size: 15px;
  }
}
