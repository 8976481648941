.appointmentViewDetails {
  p {
    font-size: 0.9em;
  }
  .title {
    color: #00a8ff;
    font-weight: bold;
  }
  .details {
    color: #006397;
    font-weight: bold;
    text-transform: uppercase;
  }
}
