@media print {
    @page {
        margin: 1.5cm 1cm;
    }

    .print_body {
        // padding: 20px;
        header {
            display: flex;
            // justify-content: center;
            align-items: center;
            flex-direction: column;

            .header_logo {
                margin: 15px auto;
                img {
                    text-align: center;
                    width: 170px;
                    height: auto;
                }
            }
            
            .header_title {
                font-weight: bold;
                font-size: 16px;
                color: black
            }

            .header_subtitle {
                margin-top: 5px;
                font-weight: bold;
                font-size: 14px;
                color: black
            }
        }

        table {
            font-size: 12px;
            page-break-inside: auto;
            // border: 1px solid #dee2e6;
    
            /* thead {
                background-color: #00a8ff;
                border: 1px solid #00a8ff;
            } */
    
            thead > tr > th {
                // border-right: 1px solid #dee2e6;
                text-align: center;
                font-weight: bold;
                color: black;
                vertical-align: middle;
            }
    
            tbody > tr > td {
                // border-right: 1px solid #dee2e6;
                font-weight: 500;
            }
        }
    }
}