.acl-header {
  .header-cont {
    background-color: #00a8ff;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;

    img {
        width: 70px;
        height: 70px;
    }

    .hr {
        background-color: #fff;
        width: 2px;
        height: 90%;
        border: none;
        display: block;
        margin: auto 0;
        color: #fff;
    }

    .text-header {
        h1 {
            color: #fff;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            margin: 0;
            padding: 0;
            font-weight: bold;

            span {
                display: block;
            }
        }
    }

    .doctor-header {
        position: absolute;
        right: 0;
        bottom: 0;
        img {
            width: 125px;
            height: auto;
        }
    }
  }
}
