.waitingListFilter {
  margin-bottom: 0.5em;

  .filterUI {
    justify-content: flex-start;

    & > div {
      flex: 1 0 20%;
      max-width: 20%;
    }
  }
}
