.diagnosis-request-modal {
  .modal-content {
    max-width: 100%;
    .modal-header {

      padding: 30px;
      display: flex;
      align-items: center;
      background: #00a8ff;

      h4 {
        margin: 0;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .modal-body {
      display: block;
      width: 100%;
      padding: 2em;

      .modal__body--wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.9em;

        .select {
          max-width: 23rem
        }

        .select__radio--wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          .btn--radio-flex {
            display: flex;
            align-items: center;
            gap: 1em;
          }
        }

        .text-area-input {
          height: 80px;
        }
      }
    }

    .modal-footer {
      border-top: transparent;
    }
  }

  .finalizeDischargeModal__body {
    padding: 3em 2em;
    border: 1px solid #00a8ff;
    border-radius: 10px;

    h6 {
      margin: 0;
      text-align: center;
    }
  }

  .add__btn {
    margin-right: 10px;
  }
}
