.dashboardCardList {
  margin: 2em;

  .dashboardCardList__header {
    padding: 1em 0;
    margin-left: 3.2%;

    &>svg {
      fill: #6DC9DD;
    }

    &>h4 {
      display: flex;
      align-items: center;
      color: #6DC9DD;
      font-size: 25px;
      font-weight: bold;
    }

    &>span {
      font-size: 16px;
    }
  }

  .dashboardCardList__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}