.tables-cont {
  .tableUI {
    border: none;
    margin: 0;

    .table {
      margin: 0;

      thead {
        tr {
          th {
            background: none;
            color: #262626;
            border: none;
            border-bottom: solid 1px #dcdcdc;
          }
        }
      }

      tbody {
        tr:nth-child(2n) {
          td {
            background-color: #fff;
          }
        }

        tr {
          td {
            border-bottom: solid 1px #dcdcdc;

            .account {
              display: flex;
              align-items: center;
              gap: 1rem;

              img {
                width: 40px;
                border-radius: 50%;
              }

              p {
                margin: 0;
              }
            }

            .denied-dropdown {
              #button-select {
                background-color: #FF6E6E;
                border-radius: 25px;
                text-transform: capitalize;
                color: #fff;
                display: block;
                padding: 5px 10px;
                width: max-content;
                margin: 0;
                border: none;

                &:focus {
                  box-shadow: none;
                }
              }

              .dropdown-menu {
                background: transparent;
                border: none;
                padding: 5px 0;

                .dropdown-item {
                  background-color: #35A100;
                  border-radius: 25px;
                  text-transform: capitalize;
                  color: #fff;
                  display: block;
                  padding: 5px 10px;
                  width: max-content;
                  margin: 0;
                  border: none;
                }
              }
            }
          }

          td.Patient.Care.Management {
            p {
              background-color: #004cff;
              border-radius: 25px;
              color: #fff;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }

          td.Property.Management,
          td.Property {
            p {
              background-color: #046d00;
              border-radius: 25px;
              color: #fff;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }

          td.Accounting.Management,
          td.Accounting {
            p {
              background-color: #9100ff;
              border-radius: 25px;
              color: #fff;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }

          td.HR.Management,
          td.HR.Manager {
            p {
              background-color: #0099ff;
              border-radius: 25px;
              color: #fff;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }

          td.Hospital.Management,
          td.Hospital {
            p {
              background-color: #a70515;
              border-radius: 25px;
              color: #fff;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }

          td.Nurse, td.Doctor, td.Admission {
            p {
              background-color: #004cff;
              border-radius: 25px;
              color: #fff;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }

          td.Active {
            p {
              background-color: #7ae97d;
              border-radius: 25px;
              color: #367d00;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }

          td.New {
            p {
              background-color: #35A100;
              border-radius: 25px;
              color: #fff;
              display: block;
              padding: 5px 10px;
              width: max-content;
              margin: 0;
            }
          }
        }
      }
    }

    .manda-pagination {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0 0;

      .paginationUI {
        justify-content: space-between;
      }
    }
  }
}

.pop-up-cont {
  flex-direction: column;
  align-items: flex-start;
}

button.remove-access.btn.btn-primary {
  background: none;
  color: #ff0000;
  border: none;
  padding: 0.2rem 0.5rem;
  text-transform: capitalize;
  font-weight: bold;

  svg {
    margin-right: 3px;
    height: 20px;
  }
}

button.view-access.btn.btn-primary {
  background: none;
  color: #00aeef;
  border: none;
  padding: 0.2rem 0.5rem;
  text-transform: capitalize;
  font-weight: bold;

  svg {
    margin-right: 3px;
    height: 20px;
  }
}

button.view-access.btn.btn-primary.approve {
  color: #13bb00;
}
