.formUI {
  .form-label {
    color: #404040;
  }

  .asterisk {
    color: red;
  }

  .form-control,
  .form-select {
    padding: 0.75em 1em;
    color: #404040;
    background-color: #f0f1f2 !important;
    border-radius: 10px !important;
    border: none;

    &:hover,
    &:focus,
    &:active {
      border-color: #c8c8c8;
      box-shadow: none;
    }

    &::placeholder {
      color: #c8c8c8;
    }
  }

  .form-check {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    &>input {
      width: 20px;
      height: 20px;
      margin-right: 0.5em;
      margin-bottom: 0;

      &:hover,
      &:focus,
      &:active {
        border-color: #c8c8c8;
        box-shadow: none;
      }
    }

    &>label {
      margin: 0;
      color: #404040;
      line-height: 100%;
    }
  }

  .input-group {
    flex-wrap: nowrap;

    &>.input-group-text {
      border: 1px solid #d4d4d4;
      border-right: none;
      border-radius: 10px;
      background: #eeeeee;

      svg {
        width: 20px;
      }
    }

    &>.form-control {
      width: 100%;
      max-width: 280px;
      padding: 0.5em 1em;
      border: 1px solid #d4d4d4;
      border-left: none;
      border-radius: 10px;
      background: #eeeeee;

      &::placeholder {
        color: #d4d4d4;
      }

      &:focus,
      &:hover {
        background: #eeeeee;
        box-shadow: none;
      }
    }
  }

  &>svg {
    width: 40px;
    fill: #00a8ff;
  }

  .react-datepicker-wrapper {
    input {
      width: 100%;
      padding: 0.75em 1em 0.75em 1em;
      border: 1px solid #d4d4d4;
      border-radius: 10px;
      outline: none;
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }
}