.income-statement {
  padding: 1rem 0;

  .income-details {
    padding: 1em 0 0;

    h5 {
      font-size: 20px;
      color: #00aeef;
      font-weight: bold;
    }

    .icon {
      svg {
        width: 2em;
        height: 2em;
        cursor: pointer;
      }
    }

    .bold {
      width: 100%;
      display: block;
      height: 1px;
      background-color: #000;
    }

    .hr {
      border-bottom: solid 1px #707070;
      width: 100%;
      height: 1px;
    }

    .inline-label {
      position: relative;
      width: 100%;

      label {
        position: absolute;
        background-color: #fff;
        font-size: 13px;
        top: -9px;
        left: 14px;
        font-weight: bold;
      }
    }

    .inline-label.width-80 {
      width: 80%;
      margin-left: auto;
    }

    .inline-label.width-70 {
      width: 70%;
    }

    .left-auto {
      margin-left: auto;
    }

    .right-auto {
      margin-right: auto;
    }

    .total-cont {
      p {
        display: inline-flex;
        gap: 1rem;
        margin: 0;
        font-weight: bold;
        font-size: 25px;

        span {
          color: #ff0000;
          font-size: 25px !important;
        }
      }
    }

    .income-container,
    .expense-container {
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      padding-right: 12px;

      .add-field {
        border: none;
        color: #fff;
        padding: 5px 10px;
        width: max-content;
        border-radius: 7px;
        background-color: #24bb00;
      }

      .form-group-inline {
        display: flex;
        align-items: center;

        label {
          margin: 0;
          padding: 0;
          flex: 0 0 15rem;
        }

        p {
          margin: 0;
          padding: 0;
        }

        input {
          border: none;
          flex: 0 0 20rem;
          margin: 0 1rem;
        }

        .group-buttons {
          width: 20px;
          cursor: pointer;
          margin-right: 10px;
        }
      }

      .total-group {
        p {
          display: inline-flex;
          gap: 1rem;
          margin: 0;
          font-weight: bold;
          font-size: 25px;
  
          span {
            color: #ff0000;
            font-size: 25px !important;
          }
        }
      }
    }
  }
}
