.inventory-table {
  .table-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      background-color: #00a8ff;
      border: none;
    }
  }

  .tableUI {
    .table {
      // thead {
      //   tr {
      //     th {
      //       padding: 10px;
      //     }
      //   }
      // }

      tbody {
        tr {
          td {
            // padding: 10px;

            .awaiting-bed {
              background: #ffcc00;
            }

            span.new {
              background-color: #00a8ff;
            }

            span.in-progress {
              background-color: #e49f00;
            }

            .action-buttons {
              display: flex;
              align-items: center;
              gap: 10px;
              flex-direction: column;

              button.btn.btn-primary {
                font-size: 14px;
                padding: 5px 10px;
                border: none;

                &.view-item-rep {
                  background-color: #00a8ff;
                  width: 100%;
                }

                &.for-issuance-rep {
                  background-color: #0069f2;
                  width: 100%;
                }
              }
            }
          }

          td:nth-of-type(2) {
            font-weight: bold;
          }

          td.action {
            display: flex;
            align-items: center;
            gap: 5px;

            button.adjust {
              padding: 3px 8px;
              border-radius: 5px;
              font-size: 10px;
              background-color: #00a8ff;
              height: max-content;
            }

            button.issue {
              padding: 3px 8px;
              border-radius: 5px;
              font-size: 10px;
              height: max-content;
              background-color: #ff0060;
            }

            button {
              border: none;

              svg {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }

  .popoverButton:focus {
    box-shadow: none;
  }

  .table-option {
    display: flex;
    gap: 1rem;
    align-items: center;

    button.btn.btn-primary {
      background-color: #c8c8c8;
      border-color: #c8c8c8;
    }

    button.btn.btn-primary.active {
      background-color: #0069f2;
      border-color: #0069f2;
    }
  }
}
