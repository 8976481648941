.inventory-table {
  .table-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      background-color: #00A8FF;
      border: none;
      text-transform: capitalize;
    }

    button.btn.btn-search {
      background-color: #02961C;
    }
  }

  .tableUI {
    .table {
      tbody {
        tr {
          td {
            .awaiting-bed {
              background: #ffcc00;
            }
          }

          td:nth-of-type(2) {
            font-weight: bold;
          }

          .custom-actions {
            display: flex;
            align-items: center;
            gap: 5px;

            button.adjust {
              padding: 3px 8px;
              border-radius: 5px;
              font-size: 10px;
              background-color: #00A8FF;
              height: max-content;
            }

            button.issue {
              padding: 3px 8px;
              border-radius: 5px;
              font-size: 10px;
              height: max-content;
              background-color: #FF0060;
            }

            button {
              border: none;

              svg {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }

  .popoverButton:focus {
    box-shadow: none;
  }
}