.order-request-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .order-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .order-request-form {
        padding: 2em;

        .patient-profile {
          background-color: #00a8ff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 1.5em;
          border-radius: 10px;
          flex-direction: column;

          h3,
          p {
            margin: 0;
            color: #fff;
          }

          h3 {
            font-weight: bold;
            font-size: 30px;
          }

          p {
            font-size: 16px;
            font-weight: normal;
          }

          .first-row,
          .second-row {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          }

          .second-row {
            h3 {
              font-size: 16px;
              font-weight: normal;
            }
          }
        }

        h2 {
          font-size: 20px;
          font-weight: bold;

          &.header {
            border-bottom: 1px solid #00a8ff;
            padding-bottom: 10px;
          }
        }

        label {
          font-weight: bold;
        }

        .specimen-cont {
          .form-check {
            position: relative;

            input {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              margin: auto;

              &:checked {
                background-color: #00a8ff;
              }
            }
          }
          label {
            font-weight: normal;
          }
        }

        .urine-volume-cont {
          display: flex;
          gap: 1em;

          .count {
            flex: 2;
          }

          .hours {
            flex: 1;
          }

          .whole-blood {
            flex: 1;
          }
        }

        .buttons-cont {
          display: flex;
          gap: 10px;
          justify-content: flex-end;

          .btn.add-button {
            background-color: #07c900;
            color: #fff;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            color: #fff;
            border: none;
          }
        }

        .category-buttons {
          display: flex;
          gap: 10px;
          justify-content: flex-start;
          align-items: flex-end;
          margin: 0 !important;
          height: 100%;

          .btn.add-button {
            background-color: #07c900;
            height: 50px;
            color: #fff;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            height: 50px;
            color: #fff;
            border: none;
          }
        }

        .order-filter-buttons {
          display: flex;
          gap: 1em;

          .btn.btn-primary {
            background-color: #0069f2;
            color: #fff;
            padding: 5px 14px;
          }
        }

        .order-pagination {
          display: flex;
          gap: 1rem;
          justify-content: flex-end;

          .results {
            display: flex;
            align-items: center;

            p {
              margin: 0;
              display: block;
              background-color: #06b10c;
              color: #fff;
              font-size: 12px;
              font-size: 12px;
              padding: 5px;
              border-radius: 7px;
            }
          }

          .pagination-cont {
            display: flex;
            gap: 1rem;
            margin: 0 !important;

            .filters {
              width: max-content;
              display: flex;
              align-items: center;
              margin: 0 !important;
              gap: 7px;

              label {
                margin: 0 !important;
                font-weight: normal;
                font-size: 12px;
              }

              select {
                width: max-content;
                padding: 5px 2.5em 5px 12px;
                border-radius: 7px;
                font-size: 12px;
              }
            }

            .pagination {
              margin: 0 !important;
              gap: 5px;

              .page-item {
                .page-link {
                  border-radius: 7px;
                  border-color: #000;
                  padding: 0;
                  font-size: 12px;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #000;

                  a {
                    span {
                      font-size: 25px;
                    }
                  }
                }

                &.active {
                  .page-link {
                    color: #fff;
                    background-color: #00a8ff;
                    border-color: #00a8ff;
                  }
                }
              }
            }
          }
        }

        .table {
          tbody {
            tr {
              td {
                padding: 1em 5px;
                text-align: center;

                p {
                  font-size: 12px;
                  line-height: 1;
                  margin: 0 !important;

                  span {
                    font-size: 10px;
                  }
                }

                .quantity {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 10px;

                  .quantity {
                    border-radius: 50%;
                    padding: 0 !important;
                    width: 20px;
                    height: 20px;
                    margin: 0 !important;
                    line-height: 1;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.add {
                      background-color: #01b425;
                    }

                    &.minus {
                      background-color: #c9000d;
                    }
                  }
                }

                .add-to-cart {
                  font-size: 12px;
                  background-color: #01b425;
                  color: #fff;
                  padding: 5px 5px;
                  text-transform: unset;
                  border: none;
                  width: max-content;
                }
              }
            }
          }
        }

        .cart-cont {
          border: 1px solid #00a8ff;
          border-radius: 10px;
          height: 100%;
          padding: 1rem;

          .header {
            border-bottom: solid 1px #00a8ff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 1rem;

            p {
              margin: 0 !important;
              font-weight: bold;
              font-size: 20px;
            }

            .cart-logo-cont {
              position: relative;

              .notification {
                background-color: #c9000d;
                color: #fff;
                font-size: 10px;
                position: absolute;
                top: -3px;
                left: -5px;
                padding: 0;
                margin: 0;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .cart-logo {
                width: 25px;
                cursor: pointer;
              }
            }
          }

          .body {
            display: flex;
            gap: 5px;
            flex-direction: column;
            padding: 10px 0;

            .list-data {
              display: flex;
              justify-content: space-between;
              align-items: center;

              p {
                margin: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
