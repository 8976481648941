.procedure-table {
  .tableUI {
    .table {
      tbody {
        tr {
          .btn.view-result {
            background-color: #07c900;
            color: #ffff;
            border-radius: 7px;
            font-size: 12px;
            padding: 2px 10px;
            border: none;
          }

          span {
            display: block;
            background-color: #07c900;
            color: #fff;
            text-align: center;
            padding: 2px 0;
            border-radius: 7px;

            &.pending {
              background-color: #CBB000;
            }

            &.approved {
              background-color: #07C900;
            }
          }
        }
      }
    }
  }
}