.professional-fee-modal {
    .modal-dialog.modal-lg {
      min-width: 1000px;
    }
  
    .modal-content {
      border-radius: 7px !important;
  
      .modal-body {
        padding: 0;
        border-radius: 7px !important;
  
        .professional-fee-header {
          padding: 2em;
          background-color: #00a8ff;
  
          .header {
            justify-content: space-between;
            align-items: center;
  
            p {
              color: #fff;
              text-transform: uppercase;
              font-family: 'Poppins', sans-serif;
              font-size: 25px;
              margin: 0;
              font-weight: bold;
            }
          }
        }
  
        .procedure-form {
          padding: 2em;
  
          .patient-profile {
            background-color: #00a8ff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 1.5em;
            border-radius: 10px;
            flex-direction: column;
  
            h3,
            p {
              margin: 0;
              color: #fff;
            }
  
            h3 {
              font-weight: bold;
              font-size: 30px;
            }
  
            p {
              font-size: 16px;
              font-weight: normal;
            }
  
            .first-row,
            .second-row {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            }
  
            .second-row {
              h3 {
                font-size: 16px;
                font-weight: normal;
              }
            }
          }
  
          h2 {
            font-size: 20px;
            font-weight: bold;
  
            &.header {
              border-bottom: 1px solid #00a8ff;
              padding-bottom: 10px;
            }
          }
  
          label {
            font-weight: bold;
          }
  
          .specimen-cont {
            .form-check {
              position: relative;
  
              input {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
  
                &:checked {
                  background-color: #00a8ff;
                }
              }
            }
  
            label {
              font-weight: normal;
            }
          }
  
          .urine-volume-cont {
            display: flex;
            gap: 1em;
  
            .count {
              flex: 2;
            }
  
            .hours {
              flex: 1;
            }
  
            .whole-blood {
              flex: 1;
            }
          }
  
          .buttons-cont {
            display: flex;
            gap: 10px;
            justify-content: flex-end;
  
            .btn.add-button {
              background-color: #07c900;
              color: #fff;
              border: none;
            }
  
            .btn.cancel-button {
              background-color: #b4b4b4;
              color: #fff;
              border: none;
            }
          }
        }
      }
    }
  
    .dataResult-hidden {
      display: none;
      margin-top: 5px;
      width: 300px;
      height: 200px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      overflow: hidden;
      overflow-y: auto;
      display: hidden;
    }
  
    .dataResult {
      margin-top: 5px;
      width: 100%;
      height: 300px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      overflow: hidden;
      overflow-y: auto;
    }
  
    .dataResult::-webkit-scrollbar {
      display: none;
    }
  
    .dataResult .dataItem {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      color: black;
      cursor: pointer;
      padding-left: 10px;
    }
  
    .dataItem p {
      margin-left: 20px;
    }
  
    .dataItem:hover {
      background-color: lightgrey;
    }
  }