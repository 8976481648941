.dietInformationTab {
  .patientProfile__header--dietInformation {
    h4 {
      margin: 0;
      font-weight: bold;
    }

    & > div:first-child {
      padding-bottom: 1em;
      border-bottom: 1px solid rgba(0, 168, 255, 0.2);
    }

    .noRecord {
      margin: 1em 0;
      h4 {
        color: #707070;
        font-size: 22px;
        font-weight: normal;
      }
    }
  }

  .tableUI {
    .dietHistoryTable {
      tbody {
        tr {
          td:nth-of-type(5) {
            color: #ff0000;
          }
        }
      }
    }
  }
}
