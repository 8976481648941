.view-request-room-table {
  .tableUI {
    .table {
      thead {
        tr {
          th {
            padding: 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px;
          }
          // td:nth-of-type(3) {
          //   font-weight: bold;
          // }

          .btn.view-result {
            background-color: #07c900;
            color: #ffff;
            border-radius: 7px;
            font-size: 12px;
            padding: 2px 10px;
            border: none;
          }

          span {
            display: block;
            background-color: red;
            color: #fff;
            text-align: center;
            padding: 2px 0;
            border-radius: 7px;

            &.pending {
              background-color: #cbb000;
            }

            &.approved {
              background-color: #07c900;
            }
          }

          span.update-button {
            background-color: #06b10c;
            cursor: pointer;
          }

          span.badge {
            background: none !important;
            color: #00A8FF;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }

    .assesment-pagination {
      height: 100%;

      .paginationUI {
        margin: 0;
        align-items: center;

        .pagination {
          .page-item {
            margin: 0 0.25em;
            display: flex;
            align-items: center;
          }
        }

        .summary {
          background: #06b10c;
          height: fit-content;
          flex-direction: column;
        }
      }
    }
  }
  .popoverButton:focus {
    box-shadow: none;
  }
}

.assesment-pagination {
  .paginationUI {
    .selectWrapper {
      .form-select {
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      }
    }
  }
}
