.recent-activites {
  .tableUI {
    border: none;

    table {
      margin: 0;

      thead {
        tr {
          th {
            color: #198754;
            background-color: #fff;
            // border-right: none;
            border: 2px solid #000;
            padding: 7px;
            font-size: 14px;

            .table-column-header {
              margin: 0;
            }

            p {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      tbody {
        tr {
          border: solid 2px #000;

          &:nth-child(2n) td {
            background-color: transparent;
          }

          td {
            padding: 7px;
            font-size: 14px;
            border: solid 2px #000;
          }
        }
      }
    }
  }
}
