.createDietaryModal {
  .modalUI {
    .modal-header {
      display: flex;
      align-items: center;
      background: #00a8ff;

      h4 {
        margin: 0;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .modal-body {
      padding: 2em;
    }
  }
}
