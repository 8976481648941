.laboratory-clearance-request-table {
  .tableUI {
    .table {
      th {
        /* styles for table headers */
        font-weight: bold;
        background-color: #F0F1F3;
        color: #828282;
        }
      thead {
        tr {
          th {
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 10%;
            }
          }
        }
      }
    }
  }
}