.repair-maintenance-table {
  .table-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      background-color: #00a8ff;
      border: none;
    }
  }
  .search-filter {
    display: flex;
    align-items: center;
  }
  .tableUI {
    border-top: none;

    .table {
      // thead {
      //   tr {
      //     th {
      //       padding: 10px;
      //     }
      //   }
      // }

      tbody {
        tr {
          td {
            // padding: 10px;

            p {
              margin: 0;

              span {
                display: block;
                background-color: transparent;
                padding: 0;
                font-size: 12px;
                color: #212529;
                text-align: left;
                margin: 0;
              }
            }

            span {
              display: block;
              background-color: #00a8ff;
              color: #fff;
              text-align: center;
              padding: 2px 0;
              border-radius: 7px;
              width: fit-content;
              padding: 5px 10px;

              font-size: 12px;
              min-width: 90px;

              &.new {
                background-color: #00a8ff;
              }

              &.in-progress {
                background-color: #e49f00;
              }

              &.Completed {
                background-color: #06b10c;
              }

              &.for.repair {
                background-color: #06b10c;
              }

              &.for.condemnation {
                background-color: #00a8ff;
              }
            }

            .action-buttons {
              display: flex;
              align-items: center;
              gap: 5px;

              Button {
                padding: 5px 10px;
                font-size: 12px;
                border: none;
                border-radius: 7px;

                &.view {
                  background-color: #00a8ff;
                }
              }
            }
          }

          td.action {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1em;
    align-items: center;

    button.active {
      background-color: #0069f2;
    }

    button {
      background-color: #c8c8c8;
      border: none;
      height: min-content;
      flex: 1;
      font-size: 13px;
    }
  }

  .popoverButton:focus {
    box-shadow: none;
  }

  .request-item-cont {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    Button.btn.btn-primary {
      border: none;
      background-color: #00a8ff;
      text-transform: capitalize;
    }
  }
}
