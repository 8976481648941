.adjustment-modal {
  .modal-dialog.modal-lg {
    max-width: 700px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .adjustment-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .adjustment-form {
        padding: 2em;

        .header {
          p {
            font-weight: bold;
          }
        }

        .tableUI {
          border-top-color: #212529;

          h3 {
            font-weight: bold;
            font-size: 20px;
            margin: 0;
            padding: 1rem 0 0;
          }
        }

        .table {
          tbody {
            td {
              span {
                display: block;
                background-color: #00a8ff;
                color: #fff;
                text-align: center;
                padding: 2px 0;
                border-radius: 7px;
                width: fit-content;
                padding: 0 10px;
                margin: 0 auto;
                min-width: 90px;

                &.receiving {
                  background-color: #06b10c;
                }

                &.in-progress {
                  background-color: #e49f00;
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: center;
          gap: 1em;

          Button.btn {
            text-transform: capitalize;
          }

          .btn.green {
            background-color: #02961c;
            border: none;
          }

          .btn.red {
            background-color: #ff5d5d;
            border: none;
          }

          .btn.pure-red {
            background-color: #ff0000;
            border: none;
          }

          .btn.grey {
            background-color: #707070;
            border: none;
          }
        }
      }

      .receiving-deny {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        border-radius: 8px;
        padding: 0 1rem;
        margin-bottom: 1rem;

        h3,
        h4 {
          font-weight: bold;
          font-size: 20px;
        }

        h6 {
          font-size: 13px;
          text-align: flex-start;

          span {
            display: block;
            color: #ff0000;
          }
        }

        p {
          text-align: flex-start;
          font-weight: bold;
          width: 100%;
        }

        label {
          font-weight: bold;
        }
      }
    }
  }
}
