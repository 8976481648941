#healthcare-teams-minus-button {
  padding-top: 35px;
}

#healthcare-teams-plus-button {
  padding-top: 35px;
}

#doctors-autocomplete {
  cursor: pointer;
  color: #404040;
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5rem;
  padding-left: 10px;
  ;
}

#doctors-autocomplete:hover {
  background-color: #707070;
}


.formCreateInpatientVisit {
  padding: 2em;
  border-radius: 10px;
  background: #ffffff;

  .formCreateInpatientVisit__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
      color: #00a8ff;
      font-weight: bold;
    }
  }

  .formCreateInpatientVisit__banner {
    display: flex;
    justify-content: space-between;
    margin: 2em 0;
    padding: 1em;
    border-radius: 10px;
    background: #00a8ff;

    &>div:nth-of-type(1) {
      display: flex;
      flex-direction: column;

      h2,
      h5 {
        margin: 0;
        color: #ffffff;
      }
    }

    &>div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      span {
        color: #ffffff;
        font-size: 18px;
      }
    }
  }

  .svg__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    border: 2px solid #707070;
    border-radius: 110px;

    svg {
      width: 140px;
    }
  }

  .form-label {
    width: 100%;
    cursor: pointer;

    .fakeInput {
      position: relative;
      width: 100%;
      min-width: 280px;
      padding: 0.75em 1em;
      color: #c8c8c8;
      border: 1px solid #c8c8c8;
      border-radius: 10px;
      background: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        position: absolute;
        top: -1px;
        right: -1px;
        padding: 0.75em 1em;
        color: #ffffff;
        border: 1px solid #0057c9;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        background: #0057c9;
      }
    }
  }

  .form-control[type='file'] {
    display: none;
  }

  .formUI {
    .form-label {
      font-size: 14px;
      font-weight: bold;
    }

    .otherPayor__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .otherPayor {
        position: relative;
        width: 100%;
        margin: 1em 1em 1em 0;
        padding: 1.5em;
        border-radius: 10px;
        background: #f5f5f5;

        .identityDocuments {
          width: 100%;
          background: #f5f5f5;
        }

        button {
          position: absolute;
          top: -10px;
          right: 10px;
        }
      }
    }

    .familyDetails__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .familyDetails {
        position: relative;
        margin: 1em 1em 1em 0;
        padding: 1.5em;
        border-radius: 10px;
        background: #f5f5f5;
      }

      button {
        position: absolute;
        top: -10px;
        right: 10px;
      }
    }

    .accordionUI {
      .accordion-header {

        &>.accordion-button,
        &>.accordion-button.collapsed {
          color: #e9ca00;
          font-size: 20px;
          font-weight: bold;
          border-bottom: 2px solid #e9ca00;
        }

        &>.accordion-button:not(.collapsed)::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E9CA00'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
      }
    }
  }
}


.dataResult {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow-y: auto;
  display: hidden;
}

.dataResult-hidden {
  display: none;
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  display: hidden;
}

.dataResult {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
}

.dataItem p {
  margin-left: 20px;
}

.dataItem:hover {
  background-color: lightgrey;
}