.NursingPatientProfileTabs {
  .tabsUI {
    .tab-content {
      padding: 1em 0;
    }
    
    .nav-tabs {
      .nav-item {
        width: fit-content;
        flex: 1 0 auto;

        button.nav-link {
          color: #ffffff;
          font-size: 15px;
          border-color: transparent;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          background: #b4b4b4;
          padding: 8px 16px;
          width: 100%;

          &.active {
            color: #ffffff;
            border-color: #00a8ff;
            background: #00a8ff;
            border-bottom: none;
          }
        }
      }

      .nav-link {
        width: 100%;
      }
    }

    .tab-content {
      .tab-pane {
        .patientProfile__header {
          margin: 1em 0 1.5em;

          h4 {
            padding-bottom: 0.75em;
            font-weight: bold;
            border-bottom: 1px solid rgba(0, 168, 255, 0.2);
          }
        }

        .patientProfile__content {
          margin-top: 0.5em;

          h5,
          h6 {
            margin-bottom: 1em;
            font-weight: bold;
          }

          .wrapper {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
          }

          .value {
            color: #006397;
            font-weight: bold;
            text-transform: uppercase;
          }

          .value--restriction {
            color: #ff0000;
          }

          .fileUpload {
            margin: 1em 0;

            .fileUpload__title {
              margin-right: 1.5em;
              padding: 0.25em 0.5em;
              border-radius: 8px;
              background: rgba(0, 0, 0, 0.25);
            }

            .fileUpload__fileName {
              color: #ff0000;
            }
          }
        }
      }
      .tab-pane.active {
        padding: 1em 0;
      }
    }
  }
}
