.ordersTab {
  .tableUI {
    border: none;

    .table {
      margin-top: 1em;
    }
  }

  .patientProfile__content {
    .title {
      padding: 0.5em 1em;
      color: #ffffff;
      background: #00a8ff;
    }
  }
}
