.accounting-management {
  .user-header {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 1rem;
    position: relative;

    .text {
      h2 {
        font-size: 14px;
        font-weight: bold;

        span {
          display: block;
          font-size: 12px;
        }
      }
    }

    .text-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      p {
        text-align: left;
        font-weight: bold;
        margin: 0;

        span {
          display: block;
          color: #00aeef;
          font-weight: bold;
        }
      }

      p.Active {
        color: #35a100;
      }

      img {
        border-radius: 50%;
        width: 100%;
      }
    }

    .button-cont {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;

      Button.btn.back-to-list {
        background: none;
        color: #00aeef;
        border: none;
        padding: 0;
        margin: 0;
        text-transform: capitalize;
        font-size: 13px;
        font-weight: bold;
      }
    }

    .search-cont {
      display: flex;
      gap: 1rem;

      .basic-search {
        flex: 1;

        .bg-grey.form-control {
        }

        .btn.btn-search {
          margin-left: 1em !important;
          border-radius: 10px !important;
          background-color: #dcdcdc;
        }

        .input-group {
          input {
            background-color: #dcdcdc;
          }

          .input-group-text {
            background-color: #dcdcdc;

            svg {
              width: 20px;
            }
          }
        }
      }

      .input-group-cont {
        flex: 1;

        select {
          background-color: #dcdcdc;
        }
      }

      .blue-background {
        select {
          background-color: #00aeef;
          color: #fff;
        }
      }
    }
  }

  .user-tables {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding: 1rem;

    .table-tabs {
      .tabsUI {
        .tab-content .tab-pane.active {
          padding: 0;
        }

        .nav.nav-tabs {
          border-bottom: solid 1px #dcdcdc;

          .nav-item {
            margin-bottom: 1rem;
          }

          .nav-link {
            background: #63d5ff;
            color: #fff;
            font-weight: bold;
            border: none;
            border-radius: 35px;
            padding: 5px 1rem;

            &.active {
              background-color: #00a8ff;
              border: none;
            }
          }
        }
      }
    }
  }
}
