.employees-add-edit {
  padding-bottom: 2em;
  border-radius: 10px;
  background: #ffffff;

  .add-edit-employee {
    .text-red {
      color: #dc3545;
    }

    h3 {
      background-color: #00aeef;
      color: #fff;
      text-transform: uppercase;
      padding: 1rem 2rem;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      font-size: 20px;
    }

    .bottom-line {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid #00aeef;
    }

    .employee-details {
      padding: 0 2rem;

      h5 {
        color: #00aeef;
        font-size: 20px;
      }

      h6 {
        color: #000000;
        font-size: 17px;
      }

      .inline-label {
        position: relative;
        width: 100%;

        label {
          position: absolute;
          background-color: #fff;
          font-size: 13px;
          top: -9px;
          left: 14px;
          z-index: 1;
          font-weight: bold;
        }
      }

      .inline-label.width-80 {
        width: 80%;
        margin-left: auto;
      }

      .inline-label.width-70 {
        width: 70%;
      }

      .left-auto {
        margin-left: auto;
      }

      .right-auto {
        margin-right: auto;
      }

      .file-upload {
        input {
          display: none;
        }

        label {
          z-index: 99;
          margin: 0;
        }

        .upload {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: relative;
          z-index: 10;
          border: solid 1px #b2b2b2;
          border-radius: 10px;
          height: 50px;
          left: 0;
          top: 0;

          span {
            background-color: #07c700;
            border-radius: 0 10px 10px 0;
            color: #fff;
            padding: 0 2rem;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }

      .profile-upload {
        display: flex;
        height: 100%;
        justify-content: center;

        input {
          display: none;
        }

        label {
          display: block;
          cursor: pointer;
          position: static;
        }

        .upload-image {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .image {
            position: absolute;
            width: 3.5rem;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        border-top: solid 1px #e4e4e4;
        padding-top: 2rem;

        button.btn.save-btn {
          background-color: #07c700;
          color: #fff;
          border-color: #07c700;
        }

        button.btn.cancel-btn {
          background-color: #c4c4c4;
          color: #fff;
          border-color: #c4c4c4;
        }
      }
    }
  }
}
