.credentials-credits {
  .modal-content {
    .modal-header {
      background-color: #00aeef;
    }

    .modal-body {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;

      button {
        flex: 1;
      }

      .main-content {
        gap: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h5 {
          font-weight: bold;
          color:#00aeef;
        }

        .vertical-align {
          flex: 1;

          label {
            font-weight: bold;
          }

          input.border-seven {
            height: 46px;
            border-radius: 7px !important;
          }
        }
        
      .browse {
        top: 286px;
        left: 449px;
        width: 166px;
        height: 58px;
        /* UI Properties */
        background: #00AEEF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        opacity: 1;
      }

      .radio {
        label {
        /* UI Properties */
        color: var(--unnamed-color-000000);
        text-align: left;
        font: normal normal 600 15px/15px Poppins;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        }
      }
    }
    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 32px;
    }

    &.btn.save {
      background-color: #07c700;
    }

    &.btn.cancel {
      background-color: #c4c4c4;
    }
  }
}