.create-new-item-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .create-new-item-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .create-item-form {
        padding: 2em;

        .buttonsWrapper {
          display: flex;
          gap: 1rem;

          button.btn.btn-primary {
            background-color: #00a8ff;
            border-color: #00a8ff;
          }

          button.btn.btn-secondary {
            background-color: #02961c;
            border-color: #02961c;
          }

          .cont {
            label {
              font-weight: normal;
              cursor: pointer;
              font-size: 1rem;
              margin: 0;
              background-color: #02961c;
              border-color: #02961c;
              color: #fff;
              padding: 0.5em 1.5em;
              text-transform: uppercase;
              border-radius: 10px;
              height: 100%;

              b {
                color: #00a8ff;
                text-decoration: underline;
              }
            }

            input {
              display: none;
            }
          }
        }

        .buttonsWrapper-2 {
          display: flex;
          gap: 1rem;
          justify-content: flex-end;

          button.btn.btn-primary {
            background-color: #00a8ff;
            border-color: #00a8ff;
          }

          button.btn.btn-secondary {
            background-color: #02961c;
            border-color: #02961c;
          }
        }

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 0 0 14rem;
            margin: 0 !important;
            font-weight: bold;
          }

          label.is-medicine {
            flex: 1;
          }

          label.item-description {
            flex: 1;
            max-width: 224px;
          }

          input,
          textarea,
          .select-item-group {
            flex: 2;
          }

          input.no-border {
            border: none;
            padding: 0;
          }

          .react-tagsinput {
            padding: 10px 1em;
            color: #404040;
            border-color: #c8c8c8;
            border-radius: 10px;
            flex: 2;

            span {
              display: inline-flex;
              align-items: center;
              justify-content: flex-start;

              .react-tagsinput-tag {
                margin-bottom: 0;
                background-color: #00a8ff;
                align-items: center;
                border: #00a8ff;
                color: #fff;
                gap: 5px;

                .react-tagsinput-remove {
                  color: #fff;
                  text-decoration: none;
                }
              }
            }

            .react-tagsinput-input {
              margin: 0;
            }
          }
        }

        .search-item,
        .item-information,
        .item-lots-and-expiration {
          .buttonWrapper {
            display: flex;
            justify-content: flex-start;
            gap: 1em;
            height: 100%;
            align-items: center;
          }

          label {
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }

          .basic-search {
            .bg-grey.form-control {
              border-top-right-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
            }

            .btn.btn-search {
              margin-left: 1em !important;
              border-radius: 10px !important;
            }
          }

          .advanced-search {
            .accordion-item {
              border: none;

              .accordion-header {
                .accordion-button {
                  font-weight: bold;
                  padding: 1rem 1.5rem;
                  color: #00a8ff;
                  background-color: #fff;
                  box-shadow: none;
                  border-bottom: 2px solid #000;

                  &.collapsed {
                    color: #000;
                    border-bottom: none;
                  }

                  &::after {
                    margin-right: auto;
                    position: absolute;
                    left: 0;
                  }
                }
              }
            }

            .inline-group {
              width: 10rem;
            }
          }

          .form-check {
            position: relative;

            input {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 0;
            }
          }

          h3 {
            font-weight: bold;
            font-size: 20px;
          }

          .selected-item {
            border-bottom: solid 1px #c8c8c8;
            padding-bottom: 1em;
          }

          .inline-group {
            display: flex;
            align-items: center;

            label,
            input,
            select,
            .locking-input {
              flex: 1;
            }

            .locking-input {
              display: flex;
              justify-content: space-between;
              gap: 1em;
            }
          }
        }

        .item-lots-and-expiration {
          h3 {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .search-table {
          .tableUI {
            border-top: none;

            .table {
              tbody {
                tr {
                  td {
                    button {
                      padding: 0;
                      background-color: transparent;
                      color: #00a8ff;
                      border: none;
                      text-transform: capitalize;
                      font-weight: bold;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.add-button {
            background-color: #02961c;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            border: none;
          }
        }
      }
    }
  }
}
