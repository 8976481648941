.accounting-tabs {
    .tabsUI {
        .nav.nav-tabs {
            .nav-item {
                width: max-content;

                .nav-link {
                    height: 100%;
                }
            }
        }
    }
}