.lab-result-table {
    .tableUI {
      .table {
        thead {
          tr {
            th {
              padding: 10px;
            }
          }
        }
  
        tbody {
          tr {
            td {
              padding: 10px;
            }
            // td:nth-of-type(3) {
            //   font-weight: bold;
            // }

            .btn.view-result {
                background-color: #07C900;
                color: #ffff;
                border-radius: 7px;
                font-size: 12px;
                padding: 2px 10px;
                border: none;
            }
          }
        }
      }
    }
    .popoverButton:focus {
      box-shadow: none;
    }
}

.assesment-pagination {
    .paginationUI {
        .selectWrapper {
            .form-select {
                padding: 0.375rem 2.25rem 0.375rem 0.75rem;
            }
        }
    }
}