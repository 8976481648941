.accordionUI {
  .accordion {
    background: none;

    .accordion-item {
      border: none;
      background: none;

      .accordion-header {
        .accordion-button,
        .accordion-button.collapsed {
          padding-left: 0;
          color: #404040;
          border-bottom: 2px solid #25aaff;
          border-radius: 0;
          background: none;
          box-shadow: none;
        }
      }

      .accordion-collapse,
      .accordion-collapse.collapse {
        border-radius: 0;

        .accordion-body {
          padding: 1em 0;
        }
      }
    }
  }
}
