.nursing-medical-records-list-table {
  .tableUI {
    .table {
      tbody {
        tr {
          td:nth-of-type(3) {
            font-weight: bold;
          }

          span.clearance-status {
            background-color: #0069F2;
            display: block;
            padding: 5px 10px;
            width: min-content;
            color: #fff;
            margin: 0 auto;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }
}
