.subTab {
  .tabsUI .nav.nav-tabs .nav-link {
    color: #B4B4B4;
    background: none;
  }
  .tabsUI .nav.nav-tabs .nav-link.active {
    color: #00A8FF;
    border: none;
  }
}
