.upload_header {
    div {
        font-size: 14px;
    }
}

.upload_body {
    padding: 16px !important;
}

table {
    border: 1px solid #6c757d;
    margin-top: 20px;

    thead {
        // border: 1px solid #6c757d;
        
        th {
            background-color: transparent;
            border: none;
            color: #00a8ff;
            font-size: 12px;
            text-transform: uppercase;
            padding: 15px 8px;
        }
    }

    tbody {
        tr {
            border: none;
        }

        td {
            font-size: 12px;
            border: none;
            vertical-align:middle;
        }
    }
}

.add_file_data_btn {
    font-size: 14px !important;
    border-radius: 3px !important;
}