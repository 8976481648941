.startDischargeModal {
  .modalUI {
    .modal-dialog {
      max-width: 720px;
    }

    .modal-header {
      display: flex;
      align-items: center;
      background: #00a8ff;

      h4 {
        margin: 0;
        color: #ffffff;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .modal-body {
      padding: 2em;
    }
  }

  .startDischargeModal__header {
    padding: 1em;
    border: 1px solid #00a8ff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    h1 {
      margin: 0;
      color: #ff0000;
      font-weight: bold;
    }
  }

  .startDischargeModal__body {
    padding: 1em;
    border: 1px solid #00a8ff;
    border-top: none;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    h5 {
      margin: 2em 0;
      text-align: center;
    }
  }
}
