.issue-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .issue-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .issue-form {
        padding: 2em;

        label {
          font-weight: bold;
        }

        input.bg-grey {
          background-color: #eeeeee;
          max-width: 100%;
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.add-button {
            background-color: #07C900;
            border: none;
          }

          .btn.cancel-button {
            background-color: #B4B4B4;
            border: none;
          }
        }
      }
    }
  }
}
