.morbility {
  thead tr th {
    border: 1px solid;
    text-align: center;
    font-size: 11px !important;
  }
  tbody tr td {
    font-size: 12px !important;
  }
  tfoot tr td {
    border: none;
  }
}
