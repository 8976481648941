.confirm-create-modal {
    .close {
      color: #fff !important;
      opacity: 1;
    }
  
    .modal-dialog {
      border-color: #00a8ff;
    }
  
    .modal-content {
      border: none;
      border-radius: 10px;
    }
  
    .modal-header {
      color: #fff;
      background-color: #00a8ff;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      text-transform: uppercase;
  
      .modal-title {
        font-size: 1.2em;
      }
    }
  
    .modal-footer {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
  
      .confirm-button {
        text-align: center;
      }
    }
  
    .modal-header,
    .modal-footer {
      border: none;
    }

    .modal-body {
        text-align: center;
    }
  }