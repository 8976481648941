.create-new-adjustment-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .create-new-adjustment-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .update-form {
        padding: 2em;

        label {
          font-weight: bold;
        }

        input.bg-grey {
          background-color: #eeeeee;
          max-width: 100%;
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.add-button {
            background-color: #02961c;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            border: none;
          }
        }

        .upload-image {
          width: fit-content;
          margin: 0 auto;

          input {
            display: none;
          }

          p {
            font-weight: bold;
            text-align: center;
            font-size: 14px;

            span {
              display: block;
              font-size: 12px;
              font-weight: normal;
            }
          }

          .cont {
            border: solid 1px #c8c8c8;
            border-radius: 10px;
            padding: 1rem;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            svg {
              width: 50px;
            }

            label {
              font-weight: normal;
              cursor: pointer;
              font-size: 13px;
              margin: 0;

              b {
                color: #00a8ff;
                text-decoration: underline;
              }
            }
          }
        }

        .select-form-input {
          height: 50px;
        }
      }
    }
  }
}
