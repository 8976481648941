.order-status-table {
  .tableUI {
    .table {
      thead {
        tr {
          th {
            padding: 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px;
          }
          // td:nth-of-type(3) {
          //   font-weight: bold;
          // }

          .btn.view-result {
            background-color: #07c900;
            color: #ffff;
            border-radius: 7px;
            font-size: 12px;
            padding: 2px 10px;
            border: none;
          }

          span {
            display: block;
            background-color: red;
            color: #fff;
            text-align: center;
            padding: 2px 5px;
            border-radius: 7px;
            font-size: 12px;

            &.pending {
              background-color: #D98A00;
            }

            &.approved {
              background-color: #00A8FF;
            }

            &.disapproved {
              background-color: #FF6161;
            }
          }
        }
      }
    }
  }
  .popoverButton:focus {
    box-shadow: none;
  }
}

.assesment-pagination {
  .paginationUI {
    .selectWrapper {
      .form-select {
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      }
    }
  }
}
