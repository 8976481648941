.returned-item-to-inventory {
  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .print-button.btn {
      background-color: #ff616c;
      border: none;
    }
  }

  .tableUI {
    .table {
      .badge.text-white {
        // color: #000 !important;
      }

      .action-buttons {
        gap: 5px;
        display: flex;
        align-items: center;
      }

      .badge {
        font-size: 12px;
        color: #000;

        &.Success {
          background: #0097144d;
          color: #009714;
        }

        &.To.Review {
          background: #00639766;
          color: #006397;
        }

        &.blue {
          color: #fff;
          background-color: #00a8ff;
        }

        &.red {
          color: #fff;
          background-color: #ba1515;
        }
      }

      thead {
        tr {
          th {
            font-size: 12px;
            width: 1ch;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 12px;

            span.for.receiving {
              padding: 2px 5px;
              border-radius: 5px;
              color: #006397;
              background-color: #00639766;
            }

            span.completed {
              padding: 2px 5px;
              border-radius: 5px;
              background: #0097144d;
              color: #009714;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }

  .fw-bold {
    font-weight: 700;
  }

  .button-status {
    margin-top: 15px;
    position: relative;

    .btn {
      padding: 3px 15px;
      text-transform: none;
    }

    .btn-open {
      background-color: #0069f2;
      color: white;
    }

    .btn-secondary {
      background-color: #c8c8c8;
    }

    .btn-request-item {
      position: absolute;
      right: 0;
      background-color: #06b10c;
      color: white;
    }
  }
}

.admissions-inventory-view-items-details-modal {
  .modal-body .fw-bold {
    color: #00a8ff;
  }
}
