.create-adjustment-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .create-adjustment-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .adjustment-form {
        padding: 2em;

        label {
          font-weight: bold;
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-start;
          gap: 1em;
          height: 100%;
          align-items: flex-end;

          button.btn.btn-search {
            margin-bottom: 13px;
          }
        }

        .header {
          p {
            font-weight: bold;
          }
        }

        .tableUI {
          border-top: none;

          h3 {
            font-weight: bold;
            font-size: 20px;
            margin: 0;
            padding: 1rem 0 0;
          }
        }

        .table {
          tbody {
            td {
              span {
                display: block;
                background-color: #00a8ff;
                color: #fff;
                text-align: center;
                padding: 2px 0;
                border-radius: 7px;
                width: fit-content;
                padding: 0 10px;
                margin: 0 auto;
                min-width: 90px;

                &.receiving {
                  background-color: #06b10c;
                }

                &.in-progress {
                  background-color: #e49f00;
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.create-adjustment {
            background-color: #07c900;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            border: none;
          }
        }

        .adjustment-item-list {
          label, h6 {
            font-weight: bold;
          }
        }

        .lock-cont {
          display: flex;
          align-items: center;
          gap: 10px;
          height: 50px;

          .form-switch {
            input {
              width: 40px;
              margin-top: 0;
            }

            label {
              font-size: 10px;
            }
          }
        }

        .stock-quantities {
          display: flex;
          gap: 10px;

          .inline-group {
            display: flex;
            align-items: center;
            gap: 5px;

            label {
              margin: 0;
            }
          }
        }
      }

      .receiving-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: solid 1px #212529;
        padding-top: 1rem;

        h3,
        h4 {
          font-weight: bold;
          font-size: 20px;
        }

        p {
          text-align: left;
          font-weight: bold;
          width: 100%;

          span {
            display: block;
            color: #ff5d5d;
          }
        }

        label {
          font-weight: bold;
        }
      }
    }
  }
}
