.report-body {
  padding: 0;
  .col {
    padding: 0;
  }
  .accordionUI {
    padding: 0;
    .accordion-header {
      background-color: #ECECEC;
    }
    .accordion-button {
      background-color: transparent;
      margin: 0;
      box-shadow: none !important;
    }
    .accordion-collapse {
      background-color: #ffffff;
    }

    .button-list button {
      margin: 0 0 15px 0;
      font-size: 10px;
    }

    .list {
      display: block;
      margin: 10px;

      .icon {
        float: right;
        padding-right: 12px;
        svg {
          margin: 5px;
        }
      }
    }
  }
}
