.order-history-cont {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #00a8ff;
    padding-bottom: 1rem;

    h3 {
      font-weight: bold;
      font-size: 25px;
      margin: 0;
    }

    .header-buttons {
      display: flex;
      gap: 1em;

      .cleared {
        background-color: #07c900;
        border: none;
      }

      .filters {
        background-color: #009a8f;
      }
    }
  }

  .category-buttons {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    .btn.btn-secondary,
    .btn.btn-primary {
      border: none;
      height: 50px;
      padding: 0 2em;
    }

    .btn.btn-primary.add-button {
      background-color: #07c900;
    }

    .btn.btn-secondary.reset-button {
      background-color: #b4b4b4;
    }
  }
}

.confirm-modal {
  .modal-body {
    padding: 0;

    .confirm-cont {
      padding: 2em;
      background-color: #FF2D2D;

      .header {
        justify-content: space-between;
        align-items: center;

        p {
          color: #fff;
          text-transform: uppercase;
          font-family: 'Poppins', sans-serif;
          font-size: 25px;
          margin: 0;
          font-weight: bold;
        }
      }
    }

    .body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em;
        flex-direction: column;
        gap: 1rem;

        p {
            text-align: center;
            font-weight: normal;
        }

        .tag-as-cleared {
            background-color: #07C900;
            padding: 1rem 2rem;
            border: none;
            border-radius: 5px;
        }
    }
  }
}
