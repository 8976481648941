.dashboardTopbar {
  display: flex;
  align-items: flex-start;
  background: #6BC9DD;
  box-shadow: 0 3px 6px rgba(83, 83, 83, 0.2);
  height: 23rem;
  padding: 20px;

  .dashboardTopbar__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 420px;

    .dashboardTopbar__brand {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10.5px;
      color: #ffffff;
      font-weight: 900;
      font-size: 35px;
    }


    .dashboardTopbar__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 74px;
      padding: 1em;
      color: #ffffff;
      font-weight: 900;
      font-size: 24px;
    }
  }

  .dashboardTopbar__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1em;
    align-items: center;
    width: 100%;
    height: 74px;

    button {
      margin: 0;
      padding: 0.25em;
      margin-right: 1.25em;
      border: none;
      background: none;
      box-shadow: none;
      outline: none;

      &>svg:nth-of-type(1) {
        width: 30px;
        fill: #00a8ff;
      }

      &:focus,
      &:hover {
        border: none;
        background: none;
        box-shadow: none;
        outline: none;
      }
    }

    .input-group {
      margin: 0;

      &>.input-group-text {
        border: 1px solid #d4d4d4;
        border-right: none;
        border-radius: 10px;
        background: none;

        svg {
          width: 20px;
        }
      }

      &>.form-control {
        width: 100%;
        max-width: 280px;
        padding: 0.5em 1em 0.5em 0;
        border: 1px solid #d4d4d4;
        border-left: none;
        border-radius: 10px;

        &::placeholder {
          color: #d4d4d4;
        }

        &:focus,
        &:hover {
          background: none;
          box-shadow: none;
        }
      }
    }

    &>svg {
      width: 40px;
      margin-left: auto;
      fill: #00a8ff;
    }
  }
}

.holder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.30); // adds a bottom shadow
  margin-right: 1.25rem;
}