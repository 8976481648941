.view-transaction-modal {
  .modal-content {
    .modal-header {
      padding: 32px;
      background-color: #00aeef;

      button {
        padding: 0;

        &:focus,
        &:hover {
          border: none;
          background: none;
          box-shadow: none;
        }
      }
    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    font-size: 25px;
    font-weight: bold;

    text-transform: uppercase;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 32px;
    }
  }

  .bg-update {
    background-color: #02961C;

    .text-white {
      color: #ffffff;
    }
  }
}