.manda-pagination {
  .paginationUI {
    display: flex;
    flex-wrap: nowrap;

    .selectWrapper > .form-select {
      padding-right: 2em;
    }
  }
}
