.admissions-search-inventory-filter {
  label {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

    button {
      background-color: #c8c8c8;
      border: none;
      height: min-content;
    }

  Button.btn.btn-success {
    background-color: #6BC9DD;
  }

  .search {
    width: 18rem;
  }
}
