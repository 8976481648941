.templates {
  .tableUI {
    border-top: none;

    .table {
      thead {
        tr {
          th {
            color: #00a8ff;
            background-color: #fff;

            &:nth-child(3) {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }

      tbody {
        tr {
            td {
                .document {
                    color: #000000;
                    text-decoration: none;
                }
            }
        }
      }

      button.add-template {
        color: #fff;
        background-color: #00aeef;
        border-color: #fff;
        padding: 5px 16px;
      }
    }
  }
}
