.credentials-credits {
  width: 100%;
  border-top: solid 2px #e9e9e9;

  .header {
    padding: 0.5rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
      padding: 0;
      color: #00aeef;
      font-size: 1rem;
      white-space: nowrap;
    }

    p {
      margin: 0;
      padding: 0;
      color: #FF0000;
      font-size: 20px;
    }

    .functions {
      gap: 1rem;
      display: flex;
      align-items: center;

      .inline-group {
        gap: 1rem;

        label {
          color: #000;
          font-size: 1rem;
        }

        input.seven {
          height: 46px;
          border-radius: 7px !important;
        }
      }

      button.btn.upload {
        background-color: #00aeef;
        border-color: #00aeef;
      }
    }
  }

  .credits-table {
    .tableUI {
      border: none;

      table {
        margin: 0;

        thead {
          border-bottom: 1px solid #DEE2E6;
          
          tr {
            th {
              color: #00AEEF;
              background-color: #fff;
            }
          }
        }

        tbody {
          tr {
            td {
              .badge {
                font-size: 12px;

                &.blue {
                  background: #00AEEF;
                  width: 166px;
                }
              }
            }
          }
        }
      }
    }
  }
}
