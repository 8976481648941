.content {
  width: 100%;
  height: 100vh;
  max-width: 80%;
  margin-bottom: 1em;
  padding: 30px;
  margin-left: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px; 
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #35626e;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #35626e;
  }

  @media (max-width: 1200px){
    max-width: 100%;
  }
}
