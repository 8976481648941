.tabsUI {
  .nav.nav-tabs {
    border: none;

    .nav-item {
      margin-right: 0.5em;
    }

    .nav-link {
      color: #ffffff;
      font-size: 15px;
      border-color: transparent;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      background: #b4b4b4;
    }

    .nav-link:hover,
    .nav-link:focus,
    .nav-link:active {
      border-color: transparent;
      background-color: #35636e;
    }

    .nav-link.active {
      color: #ffffff;
      border-color: #6ac9de;
      background-color: #6ac9de;
    }
  }

  .tab-content {
    .tab-pane.active {
      padding: 2em;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background: #ffffff;
    }
  }
}