.view-delete-modal {
  .modal-content {
    .modal-header {
      background-color: #00aeef;
    }

    .modal-body {
      gap: 1rem;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        flex: 1;
      }
    }
    .modal-footer {
      padding: 1em 2em 2em;

      .remove {
        margin: 0 auto;
        padding: 0.5em 3.5em;
        color: #ffffff;
        font-size: 18px;
        text-transform: uppercase;
        border: none;
        border-radius: 10px;
        background: #ff0000;
      }
      .cancel {
        margin: 0 auto;
        padding: 0.5em 3.5em;
        color: #ffffff;
        font-size: 18px;
        text-transform: uppercase;
        border: none;
        border-radius: 10px;
        background: #8f898a;
      }

    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    font-size: 22px;
    font-weight: bold;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 32px;
    }
  }
}