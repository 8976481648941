.view-stock-details {
    .buttonWrapper {
      display: flex;
      justify-content: flex-start;
      gap: 1em;
      height: 100%;
      align-items: center;
    }
  
    label {
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
    }
  
    .basic-search {
      .bg-grey.form-control {
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
      }
  
      .btn.btn-search {
        margin-left: 1em !important;
        border-radius: 10px !important;
      }
    }
  
    .advanced-search {
      .accordion-item {
        border: none;
  
        .accordion-header {
          .accordion-button {
            font-weight: bold;
            padding: 1rem 1.5rem;
            color: #00a8ff;
            background-color: #fff;
            box-shadow: none;
            border-bottom: 2px solid #000;
  
            &.collapsed {
              color: #000;
              border-bottom: none;
            }
  
            &::after {
              margin-right: auto;
              position: absolute;
              left: 0;
            }
          }
        }
  
        .accordion-body {
          h6 {
            font-weight: bold;
            font-size: 1rem;
          }
  
          .inline-group {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
  
            label {
              flex: 1;
              margin: 0 !important;
            }
  
            input,
            select {
              flex: 1;
            }
  
            select {
              background-color: #00a8ff;
              color: #fff;
            }
  
            input.no-border {
              border: none;
              padding: 0;
              color: #006397;
              font-weight: bold;
            }
          }

          .view-stock-table {
            .action-table {
                display: flex;
                gap: 1rem;
            }

            .table-button {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                height: 100%;
                gap: 1rem;

                button.btn.red {
                    background-color: #FF5757;
                    border: solid 2px #FF5757;
                }

                button.btn.hollow-blue {
                    color: #00A8FF;
                    background-color: #fff;
                    font-size: bold;
                    border: solid 2px #00A8FF;
                }

                .inline-group {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }

            .tableUI {
                border: none;
            }
          }
        }
      }
    }
  
    .form-check {
      position: relative;
  
      input {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
      }
    }
  }
  