.credit-memorandum-modal {
    .modal-dialog {
      max-width: 1000px;
    }

    .modal-content {
      padding-bottom: 20px;

      .modal-header {
        background-color: #00aeef;

        .add-procedure-request {
          padding: 1.2em;
          width: 100%;

          .header {
            justify-content: space-between;
            align-items: center;

            p {
              color: #fff;
              text-transform: uppercase;
              font-family: 'Poppins', sans-serif;
              font-size: 25px;
              margin: 0;
              font-weight: bold;
            }
          }
        }

        button {
          padding: 0;
  
          &:focus,
          &:hover {
            border: none;
            background: none;
            box-shadow: none;
          }
        }

      }
  
      .modal-body {
        display: block;
        width: 100%;

        padding: 20px 50px;

        .row-border {
          padding: 20px;
          border: 1px solid #00A8FF;
          border-radius: 10px;

          p {
            letter-spacing: 0;
            color: #5E5B5B;

            display: flex;
            align-items: center;
            gap: 10px;
          }
          span {
            color: #000000;
            font-weight: bold;
          }
        }

        th {
          p {
            font-size: 14px;
            margin: 0;
          }
        }

        .fields {
          label {
            text-transform: capitalize;
          }

          .amount-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .modal-footer {
        padding: 20px 35px;
        border-top: none;

        button {
          flex: 1;
          padding: 10px 20px;

          border-radius: 5px;
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
        }
        button.primary {
          background-color: #009714;
        }
      }
    }

    .label {
      font-size: 14px;
    }
    h5 {
      font-size: 18px;
      font-weight: bold;
    }
  
    h6 {
      margin: 0;
      color: #ffffff;
      font-size: 18px;
      font-weight: bold;
    }
  
    button {
      border: none;
      background: none;
      text-transform: capitalize;
  
      &:focus,
      &:hover {
        border: none;
        background: none;
        box-shadow: none;
      }
  
      svg {
        width: 32px;
      }
  
      &.btn.remove {
        background-color: #ff7280;
      }
  
      &.btn.approve {
        background-color: #35a100;
      }
  
      &.btn.cancel {
        background-color: #707070;
      }
    }
  }
  