.qr-code-container {
  position: absolute;
  background: #fff;
  z-index: 9;
  left: 0;
  right: 0;
  margin: auto;
  width: 35rem;
  top: -10rem;

  .qr-code-container-header {
    padding: 2rem;
    background-color: #00a8ff;

    .header {
      justify-content: space-between;
      align-items: center;

      p {
        color: #fff;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-size: 25px;
        margin: 0;
        font-weight: bold;
      }
    }
  }

  .qr-code-container-body {
    padding: 2rem;

    section {
      div {
        padding-top: 56% !important;
      }
    }
  }
}
