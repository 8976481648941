.admissions-inventory-list-table {
  .tableUI {
    .table {
      th {
        /* styles for table headers */
        font-weight: bold;
        background-color: #F0F1F3;
        color: #828282;
    }
      .badge {
        font-size: 12px;

        &.red {
          background: #ff0000;
        }

        &.green {
          background: #02961c;
        }

        &.blue {
          background: #00a8ff;
        }
      }

      thead {
        tr {
          th {
            font-size: 12px;
            width: 1ch;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 12px;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }

  .fw-bold {
    font-weight: 700;
  }

  .button-status {
    margin-top: 15px;
    position: relative;

    .btn {
      padding: 3px 15px;
      text-transform: none;
    }

    .btn-open {
      background-color: #0069f2;
      color: white;
    }

    .btn-secondary {
      background-color: #c8c8c8;
    }

    .btn-request-item {
      position: absolute;
      right: 0;
      background-color: #06b10c;
      color: white;
    }
  }
}

.admissions-inventory-view-items-details-modal {
  .modal-body .fw-bold {
    color: #00a8ff;
  }
}
