.sidebar-cont {
  .react-calendar {
    width: 100%;
    margin: 0 auto;
    border: none;
    border-radius: 10px;
  }

  .recent-activities {
    background-color: #fff;
    border-radius: 10px;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        margin: 0;
        padding: 0.5rem 1rem;
        border-bottom: solid 1px #999;

        p {
          font-weight: bold;
          margin: 0;
          font-size: 14px;

          span {
            display: block;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
    }
  }
}
