.outpatient-daily-worklist-table {
  .tableUI {
    .table {
      tbody {
        tr {
          td {
            .NEW {
              background: #ffcc00;
            }
            .ACCEPTED {
              background: #00c41a;
            }
          }

          td:nth-of-type(1) {
            font-weight: bold;
          }
        }
      }
    }
  }
}