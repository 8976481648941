.view-diagnosis-table {
  .tableUI {
    .table {
      tbody {
        tr {
          td:nth-of-type(3) {
            font-weight: bold;
          }

          span {
            display: block;
            background-color: #00a8ff;
            color: #fff;
            text-align: center;
            padding: 2px 0;
            border-radius: 7px;
            width: fit-content;
            padding: 0 10px;
            margin: 0 auto;
            min-width: 90px;

            &.pending {
              background-color: #cbb000;
            }

            &.approved {
              background-color: #07c900;
            }
          }

          span.awaiting-bed {
            background-color: #e2bd00;
            cursor: pointer;
          }

          span.available {
            background-color: #06b10c;
          }

          span.busy {
            background-color: #ff5050;
          }

          span.badge {
            background: none !important;
            color: #00A8FF;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
      }
    }
  }

  .bg-success {
    background: #06b10c !important;
  }
}
