.patientProfile__header {
  .action-buttons {
    .badge {
      display: block;

      &.red {
        background-color: #ff616c;
        color: #fff;
      }
    }
  }

  .tableUI {
    table {
      tbody {
        tr {
          td {
            .table-list {
              margin: 0;
              list-style: disc;
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
}
