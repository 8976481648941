.stock-adjustment-filter-table {
  .table-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    height: 100%;

    .btn {
      background-color: #00a8ff;
      border: none;
    }
  }

  .tableUI {
    border-top: none;
    
    .table {
      // thead {
      //   tr {
      //     th {
      //       padding: 10px;
      //     }
      //   }
      // }

      tbody {
        tr {
          td {
            // padding: 10px;

            p {
              margin: 0;

              span {
                display: block;
                background-color: transparent;
                padding: 0;
                font-size: 12px;
                color: #212529;
                text-align: left;
                margin: 0;
              }
            }

            span {
              display: block;
              background-color: #00a8ff;
              color: #fff;
              text-align: center;
              padding: 2px 0;
              border-radius: 7px;
              width: fit-content;
              padding: 0 10px;
              margin: 0 auto;
              min-width: 90px;

              &.new {
                background-color: #00a8ff;
              }

              &.in-progress {
                background-color: #e49f00;
              }

              &.for-approval {
                background-color: #016AA1;
              }
            }

            div.action {
              display: flex;
              align-items: center;
              gap: 5px;
            } 
          }

          td:nth-of-type(2) {
            font-weight: bold;
          }

          td.action {
            display: flex;
            align-items: center;
            gap: 5px;

            button.edit {
              padding: 3px 8px;
              border-radius: 5px;
              font-size: 10px;
              background-color: #00AC1D;
              height: max-content;
            }

            button.issue {
              padding: 3px 8px;
              border-radius: 5px;
              font-size: 10px;
              height: max-content;
              background-color: #FF0060;
            }

            button {
              border: none;

              svg {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1em;
    align-items: center;
    height: 100%;

    .actions {
      flex: 1;
    }
  }

  .popoverButton:focus {
    box-shadow: none;
  }
}
