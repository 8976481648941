.note-container {
  display: flex;
  width: 100%;
  padding: 0;
  border: solid 2px #777;
  flex-direction: column;

  .note-row,
  .note-row-last {
    display: flex;
    align-items: stretch;
    border-bottom: solid 2px #777;

    .note-column,
    .note-column-last {
      height: unset;
      display: flex;
      padding: 1rem;
      flex-direction: column;
      justify-content: center;
      border-right: solid 2px #777;

      label {
        font-size: 12px;
      }

      .inline-group {
        display: flex;
        align-items: center;

        label {
          font-size: 12px;
          margin: 0;
          flex: 1;
        }

        input {
          flex: 1;
          padding: 5px 10px;
        }
      }

      .svg {
        width: 20px;
        height: 20px;
        fill: #07c900;
        cursor: pointer;
        display: inline-block;
      }

      .multiple-inputs-header {
        gap: 1rem;
        display: flex;
        align-items: center;

        label {
          padding: 0;
          margin: 0;
        }

        .svg {
          width: 20px;
          height: 20px;
          fill: #07c900;
          cursor: pointer;
          display: inline-block;
        }
      }

      .multiple-inputs {
        margin: 0.5rem 0 0;
        display: flex;
        align-items: center;
        gap: 1rem;

        .svg {
          width: 20px;
          height: 20px;
          cursor: pointer;
          display: inline-block;
        }

        .observation-inputs {
          gap: 1rem;
          width: 100%;
          display: flex;
          align-items: flex-start;

          .last-flex {
            display: flex;
            align-items: center;
            gap: 1rem;
          }
        }
      }

      .note-data,
      .note-data-inline {
        height: 100%;
      }

      .note-data-inline {
        display: flex;
        align-items: center;
        gap: 1rem;

        label {
          margin: 0;
        }
      }

      .note-data.height-auto {
        height: auto;
      }

      .inline-check-with-input {
        display: flex;
        align-items: center;
        gap: 10px;

        .input-no-border {
          display: flex;
          align-items: center;

          label,
          input {
            flex: 1;
          }

          input {
            border: none;
            border-bottom: solid 1px #000;
            border-radius: 0;
            padding: 0;
          }

          label {
            margin: 0;
          }
        }

        .head-checkbox {
          flex: 1;
        }

        .inline-checkbox {
          gap: 1rem;
          display: flex;
          align-items: center;
          flex: 3;

          .form-check {
            flex: 0 0 22%;
          }
        }
      }

      .gap-2 {
        gap: 4rem;
      }

      .big-textbox {
        height: 14rem;
      }
    }

    .note-column-last {
      border-right: none;
    }

    .align-start {
      justify-content: flex-start;
    }

    .flex-2 {
      flex: 2;
    }

    .flex-1 {
      flex: 1;
    }
  }

  .note-row-last {
    border-bottom: unset;
  }

  .nine-row-box {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-template-rows: 2fr 2fr 2fr 2fr 2fr;

    .right-side {
      padding: 1rem;
      grid-column: 4;
      grid-row: 1/2;

      h6 {
        font-weight: bold;
      }

      .note-data-inline {
        .input-number {
          max-width: 2rem;
          height: 1rem;
        }
      }
    }

    .glassgow-score {
      border-top: solid 2px #777;
      padding: 1rem;
      grid-column: 4;
      grid-row: 2/3;

      h6 {
        font-weight: bold;
      }

      .note-data-inline {
        .input-number {
          max-width: 2rem;
          height: 1rem;
          padding: 10px;
          display: flex;
          align-items: center;
        }
      }
    }

    .stickman {
      height: 90%;
      width: auto;
      align-self: center;
    }

    .drawable-area {
      padding: 1rem;
      grid-column: 4;
      grid-row: 3/4;
      min-height: 10rem;
      border-top: solid 2px #777;
      border-bottom: solid 2px #777;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .drawable-area-2 {
      padding: 1rem;
      grid-column: 4;
      grid-row: 4/5;
      min-height: 10rem;
      border-bottom: solid 2px #777;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .drawable-area-3 {
      padding: 1rem;
      grid-column: 4;
      grid-row: 5/6;
      min-height: 10rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .left-side {
      padding: 1rem;
      grid-column: 1/4;
      grid-row: 1/8;
      border-right: solid 2px #777;
    }

    .note-data-inline {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 1rem;

      label {
        margin: 0;
        font-size: 12px;
      }
    }
  }

  .border-down {
    border-bottom: solid 2px #777;
  }
}
