.inventory-table {
  .table-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      background-color: #00a8ff;
      border: none;
    }
  }

  .tableUI {
    border-top: none;

    .table {
      // thead {
      //   tr {
      //     th {
      //       padding: 10px;
      //     }
      //   }
      // }

      tbody {
        tr {
          td {
            // padding: 10px;

            p {
              margin: 0;

              span {
                display: block;
                background-color: transparent;
                padding: 0;
                font-size: 12px;
                color: #212529;
                text-align: left;
                margin: 0;
              }
            }

            span {
              display: block;
              background-color: #00a8ff;
              color: #fff;
              text-align: center;
              padding: 2px 0;
              border-radius: 7px;
              width: fit-content;
              padding: 0 10px;

              min-width: 90px;

              &.new {
                background-color: #00a8ff;
              }

              &.in-progress {
                background-color: #e49f00;
              }

              &.for-approval {
                background-color: #016AA1;
              }
            }

            .action-button {
              display: flex;
              align-items: center;
              gap: 10px;

              Button.btn.btn-primary {
                border-radius: 5px;
                font-size: 10px;
                border: none;

                &.view-item {
                  background-color: #00A8FF;
                }

                &.print {
                  background-color: #FF0000;
                }
              }
            }
          }

          td:nth-of-type(2) {
            font-weight: bold;
          }

          td.action {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1em;
    align-items: center;

    button.active {
      background-color: #0069f2;
    }

    button {
      background-color: #c8c8c8;
      border: none;
      height: min-content;
      flex: 1;
      font-size: 13px;
    }
  }

  .popoverButton:focus {
    box-shadow: none;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;

    Button.btn.btn-primary {
      border: none;

      &.approve {
        background-color: #13BB00;
      }

      &.reject {
        background-color: #FF5D5D;
      }
    }
  }
}