@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../fonts/Poppins-Regular.otf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('../fonts/Poppins-Medium.otf');
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  background: #f4f6fd;
}

.btn {
  padding: 0.5em 1.5em;
  text-transform: uppercase;
  border-radius: 10px;

  &:hover,
  &:focus {
    box-shadow: none;
  }

  svg {
    width: 20px;
    margin-right: 0.5em;
  }
}

.btn-search,
.btn-addField,
.btn-save,
.btn-go {
  color: #ffffff !important;
  background: #07c900;

  &:focus,
  &:hover {
    background: #07c900 !important;
  }
}

.btn-clear,
.btn-reset,
.btn-cancel {
  color: #ffffff !important;
  background: #b4b4b4;

  &:focus,
  &:hover {
    background: #b4b4b4 !important;
  }
}

.btn-dark-blue {
  color: #ffffff !important;
  background: #0077ff;

  &:focus,
  &:hover {
    background: #0077ff !important;
  }
}

.btn-light-blue {
  color: #ffffff !important;
  background: #00a8ff;

  &:focus,
  &:hover {
    background: #0077ff !important;
  }
}

.btn-light-green {
  color: #ffffff !important;
  background: #01b425;
  border-color: #01b425;

  &:focus,
  &:hover {
    background: #01b425 !important;
  }
}

.btn-light-red {
  color: #ffffff !important;
  background: #ff4a4a;

  &:focus,
  &:hover {
    background: #ff4a4a !important;
  }
}

.btn-transparent,
.btn-action {
  margin: 0;
  padding: 0;
  border: none;
  background: none;

  svg {
    width: 32px;
    margin: 0;
  }
}

.btn-alias {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 55px;
  margin-right: 1em;
  padding: 0.5em;
  background: #b4b4b4;

  &:hover {
    background-color: darken(#b4b4b4, 30%) !important;
  }

  & > .aliasRound {
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 15px;
    background: #ffffff;
  }

  &.active {
    justify-content: flex-end;
    background: #07c900;
  }
}

.center-position {
  margin: 0 auto;
  width: 50%;
}

.font-size-normal {
  font-size: 12px !important;
}

.btn-discharge {
  color: #ffffff !important;
  background: #ff616c;

  &:hover {
    background: darken(#ff616c, 30%) !important;
  }
}

.btn-dark-green {
  color: #ffffff !important;
  background: #00ab33;

  &:hover {
    background: darken(#00ab33, 10%) !important;
  }
}

.btn-light-red {
  color: #ffffff !important;
  background: #ff7272;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
}
