.user-accounts {
  .user-header {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    padding: 1rem;

    .text {
      h2 {
        font-size: 14px;
        font-weight: bold;

        span {
          display: block;
          font-size: 12px;
        }
      }
    }

    .search-cont {
      display: flex;
      gap: 1rem;

      .basic-search {
        flex: 1;

        .bg-grey.form-control {
        }

        .btn.btn-search {
          margin-left: 1em !important;
          border-radius: 10px !important;
          background-color: #dcdcdc;
        }

        .input-group {
          input {
            background-color: #dcdcdc;
          }

          .input-group-text {
            background-color: #dcdcdc;
            cursor: pointer;

            svg {
              width: 20px;
            }
          }
        }
      }

      .input-group-cont {
        flex: 1;

        select {
          background-color: #dcdcdc;
        }
      }
    }
  }

  .user-tables {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding: 1rem;

    .table-tabs {
      .tabsUI {
        .tab-content .tab-pane.active {
          padding: 0;
        }

        .nav.nav-tabs {
          border-bottom: solid 1px #dcdcdc;

          .nav-link {
            background: none;
            border: none;
            color: #262626;
            font-weight: bold;
            padding: 0.5rem;

            &.active {
              color: #00aeef;
              border: none;
            }
          }
        }
      }
    }
  }
}
