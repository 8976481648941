.financial-statement {
    .tabsUI {
      .nav.nav-tabs {
        border: none;
  
        .nav-item {
          margin-right: 0.5em;
        }
  
        .nav-link {
          color: #b4b4b4;
          font-size: 15px;
          background-color: transparent;
          border: none;
          padding: 1em;
        }
  
        .nav-link:hover,
        .nav-link:focus,
        .nav-link:active {
          border-color: transparent;
        }
  
        .nav-link.active {
          color: #00a8ff;
          border-color: #00a8ff;
          border: none;
          border-bottom: 4px solid #00a8ff;
          background-color: transparent;
        }
      }
  
      .tab-content {
        .tab-pane.active {
          padding: 0;
        }
      }
    }
  }
  