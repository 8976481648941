.stock-adjustment-filter-table {
  .table-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    height: 100%;

    .btn {
      background-color: #00a8ff;
      border: none;
    }
  }

  .tableUI {
    border-top: none;

    .table {
      tbody {
        tr {
          td {
            span.status {
              display: block;
              background-color: #00a8ff;
              color: #fff;
              text-align: center;
              padding: 2px 0;
              border-radius: 3px;
              width: fit-content;
              padding: 0 10px;
              margin: unset;
              min-width: 90px;

              &.returned {
                background-color: #ffd20080;
                color: #977c00;
              }

              &.borrowed {
                background-color: #0097144d;
                color: #009714;
              }
            }

            p {
              margin: 0;

              span {
                display: block;
                background-color: transparent;
                padding: 0;
                font-size: 12px;
                color: #212529;
                text-align: left;
                margin: 0;
              }
            }

            span {
              display: block;
              background-color: #00a8ff;
              color: #fff;
              text-align: center;
              padding: 2px 0;
              border-radius: 3px;
              width: fit-content;
              padding: 0 10px;
              margin: unset;
              min-width: 90px;

              &.new {
                background-color: #00a8ff;
              }

              &.in-progress {
                background-color: #e49f00;
              }

              &.for-approval {
                background-color: #016aa1;
              }
            }

            div.action {
              display: flex;
              align-items: center;
              gap: 5px;

              Button.btn.btn-primary {
                padding: 5px 10px;
                border: none;
                font-size: 10px;
                border-radius: 5px;

                &.view {
                  background-color: #00a8ff;
                }

                &.approve {
                  background-color: #02961c;
                }

                &.deny {
                  background-color: #ff0000;
                }
              }
            }

            .action-buttons {
              span {
                font-size: 12px;
                display: block;
                color: #fff;
                text-align: center;
                padding: 2px 0;
                border-radius: 3px;
                width: fit-content;
                padding: 2px 10px;
                margin: unset;
                min-width: 90px;

                &.blue {
                  background-color: #00a8ff;
                  border-color: #00a8ff;
                }

                &.green {
                  background-color: #06b10c;
                  border-color: #06b10c;
                }
              }
            }
          }

          td:nth-of-type(2) {
            font-weight: bold;
          }

          td.action {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1em;
    align-items: center;
    height: 100%;

    button.active {
      background-color: #0069f2;
    }

    button {
      background-color: #c8c8c8;
      border: none;
      height: min-content;
      flex: 1;
      font-size: 13px;
    }
  }

  .popoverButton:focus {
    box-shadow: none;
  }
}
