.outpatient-search-patient-table {
  .tableUI {
    .table {
      thead {
          tr {
            th {
              &:nth-child(1) {
                width: 15%;
              }
      
              &:nth-child(3) {
                width: 10%;
              }
            }
          }
        }
    }
  }

  .bg-success {
    background: #06b10c !important;
  }
}