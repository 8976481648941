.worklist-action-table {
  .tableUI {
    .table {
      thead {
        tr {
          th {
            padding: 10px;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 10px;
          }
          td:nth-of-type(3) {
            font-weight: bold;
          }

          span {
            display: block;
            background-color: #00A8FF;
            color: #fff;
            text-align: center;
            padding: 2px 0;
            border-radius: 7px;
            width: fit-content;
            padding: 0 10px;
            margin: 0 auto;
            min-width: 90px;

            &.pending {
              background-color: #cbb000;
            }

            &.approved {
              background-color: #07c900;
            }

            &.admitted {
              background-color: #E2BD00;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }
  .popoverButton:focus {
    box-shadow: none;
  }
}
