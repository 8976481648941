.price-update-modal {
  .modal-dialog.modal-lg {
    max-width: 700px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .price-update-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .price-update-form {
        padding: 2em;

        h3 {
          font-size: 20px;
          font-weight: bold;
        }

        label {
          font-weight: bold;
          margin: 0 !important;
        }

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 0 0 14rem;
            font-weight: bold;
            margin: 0 !important;
          }

          .select-item-group {
            flex: 2;
          }

          input {
            flex: 2;
            color: #006397;
            font-weight: bold;
            background: transparent;

            &::placeholder {
              font-weight: bold;
              color: #006397;
            }
          }

          input.no-border {
            border: none;
            padding: 0;
          }

          .quantities-header {
            flex: 2;
            display: flex;
            flex-direction: column;
            gap: 20px;
          }

          .quantities {
            display: flex;
            flex-direction: column;
            flex: 2;
          }
        }

        .react-tagsinput {
          padding: 10px 1em;
          color: #404040;
          border-color: #c8c8c8;
          border-radius: 10px;
          flex: 2;

          span {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;

            .react-tagsinput-tag {
              margin-bottom: 0;
              background-color: #00a8ff;
              align-items: center;
              border: #00a8ff;
              color: #fff;
              gap: 5px;

              .react-tagsinput-remove {
                color: #fff;
                text-decoration: none;
              }
            }
          }

          .react-tagsinput-input {
            margin: 0;
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.add-button {
            background-color: #02961c;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            border: none;
          }
        }
      }
    }
  }
}
