.clearanceFilter {
  .buttonWrapper {
    display: flex;
    & > button {
      margin-left: 1em;
      height: 50px;
    }
  }

  label {
    font-weight: bold;
  }
}
