.doctorTabs {
  .tableUI {
    .table {
      tbody {
        tr {
          td {
            .awaiting-bed {
              background: #ffcc00;
            }
          }

          td:nth-of-type(2) {
            font-weight: bold;
          }
        }
      }
    }
  }

  .tabsUI {
    .nav.nav-tabs {
      .nav-link {
        position: relative;

        .pop-up {
          top: -8px;
          right: -8px;
          width: 1rem;
          height: 1rem;
          display: flex;
          display: block;
          font-size: 11px;
          position: absolute;
          border-radius: 50%;
          align-items: center;
          background-color: red;
          justify-content: center;
        }
      }
    }
  }
}
