.PhilhealthTabs {
    .tabsUI {
        .nav-tabs {
            .nav-item {
                width: auto;

                .nav-link {
                    height: 100%;
                }
            }

            li.nav-item:nth-child(9) {
                width: auto;
            }
        }
    }
}