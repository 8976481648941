.roomReservationFilter {
  margin-bottom: 0.5em;

  .filterUI {
    & > div {
      flex: 1 0 18%;
      max-width: 18%;
    }
  }
}
