.create-new-stock-check {
  h5 {
    font-weight: bold;
  }

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 1em;
    border-bottom: solid 12px #707070;
    position: relative;

    p {
      color: #707070;
      margin: 0;
      font-weight: bold;
    }

    .arrow {
      position: absolute;
      top: 0;
      right: 0;
      margin: 7px 0px;
      width: 20px;
      height: 20px;
      background-image: url('../../../../../../assets/images/arrow-img-grey.png');
      background-size: cover;
    }
  }

  .step.active {
    border-bottom: solid 12px #00a8ff;
    
    p {
      color: #00a8ff;
    }

    .arrow {
      background-image: url('../../../../../../assets/images/arrow-img.png');
    }
  }
  
  .btn {
    &.btn-success {
        background: #13BB00;
        border-color: #13BB00;
    }
  }

  .selected-items {
    display: flex;
    align-items: center;

    .counter {
        background: var(--unnamed-color-00a8ff) 0% 0% no-repeat padding-box;
        background: #00A8FF 0% 0% no-repeat padding-box;
        border: 1px solid #C8C8C8;
        border-radius: 5px;
        padding: 0.35rem 1.5rem;
        margin-left: 1rem;
    }
  }

  .list-group {
    .list-group-item {
        padding: 1rem;
    }

    .form-check-input {
        margin-right: 2rem;
    }
  }

  .print-and-time {
    display: flex;
    margin-left: auto;
    width: max-content;
    align-items: flex-end;
    flex-direction: column;
    
    .time-started {
      flex: 1;
      width: 100%;

      label {
        font-weight: bold;
      }

      input.form-control {
        color: #00A8FF;
        border-width: 4px;
        text-align: center;
        border-radius: 7px;
        border-color: #00A8FF;
        font-weight: bold;
        background-color: #fff;
      }
    }
  }

  .finalize-and-draft {
    gap: 1rem;
  }
}
