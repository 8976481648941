.admissions-inventory-list-table {
  .tableUI {
    .table {
      .custom-actions {
        display: flex;
        flex: 1 1;
        gap: 10px;
      }
      
      .badge {
        font-size: 15px;

        &.red {
          background: #FF0000;
        }

        &.green {
          background: #02961C;
        }

        &.blue {
          background: #00A8FF;
        }
      }

    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }

  .button-status {
    margin-top: 15px;
    position: relative;

    .btn {
      padding: 3px 15px ;
      text-transform: none;
    }
    
    .btn-open {
      background-color: #0069f2;
      color: white;
    }
    .btn-secondary {
      background-color: #c8c8c8;
    }

    .btn-request-item {
      position: absolute;
      right: 0;
      background-color: #06b10c;
      color: white;
    }
  }
}