.admissions-room-reservation-list-table {
  .tableUI {
    .table {
      tbody {
        tr {
          td:nth-of-type(3) {
            font-weight: bold;
          }

          td {
            .badge {
              font-size: 12px;

              &.red {
                background: #ff0000;
              }

              &.green {
                background: #02961c;
              }

              &.blue {
                background: #00a8ff;
              }

              &.Pending {
                background: #0079c933;
                color: #0079c9;
              }

              &.In.use {
                background: #0097144d;
                color: #009714;
              }

              &.Denied {
                background: #ff616c66;
                color: #e20010;
              }

              &.Returned {
                background: #ffd5004d;
                color: #b88100;
              }
            }
          }
        }
      }
    }
  }
}
