.signUpModal {
  .modalUI {
    .modal-header {
      padding: 0;

      .signUpModal__header {
        width: 100%;
        height: 300px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        background: url('../../../assets/images/signup_modal_header.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    .modal-body {
      padding: 2em;

      p {
        font-size: 18px;
        text-align: center;
      }
    }

    .modal-footer {
      padding: 1em 2em 2em;

      .signUpModal__button {
        margin: 0 auto;
        padding: 0.5em 1em;
        color: #ffffff;
        font-size: 18px;
        text-transform: uppercase;
        border: none;
        border-radius: 10px;
        background: #00a8ff;
      }
    }
  }
}
