.records{
    .Filter{
        border-bottom: 1px solid #ccc;
        padding: 1em 1em 0 1em;

        h4{
            color: #333;
            font-size: 20px;
            font-weight: 900;
        }
    }

}