.admissions-inventory-list-table.request-item {
  .tableUI {
    .table {
      .badge {
        font-size: 12px;

        &.red {
          background: #ff0000;
        }

        &.green {
          background: #02961c;
        }

        &.blue {
          background: #00a8ff;
        }

        &.darkgreen {
          background: #02961c;
        }

        &.darkblue {
          background: #0069f2;
        }
      }

      thead {
        tr {
          th {
            font-size: 12px;
            width: unset;
          }

          th:nth(3) {
            width: 1ch;
          }
        }
      }

      tbody {
        tr {
          .action-buttons {
            display: flex;
            gap: 2px;
            flex-direction: column;
            align-items: center;

            span {
              font-size: 12px;
              min-width: 135px;
            }
          }

          td {
            font-size: 12px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #6BC9DD;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }

  .button-status {
    margin-top: 15px;
    position: relative;

    .btn {
      padding: 3px 15px;
      text-transform: none;
    }

    .btn-open {
      background-color: #0069f2;
      color: white;
    }
    .btn-secondary {
      background-color: #c8c8c8;
    }

    .btn-request-item {
      position: absolute;
      right: 0;
      background-color: #06b10c;
      color: white;
    }
  }
}
