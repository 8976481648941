.add-financial-statement {
  padding: 1rem 0;

  .financial-details {
    padding: 1em 0 0;

    .asset-name::after {
      content: ":";
      float: right;
    }

    .current-asset {
      color: #35a100;
    }

    .non-current-asset {
      color: #a70515;
    }

    .icon {
      svg {
        width: 2em;
        height: 2em;
        cursor: pointer;
      }
    }

    .button-list  {
      button {
        font-size: 10px;
      }
    }

    h5 {
      font-size: 20px;
      color: #00aeef;
      font-weight: bold;
    }

    h6 {
      font-weight: bold;
      font-size: 13px;
    }

    .bold {
      width: 100%;
      display: block;
      height: 1px;
      background-color: #000;
    }

    .hr {
      border-bottom: solid 1px #707070;
      width: 100%;
      height: 1px;
    }

    .inline-label {
      position: relative;
      width: 100%;

      label {
        position: absolute;
        background-color: #fff;
        font-size: 13px;
        top: -9px;
        left: 14px;
        font-weight: bold;
      }
    }

    .inline-label.width-80 {
      width: 80%;
      margin-left: auto;
    }

    .inline-label.width-70 {
      width: 70%;
    }

    .left-auto {
      margin-left: auto;
    }

    .right-auto {
      margin-right: auto;
    }

    .total-cont {
      p {
        display: inline-flex;
        gap: 1rem;
        margin: 0;
        font-weight: bold;
        font-size: 25px;

        span {
          color: #FF0000;
          font-size: 25px;
        }
      }
    }
  }
}
