.clearanceFilter {
  .buttonWrapper {
    display: flex;
    & > button {
      margin-left: 1em;
    }
  }

  label {
    font-weight: bold;
  }
}
