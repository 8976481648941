.order-tabs {
  .order-tabs__header {
    position: relative;

    .tabsUI {
      .nav.nav-tabs {
        padding-bottom: 1em;
        border-bottom: 1px solid rgba(0, 168, 255, 0.2);

        .nav-item {
          margin-right: 1.5em;
          flex: 0;
        }

        .nav-link {
          width: fit-content;
          padding: 0.5em 0;
          color: #b4b4b4;
          font-size: 18px;
          white-space: nowrap;
          border: none;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          background: none;
        }

        .nav-link.active {
          color: #00a8ff;
          border: none;
          border-bottom: 3px solid #00a8ff;
          border-color: #00a8ff;
          background: none;
        }
      }

      .tab-content {
        .tab-pane {
          .patientProfile__header {
            h4 {
              padding: 0;
              border: none;
            }
          }
        }
      }
    }

    & > .btn-discharge {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .tableUI {
    border: none;

    .table {
      margin-top: 1em;
    }
  }
}
