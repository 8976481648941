@font-face {
  font-family: 'PoppinsExtraBold';
  src: url('../../../assets/fonts/Poppins-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

.headerUI {
  margin-bottom: 2em;

  &>h4 {
    color: #6ac9de;
    font-family: 'PoppinsExtraBold', sans-serif;
  }

  &>p {
    color: #6ac9de;
    font-size: 14px;
  }
}