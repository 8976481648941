.RoomBedTabs {
    .tabsUI {
        .nav.nav-tabs {
            .nav-item {
                width: max-content;

                .nav-link {
                    height: 100%;
                }
            }

            li.nav-item:nth-child(9) {
                width: max-content;

            }
        }
    }
}