.soa-cont {
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }

  label {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #00a8ff;
    padding-bottom: 1rem;

    h2 {
      margin: 0;
      font-weight: bold;
    }

    Button.btn {
      text-transform: capitalize;
      background-color: #00a8ff;
      border: none;
      border-radius: 7px;
    }
  }

  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    .left-cont {
      display: flex;
      align-items: center;
      gap: 1rem;

      .inline-group {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        label {
          flex: 1;
          margin: 0 !important;
          font-weight: bold;
        }

        input {
          flex: 2;
        }

        input.no-border {
          border: none;
          padding: 0;
          color: #ff0000;
          font-weight: bold;
        }
      }
    }

    .right-cont {
      Button.btn {
        background-color: #00a8ff;
        border: none;
      }
    }
  }

  .net-hospital-bill {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 1rem;

    .inline-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      label {
        flex: 2;
        font-size: 20px;
        cursor: default;
        font-weight: bold;
        margin: 0 !important;
      }

      input {
        flex: 1;
        font-size: 20px;
      }

      input.blue-border {
        font-weight: bold;
        background-color: #00a8ff;
        color: #fff;
      }
    }
  }

  .hospital-bill {
    h4 {
      font-weight: bold;
      font-size: 20px;
    }

    .tableUI {
      border-top: none;

      .table {
        tbody {
          tr:nth-child(2n) {
            td {
              background-color: #fff;
            }
          }

          tr {
            border-bottom: solid 1px #000;

            td {
              p {
                margin: 0;
                font-weight: bold;

                span {
                  display: block;
                  font-weight: normal;
                  font-size: 14px;
                }
              }
            }
          }
        }

        tfoot {
          tr {
            td {
              background-color: #00a8ff;
              color: #fff;
              border-left: none;
              border-right: none;
            }

            td.red-bg {
              background-color: #ff7474;
            }
          }
        }
      }
    }
  }

  .payment-option {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .payment-group {
      width: 100%;
      max-width: 256px;

      .form-select {
        background-color: #06b10c;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}
.form-tab-table-billing {
  .tableUI .accordion .accordion-item .accordion-collapse .accordion-body,
  .tableUI .accordion .accordion-item .accordion-collapse.collapse .accordion-body {
    position: relative;
    top: -10px;
    left: 0;
    padding: 1em 0;
    background: white;
    color: black;
  }
  .tableUI .accordion .accordion-item .accordion-header .accordion-button,
  .tableUI .accordion .accordion-item .accordion-header .accordion-button.collapsed {
    background: #ececec;
  }
  .accordion-button:not(.collapsed) {
    color: black;
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
      var(--bs-accordion-border-color);
  }
  .btn {
    background-color: #00ab06;
    border: none;
  }
  .accordion-header-custom {
    padding: 1em;
    color: #ffffff;
    background: #00a8ff;
  }
  .tableUI .table tbody tr td {
    margin: 0;
    padding: 0;
  }
  .add-record:hover {
    background-color: #00ab06;
  }
  .table-responsive {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
.total-deduction {
  display: flex;
  justify-content: center;
  h5 {
    font-weight: 900;
    font-size: 32px;
    border: 1px solid black;
    padding: 0.3rem;
  }
  h6 {
    font-weight: 700;
  }
}
.deduction-save {
  background-color: #00ab06;
  border: none;
}
.deduction-cancel {
  background-color: white;
  color: black;
  border: 1px solid black;
}
.no-padding {
  padding: 0 !important;
}

// SOA TABLE
.SOA-table {
  table td {
    border: none;
    width: 80%;
  }
  table th {
    border-bottom: 1px solid black;
    border-right: none;
    width: 80%;
  }
  table {
    border: none;
  }
}
.accordion-header-custom2 {
  padding: 1em;
  color: black;
  background: white;
}
.paid-container {
  padding: 2rem;
  background-color: #14cb0033;
  color: #1fc100;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
