.price-logs-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .price-logs-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .price-logs-form {
        padding: 2em;

        label {
          font-family: 'Poppins', sans-serif;
          font-weight: bold;
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-start;
          gap: 1em;
          height: 100%;
          align-items: flex-end !important;

          button.btn.btn-search {
            background-color: #02961c;
            height: 50px;
          }

          button.btn.btn-clear {
            background-color: #c8c8c8;
            height: 50px;
          }
        }

        .tableUI {
          border-top: none;
        }
      }
    }
  }
}
