.cardUI {
  & > div {
    border: none;
    border-radius: 10px;

    .card__body {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      min-height: 120px;
      padding: 0.5em;
      border-radius: 10px 10px 0 0;
      background: #00a8ff;

      & > span {
        color: #ffffff;
        font-size: 52px;
        font-weight: bold;
      }

      & > svg {
        width: 60px;
        margin-left: 3em;
        fill: #ffffff;
      }
    }

    .card__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;
      border-right: 1px solid;
      border-bottom: 1px solid;
      border-left: 1px solid;
      border-radius: 0 0 10px 10px;

      & > span {
        font-weight: bold;
      }

      & > button {
        margin: 0;
        padding: 0;
        border: none;
        background: transparent;

        &:focus,
        &:hover {
          border: none;
          background: transparent;
          box-shadow: none;
        }

        svg {
          width: 32px;
          margin: 0;
        }
      }
    }
  }
}
