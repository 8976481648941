.patient-prescription-modal {
    .modal-dialog.modal-lg {
      max-width: 1400px;
    }
  
    .modal-content {
      border-radius: 7px !important;
  
      .modal-body {
        padding: 0;
        border-radius: 7px !important;
  
        .patient-prescription {
          padding: 2em;
          background-color: #00a8ff;
  
          .header {
            justify-content: space-between;
            align-items: center;
  
            p {
              color: #fff;
              text-transform: uppercase;
              font-family: 'Poppins', sans-serif;
              font-size: 25px;
              margin: 0;
              font-weight: bold;
            }
          }
        }
  
        .view-form {
          padding: 2em;
  
          .inline-group {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
  
            label {
              flex: 1;
              margin: 0 !important;
            }
  
            input {
              flex: 2;
            }

            input.no-border {
              border: none;
              padding: 0;
            }
          }
  
          .inline-group.date-cont {
            input {
              flex: 1;
            }
          }
  
          h2 {
            font-size: 20px;
            font-weight: bold;
  
            &.header {
              border-bottom: 1px solid #00a8ff;
              padding-bottom: 10px;
            }
          }
  
          label {
            font-weight: bold;
          }
  
          .buttons-cont {
            display: flex;
            gap: 10px;
            justify-content: center;
  
            .btn.add-button {
              background-color: #07c900;
              color: #fff;
              border: none;
            }
  
            .btn.cancel-button {
              background-color: #b4b4b4;
              color: #fff;
              border: none;
            }
          }
  
          .check-boxes-cont {
              display: flex;
              gap: 1em;
          }
        }
      }
    }
  }
  