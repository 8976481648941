.doctorFilter {
  margin-bottom: 0.5em;

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 2em;

    & > button {
      margin-left: 1em;
    }
  }

  .patient-header {
    margin-top: 3em;
  }

  .custom {
    display: flex;
    gap: 1em;
    
    .container-tb {
      padding: 0;
      display: flex;
      flex: 1;
    }
    
    .text-box {
        width: 100%;
      }
  }
}
