.reports {
  button {
    margin: 10px;
  }
  .filterUI {
    background-color: #fff;
    padding: 10px;
    border: #fff;
    border-radius: 5px;
  }

  .reports-header {
    display: flex;
    padding: 16px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: baseline;

    h5 {
      color: #00A8FF;
    }
  }

  .reports-filter {
    span {
      color: #00A8FF;
    }
  }

  .reports-type {
    .header {
      background-color: #00A8FF;
      padding: 15px;
      color: #ffffff;
      font-size: 20px;
      border-color: #00A8FF;
      border-radius: 10px 10px 0 0;
    }

    .action span {
      float: right;
    }
  }
}
