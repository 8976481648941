.activity-log {
  .tableUI {
    border: none;

    .filter.mt-3 {
      margin-top: 0 !important;

      .search-filter {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        input {
          height: 50px;
        }

        .buttonWrapper {
          display: flex;
          gap: 10px;

          Button.btn {
            height: 50px;
          }
        }
      }
    }
  }
}
