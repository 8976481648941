.chart-of-account-filter {
    .controls {
      display: flex;
      justify-content: space-between;
      // width: auto;
    }

    .filters {
      .dropdown {
        border: 1px solid darken(#dee2e6, 20%);
        padding: 8 30px !important;
        
        .dropdown-menu {
          font-size: 12px;

          .dropdown-header {
            font-weight: 600;
            font-size: 12px;
          }

          .dropdown-item {
            font-size: 12px;
            color: lighten(#000, 40%)
          }
        }
        
        .btn {
          font-size: 14px;
          text-transform: capitalize !important;
          border-radius: 0;
          padding: 5px 30px !important;
          // font-weight: 600;
        }
      }
    }

    .buttons {
      display: flex;
      // justify-content: flex-end;
      .btn {
        border: none;
        border-radius: 3px;
        margin: 0 7px;
        padding: 6px 30px;
        font-size: 14px;
        display: flex;
        align-items: center;
      }

      svg {
        width: 15px;
        height: inherit;
      }

      .btn:last-of-type {
        margin-right: 0 !important;
      }
    }
}
