.user-account {
  position: relative;
  z-index: 99;
  margin-left: 28px;
}

.userSVG{
  width: 28px;
}

#circle-user{
  fill: #6ac9de;
}

.user-account-cont {
  background-color: #fff;
  padding: 1rem;
  width: max-content;

  .account-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border-bottom: solid 1px #dedede;
    padding-bottom: 10px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    button.btn.btn-primary {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      color: #000000;
      text-transform: unset;
    }


    span {
      font-size: 1rem;
    }
  }


  .profile-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px 0 0;

    .profile-default-logo {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    button.btn.btn-primary {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      color: #000000;
      text-transform: unset;
      flex: 1;
    }
  }
}