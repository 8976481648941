.filter {
  label {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .header-line {
    height: 1px;
    margin: 1em 0;
    background-color: #00a8ff;
    width: 100%;
  }

  .header {
    color: #00a8ff;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .more-filters-cont {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;

    select.form-select {
      width: 14em;
      padding: 0.5rem 1rem;
    }

    .btn {
      background-color: #d59c00;
      border: none;
    }
  }

  .basic-search {
    .input-group {
      input {
        max-width: unset !important;
      }
    }
  }

  .category-buttons {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    .btn.btn-secondary,
    .btn.btn-primary {
      border: none;
      height: 50px;
      padding: 0 2em;
    }

    .btn.btn-primary.add-button {
      background-color: #07c900;
    }

    .btn.btn-secondary.reset-button {
      background-color: #b4b4b4;
    }
  }

  .form-check {
    position: relative;

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
    }
  }
}
