.modalUI {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba(91, 91, 91, 0.5);

  .modal-dialog {
    width: 100%;
    max-width: fit-content;
    top: 120px;
  }

  .modal-content {
    border: none;
    border-radius: 10px;
  }

  .modal-header {
    padding: 2em;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .modal-body {
    background-color: #fff;
  }

  .modal-footer {
    padding: 2em;
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .modal-header,
  .modal-footer {
    border: none;
  }
}

.modalUI.enableScroll {
  height: 100%;
  position: static;

  .modal-dialog {
    margin: 0;
    width: 100%;
    height: 100%;
    max-width: 100% !important;
  }
}
