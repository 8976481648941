#print_cashflow_statement {
  padding: 20px;

  .header {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 170px;
      height: auto;
    }

    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      margin-top: 15px;
    }

    h3 {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      // color: #6c757d
    }
  }

  .asset-name {
    min-width: 50px;
  }

  .asset-name::after {
    content: ':';
    float: right;
  }

  .asset-amount {
    font-size: 16px;
    color: #00aeef;
    font-weight: bold;
  }
}