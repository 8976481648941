.set-professional-modal {
  .modal-dialog.modal-lg {
    max-width: 500px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .request-for-repair-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .adjustment-form {
        padding: 2em;

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 2;
            margin: 0 !important;
            font-weight: bold;
          }

          input {
            flex: 2;

            &::placeholder {
              color: #00aeef;
            }
          }

          input.no-border {
            border: none;
            padding: 0;
          }
        }

        hr {
          background-color: #00a8ff;
          height: 2px;
        }

        .form-checkbox {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .form-check {
            margin-bottom: 25px;
          }
        }

        .bg-grey {
          background-color: #d6d6d6;
        }

        .upload-image {
          width: fit-content;
          margin: 0 auto;

          input {
            display: none;
          }

          p {
            font-weight: bold;
            text-align: center;
            font-size: 14px;

            span {
              display: block;
              font-size: 12px;
              font-weight: normal;
            }
          }

          .cont {
            border: solid 1px #c8c8c8;
            border-radius: 10px;
            padding: 1rem;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            svg {
              width: 50px;
            }

            label {
              font-weight: normal;
              cursor: pointer;
              font-size: 13px;
              margin: 0;

              b {
                color: #00a8ff;
                text-decoration: underline;
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-start;
          gap: 1em;
          height: 100%;
          align-items: flex-end;

          button.btn.btn-primary {
            border: none;

            &.print {
              background-color: #ff0000;
            }

            &.save {
              background-color: #06B10C;
            }

            &.cancel {
              background-color: #b4b4b4;
            }
          }
        }

        label {
          font-weight: bold;
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-start;
          gap: 1em;
          height: 100%;
          align-items: flex-end;

          button.btn.btn-search {
            margin-bottom: 13px;
          }
        }

        .header {
          p {
            font-weight: bold;
          }
        }

        .tableUI {
          border-top: none;

          h3 {
            font-weight: bold;
            font-size: 20px;
            margin: 0;
            padding: 1rem 0 0;
          }
        }

        .table {
          tbody {
            td {
              span {
                display: block;
                background-color: #00a8ff;
                color: #fff;
                text-align: center;
                padding: 2px 0;
                border-radius: 7px;
                width: fit-content;
                padding: 0 10px;
                margin: 0 auto;
                min-width: 90px;

                &.receiving {
                  background-color: #06b10c;
                }

                &.in-progress {
                  background-color: #e49f00;
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.request-for-repair {
            background-color: #07c900;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            border: none;
          }
        }

        .adjustment-item-list {
          label,
          h6 {
            font-weight: bold;
          }
        }
      }

      .receiving-cont {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: solid 1px #212529;
        padding-top: 1rem;

        h3,
        h4 {
          font-weight: bold;
          font-size: 20px;
        }

        p {
          text-align: left;
          font-weight: bold;
          width: 100%;

          span {
            display: block;
            color: #ff5d5d;
          }
        }

        label {
          font-weight: bold;
        }
      }

      .count-control {
        display: flex;
        align-items: flex-end;
        height: 100%;
        gap: 10px;
        justify-content: flex-start;

        svg {
          margin-bottom: 20px;
          cursor: pointer;
        }
      }
    }
  }
}
