// .dashboardCard {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   align-items: center;
//   width: 100%;
//   max-width: 260px;
//   height: 200px;
//   margin: 1em;
//   padding: 1.5em 1em;
//   border-radius: 15px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 3px 3px rgba(0, 0, 0, 0.12);

//   &:hover {
//     border: 2px solid #6DC9DD;
//     transition: border;
//   }

//   svg {
//     width: 80px;
//     margin-bottom: 1.5em;
//     fill: #6DC9DD;
//   }

//   h5 {
//     margin: 0 auto;
//     color: #000000;
//     font-family: Poppins;
//     font-weight: 500;
//     font-size: 16px;
//     text-align: center;
//     line-height: 24px;
//     text-transform: uppercase;
//   }

//   a {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
//     cursor: pointer;
//   }

//   &.disabled {
//     background-color: gray;
//     pointer-events: none;

//     svg {
//       fill: gray;
//     }

//     h5 {
//       color: gray;
//     }

//     a {
//       cursor: not-allowed;
//     }
//   }
// }

.dashboardCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 260px;
  height: 200px;
  margin: 1em;
  padding: 1.5em 1em;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 3px 3px rgba(0, 0, 0, 0.12);

  &:hover {
    border: 2px solid #6DC9DD;
    transition: border;
  }

  svg {
    width: 80px;
    margin-bottom: 1.5em;
    fill: #6DC9DD;
    transition: fill 0.3s ease; // Adding transition for smooth color change
  }

  h5 {
    margin: 0 auto;
    color: #000000;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    transition: color 0.3s ease; // Adding transition for smooth color change
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;

    svg {
      fill: #D0D4D7;
    }

    h5 {
      color: #D0D4D7;
    }

    a {
      cursor: not-allowed;
    }
  }
}