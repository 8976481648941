.attendance-table {
  width: 100%;
  border-top: solid 2px #e9e9e9;

  .main-table {
    .tableUI {
      border: none;

      table {
        margin: 0;

        thead {
          border-bottom: 1px solid #DEE2E6;

          tr {
            th {
              color: #00AEEF;
              background-color: #fff;
            }
          }
        }

        tbody {
          tr {
            td {
              .badge {
                font-size: 12px;

                &.green {
                  background: #07c700;
                }
              }

              .attendance-actions {
                gap: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .avatar {
                width: 5rem;
                height: 5rem;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}