.view-record-modal {
  padding-top: 90px !important;

  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;
      .add-record-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .view-patient-details {
        padding: 60px;

        p {
          color: #000 !important;
          font-size: 15px
        }
      }

    }

    .modal-footer {
      padding: 20px 50px
    }
  }
}
