.credentials-credits {
  .modal-content {
    .modal-header {
      background-color: #00aeef;
    }

    .modal-body {
      gap: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px;

      button {
        flex: 1;
      }

      .main-content {
        gap: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .accordion-header {
          font: normal normal medium 15px/25px Poppins;
          letter-spacing: 0px;
          opacity: 1;
          background: #00A8FF 0% 0% no-repeat padding-box;
          width: 100%;
          height: 2%;
          padding: 0;
          .accordion-button,
          .accordion-button.collapsed {
            padding-left: 2%;
            color: #00aaff;
            border-radius: 5px;
            background: none;
            box-shadow: none;
        }
        }

        .accordion-body {
          padding-left: 2%;
          margin: 0;
          width: 100%;
          flex: 1;

          .badge {
            font-size: 12px;
            color: #3c3c3c;
            &.green {
              background: #5aca57;
              color: #047400;
              font-size: 16px;
              width: 100px;
              padding: 14px 0;
            }
            &.red {
              background-color: #FF8282;
              color: #700000;
              font-size: 16px;
              width: 100px;
              padding: 14px 0;
            }
          }
        }

        .details-content {

          textarea.form-control,
             select.form-select {
              border-radius: 7px !important;
            }
      
          select.form-select {
             max-width: 10rem;
            }
        }

        .inline-group {
          gap: 3rem;
          display: flex;
        }

        h5 {
          font-weight: bold;
          color:#00aeef;
        }

        .vertical-align {
          flex: 1;

          label {
            font-weight: bold;
          }

          input.border-seven {
            height: 46px;
            border-radius: 7px !important;
          }
        }

          .credits-table {
            .tableUI {
              border: none;
        
              table {
                margin: 0;
        
                thead {
                  border-bottom: 1px solid #DEE2E6;
                  
                  tr {
                    th {
                      color: #00AEEF;
                      background-color: #fff;
                    }
                  }
                }
        
                tbody {
                  tr {
                    td {
                      .badge {
                        font-size: 12px;
        
                        &.link {
                          width: 166px;
                        }
                      }
                    }
                  }
                  a {
                    color: #00AEEF;
                  }
                }
              }
            }
          }
      }
    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 32px;
    }

    &.btn.edit {
      background-color: #07c700;
    }

    &.btn.copy {
      background-color: #00AEEF;
    }
  }
}