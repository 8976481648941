.UpdatePatientProfile {
    // padding: 2em;
    border-radius: 10px;
    background: #ffffff;
  
    .avatar__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin: 0 auto;
      padding: 2em 0;
  
      .uploadedAvatar {
        width: 160px;
        height: 160px;
        margin: 0 auto;
        border-radius: 80px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
  
      .patient__details {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1em;

        .patient__newborn {
          color: #fff;
          background-color: #C90000;
          text-transform: uppercase;
          font-family: 'Poppins', sans-serif;
          font-size: 12px;
          display: block;
          line-height: 1;
          height: max-content;
          padding: 7px;
          border-radius: 5px;
        }

        .patient__number {
          color: #00a8ff;
          font-weight: 600;
          margin: 0;
    
          span {
            color: black;
          }
        }
      }

      .patient__info {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 1rem;
      }
    }

    .formUI {
      .form-label {
        font-size: 14px;
        font-weight: bold;
      }
  
      .patientIdentity__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
  
        .patientIdentity {
          position: relative;
          flex: 1 0 45%;
          max-width: 720px;
          margin: 1em 1em 1em 0;
          padding: 1.5em;
          border-radius: 10px;
          background: #f5f5f5;
  
          .identityDocuments {
            width: 100%;
            background: #f5f5f5;
          }
  
          button {
            position: absolute;
            top: -10px;
            right: 10px;
          }
        }
      }
  
      .familyDetails__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
  
        .familyDetails {
          position: relative;
          margin: 1em 1em 1em 0;
          padding: 1.5em;
          border-radius: 10px;
          background: #f5f5f5;
        }
  
        button {
          position: absolute;
          top: -10px;
          right: 10px;
        }
      }
  
      .accordionUI {
        .accordion-header {
  
          &>.accordion-button,
          &>.accordion-button.collapsed {
            color: #e9ca00;
            font-size: 20px;
            font-weight: bold;
            border-bottom: 2px solid #e9ca00;
          }
  
          &>.accordion-button::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E9CA00'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
          }

          &>.accordion-button:not(.collapsed)::after {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E9CA00'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
          }
        }
      }

      .buttons-cont {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    
        .btn.add-button {
          background-color: #07c900;
          color: #fff;
          border: none;
        }
    
        .btn.cancel-button {
          background-color: #b4b4b4;
          color: #fff;
          border: none;
        }
      }

      .patient-profile {
        background-color: #00a8ff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 1.5em;
        border-radius: 10px;
        flex-direction: column;

        h3,
        p {
          margin: 0;
          color: #fff;
        }

        h3 {
          font-weight: bold;
          font-size: 30px;
        }

        p {
          font-size: 16px;
          font-weight: normal;
        }

        .first-row,
        .second-row {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }

        .second-row {
          h3 {
            font-size: 16px;
            font-weight: normal;
          }
        }
      }

      h2 {
        color: #00A8FF;
      }
    }
  }