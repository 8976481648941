.advancedSearch {
    .formCheckWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
    }

    .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 2em;

        &>button {
            margin-left: 1em;
        }
    }
}

#doctors-autocomplete {
    cursor: pointer;
    color: #404040;
    font-size: 16px;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0.5rem;
    padding-left: 10px;
    ;
}

#doctors-autocomplete:hover {
    background-color: #707070;
}

.dataResult-hidden {
    display: none;
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    display: hidden;
}

.dataResult {
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    top: 5rem;
}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
    cursor: pointer;
    padding-left: 10px;
}

.dataItem:hover {
    background-color: lightgrey;
}