.employees-view {
  .tabsUI {
    .nav.nav-tabs {
      border: none;
      gap: 25%;

      .nav-item {
        margin-right: 0.5em;
      }

      .nav-link {
        color: #b4b4b4;
        font-size: 25px;
        background-color: transparent;
        border: none;
        padding: 0.5em 1em;
      }

      .nav-link:hover,
      .nav-link:focus,
      .nav-link:active {
        border-color: transparent;
      }

      .nav-link.active {
        color: #00a8ff;
        border-color: #00a8ff;
        border: none;
        border-bottom: 4px solid #00a8ff;
        background-color: transparent;
      }
    }

    .tab-content {
      .tab-pane.active {
        padding: 0;
      }
    }
  }

  .employee-profile {
    .profile-header {
      box-shadow: 0 0.125rem 60px rgba(0, 0, 0, 0.075);
      display: flex;
      flex-direction: column;
      border-radius: 10px 10px;

      img {
        height: 120px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
      }
    }

    .header-information {
      background-color: #fff;
      display: flex;
      // align-items: center;
      border-radius: 0 0 10px 10px;
      // gap: 100px;

      [class^=col] {
        padding: 0 2rem;

        &:last-child {
          display: flex;
          flex: 1 1;
          flex-direction: column;

          .row:first-child {
            margin-top: auto;
          }
        }
      }

      .row {
        display: flex;

        & * {
          width: auto;
        }
      }

      .profile-picture {
        width: 200px;
        height: 200px;
        // height: 130px;
        position: relative;
        top: -50px;
        border-radius: 10px;
        background: #FFFFFF;
        border: 5px solid #FFFFFF;
      }

      .p-header {
        margin-top: 1rem;
        padding: 0;

        p {
          font-size: 40px;
          font-weight: bold;
          margin: 0;
        }

        span {
          color: #00aeef;
          display: block;
        }

        &:last-child {
          text-align: right;
          margin-left: auto;
        }
      }

      .go-back {
        color: #00a8ff;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        margin-top: 1rem;
        margin-bottom: 1rem;
        margin-left: auto;
      }
    }

    .employee-details {
      border-radius: 10px;
      box-shadow: 0 0.125rem 60px rgba(0, 0, 0, 0.075);
      padding: 1rem;
      background: #fff;

      .tabsUI {
        position: relative;
      }

      .edit-profile-cont {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button.btn.edit-profile {
          background-color: #1ac100;
          color: #fff;
          border: none;
        }
      }

      .employee-profile-tab,
      .leave-credits-tab {
        h4 {
          font-size: 18px;
        }

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 2;
            margin: 0 !important;
            font-weight: bold;
          }

          input {
            flex: 2;

            &::placeholder {
              color: #00AEEF;
            }
          }

          input.no-border {
            border: none;
            padding: 0;
          }
        }

        .input.no-border {
          border: none;
          padding: 0;
        }
      }

      .shifting-tab {
        .shifting-schedule {
          margin: 0;
        }

        .tableUI {
          border: none;

          .table {
            margin: 10px 0 1em;
          }
        }
      }
    }
  }
}