.formLoginUI {
  h4,
  h5,
  h6 {
    margin: 0;
    color: #00a8ff;
    font-weight: bold;
    text-align: center;
  }

  p {
    color: #00a8ff;
    text-align: center;
  }

  .wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > h6 {
      position: absolute;
      top: 30%;
      left: 30%;
      margin: 0 auto;
    }
  }

  .form-label {
    color: #00a8ff;
  }

  .form-control,
  .form-select {
    padding: 0.75em 1em;
    color: #2e2e2e;
    font-size: 18px;
    border-color: #c8c8c8;
    border-radius: 10px;
    background: none;

    &::placeholder {
      color: #c8c8c8;
    }

    &:focus {
      background: none;
      box-shadow: none;
      outline: none;
    }
  }

  .input-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    & > .svgContainer:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 56px;
      margin: 0;
      padding: 0.5em 1em;
      border: 1px solid #c8c8c8;
      border-right: none;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      & > svg {
        width: 22px;
        fill: #00a8ff;
      }
    }

    & > .divider {
      width: 1.5px;
      height: 18px;
      background: #00a8ff;
    }

    .form-control {
      width: 100%;
      border-left: none;
    }
  }

  .input-group.password {
    & > .svgContainer:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 56px;
      margin: 0;
      padding: 0;
      border: 1px solid #c8c8c8;
      border-left: none;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      button {
        border: none;
        background: none;
        box-shadow: none;
        outline: none;

        &:focus,
        &:hover,
        &:active {
          border: none;
          background: none;
          box-shadow: none;
          outline: none;
        }
      }

      & > svg {
        width: 20px;
        fill: #00a8ff;
      }
    }

    & > .form-control {
      border-right: none;
      border-left: none;
      border-color: #c8c8c8;
    }
  }

  .button--rememberMe {
    position: relative;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    margin-right: 0.5em;
    padding: 0.5em;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    background: none;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
    }

    &:focus,
    &:hover {
      background: none;
      box-shadow: none;
    }
  }

  .form__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1.5em 0 1em;

    button {
      margin-bottom: 1em;
      padding: 0.5em 1.75em;
      font-size: 18px;
      text-transform: uppercase;
      border-color: #00a8ff;
      border-radius: 10px;
      background: #00a8ff;

      &:focus,
      &:hover,
      &:active {
        border-color: #00a8ff;
        box-shadow: none;
        outline: none;
      }
    }
  }

  a {
    color: #00a8ff;
    font-size: 15px;
    text-decoration: none;
  }

  .codeWrapper {
    display: flex;
    flex-wrap: nowrap;
    margin: 3em 0;

    .form-control--code {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.25em;
      padding: 0;
      font-size: 40px;
      text-align: center;
      border: none;
      border-bottom: 3px solid #00a8ff;
      border-radius: 0;
    }
  }
}
