.goods-receipts {
  .advanced-search {
    .accordion-item {
      border: none;

      .accordion-header {
        .accordion-button {
          font-weight: bold;
          padding: 1rem 1.5rem;
          color: #00a8ff;
          background-color: #fff;
          box-shadow: none;
          border-bottom: 2px solid #000;

          &.collapsed {
            color: #000;
            border-bottom: none;
          }

          &::after {
            margin-right: auto;
            position: absolute;
            left: 0;
          }
        }
      }

      .accordion-body {
        label {
          font-weight: bold;
        }

        .buttonWrapper {
          display: flex;
          justify-content: flex-start;
          gap: 1em;
          height: 100%;
          align-items: center;
        }

        .purchase-order-items-buttons {
          display: flex;
          justify-content: flex-end;
          gap: 1em;
          align-items: center;

          .btn.red {
            background-color: #fb8c8c;
            border-color: #fb8c8c;
          }

          .btn.blue {
            background-color: #00a8ff;
            border-color: #00a8ff;
          }
        }

        .purchase-order-items-table {
          tbody {
            tr {
              td {
                p {
                  margin: 0;
                  line-height: 1;

                  span {
                    font-size: 12px;
                  }
                }

                span.in-progress {
                  background-color: #d69e04;
                  width: max-content;
                  display: block;
                  border-radius: 8px;
                  color: #fff;
                  font-size: 12px;
                  padding: 2px 5px;
                  margin: 0;
                }
              }
            }
          }
        }

        .related-purchase-request {
          .tableUI {
            border-top: none;

            .table {
              // thead {
              //   tr {
              //     th {
              //       padding: 10px;
              //     }
              //   }
              // }

              tbody {
                tr {
                  td {
                    // padding: 10px;

                    p {
                      margin: 0;

                      span {
                        display: block;
                        background-color: transparent;
                        padding: 0;
                        font-size: 12px;
                        color: #212529;
                        text-align: left;
                        margin: 0;
                      }
                    }

                    span {
                      display: block;
                      background-color: #00a8ff;
                      color: #fff;
                      text-align: center;
                      padding: 2px 0;
                      border-radius: 7px;
                      width: fit-content;
                      padding: 0 10px;
                      margin: 0 auto;
                      min-width: 90px;

                      &.for-approval {
                        background-color: #016aa1;
                      }

                      &.in-progress {
                        background-color: #e49f00;
                      }
                    }
                  }

                  td.action {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                  }
                }
              }
            }
          }

          .buttons {
            display: flex;
            gap: 1em;
            align-items: center;

            button.active {
              background-color: #0069f2;
            }

            button {
              background-color: #c8c8c8;
              border: none;
              height: min-content;
              flex: 1;
              font-size: 13px;
            }
          }

          .popoverButton:focus {
            box-shadow: none;
          }
        }
      }
    }
  }

  .purchase-order-list {
    .form-label {
      font-weight: bold;
    }

    .form-control {
      padding: 0.75em 1em;
      border-radius: 10px;
    }

    .buttonWrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 1rem;
      height: 100%;

      button.btn {
        height: 50px;
      }
    }
  }
}

.inventory-good-receipts-modals {
  .modal-dialog.modal-lg {
    max-width: 1400px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .inventory-good-receipts {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .view-form {
        padding: 2em;

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 1;
            margin: 0 !important;
          }

          input {
            flex: 2;
          }

          input.no-border {
            border: none;
            padding: 0;
          }
        }

        .inline-group.date-cont {
          input {
            flex: 1;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: bold;

          &.header {
            border-bottom: 1px solid #00a8ff;
            padding-bottom: 10px;
          }
        }

        label {
          font-weight: bold;
        }

        .buttons-cont {
          display: flex;
          gap: 10px;
          justify-content: flex-end;

          .btn.add-button {
            background-color: #07c900;
            color: #fff;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            color: #fff;
            border: none;
          }
        }

        .check-boxes-cont {
          display: flex;
          gap: 1em;
        }

        .buttonWrapper {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: 100%;

          button.btn {
            height: 50px;
          }
        }

        .button-submit {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button.btn {
            background-color: #13bb00;
            border: none;
          }
        }

        .table-cont {
          .tableUI {
            border: none;
          }
        }

        .free-goods-item-information {
          .accordion-item {
            border: none;

            .accordion-header {
              .accordion-button {
                font-weight: bold;
                padding: 1rem 1.5rem;
                color: #00a8ff;
                background-color: #fff;
                box-shadow: none;
                border-bottom: 2px solid #000;

                &.collapsed {
                  color: #000;
                  border-bottom: none;
                }

                &::after {
                  margin-right: auto;
                  position: absolute;
                  left: 0;
                }
              }
            }

            .accordion-body {
              label {
                font-weight: bold;
              }

              .check-cont {
                display: flex;
                align-items: flex-end;
                height: 100%;
              }
            }
          }
        }

        .quantity-per-slot {
          h4 {
            font-weight: bold;
            font-size: 1rem;
            border-bottom: solid 1px #000;
            padding-bottom: 10px;
          }

          p {
            font-weight: bold;
            margin: 0;
          }
        }

        .blue-background {
          background-color: #00a8ff;
          color: #fff;
          font-weight: normal;
          padding: 4px 10px;
        }

        .dual-input {
          display: flex;
          justify-content: space-between;
          gap: 1rem;

          .div-group {
            margin-top: 1rem;
            flex: 1;
          }

          .div-group.button-cont {
            display: flex;
            align-items: flex-end;

            button.btn {
              height: 50px;
              background-color: #13bb00;
            }
          }
        }

        .lot-result-table {
          .tableUI {
            border: none;

            .table {
              margin: 10px 0 0;
            }
          }
        }
      }
    }
  }
}

.goods-receipt-information-modals {
  .modal-dialog.modal-lg {
    max-width: 1400px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .inventory-good-receipts {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .view-form {
        padding: 2em;

        .inline-group {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;

          label {
            flex: 1;
            margin: 0 !important;
          }

          input {
            flex: 2;
          }

          input.no-border {
            border: none;
            padding: 0;
          }
        }

        .inline-group.date-cont {
          input {
            flex: 1;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: bold;

          &.header {
            border-bottom: 1px solid #00a8ff;
            padding-bottom: 10px;
          }
        }

        label {
          font-weight: bold;
        }

        .buttons-cont {
          display: flex;
          gap: 10px;
          justify-content: flex-end;

          .btn.add-button {
            background-color: #07c900;
            color: #fff;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            color: #fff;
            border: none;
          }
        }

        .check-boxes-cont {
          display: flex;
          gap: 1em;
        }

        .buttonWrapper {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: 100%;

          button.btn {
            height: 50px;
          }
        }

        .button-submit {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          button.btn {
            background-color: #13bb00;
            border: none;
          }
        }

        .table-cont {
          .tableUI {
            border: none;
          }
        }

        .free-goods-item-information {
          .accordion-item {
            border: none;

            .accordion-header {
              .accordion-button {
                font-weight: bold;
                padding: 1rem 1.5rem;
                color: #00a8ff;
                background-color: #fff;
                box-shadow: none;
                border-bottom: 2px solid #000;

                &.collapsed {
                  color: #000;
                  border-bottom: none;
                }

                &::after {
                  margin-right: auto;
                  position: absolute;
                  left: 0;
                }
              }
            }

            .accordion-body {
              label {
                font-weight: bold;
              }

              .check-cont {
                display: flex;
                align-items: flex-end;
                height: 100%;
              }
            }
          }
        }

        .quantity-per-slot {
          h4 {
            font-weight: bold;
            font-size: 1rem;
            border-bottom: solid 1px #000;
            padding-bottom: 10px;
          }

          p {
            font-weight: bold;
            margin: 0;
          }
        }

        .blue-background {
          background-color: #00a8ff;
          color: #fff;
          font-weight: normal;
          padding: 4px 10px;
        }

        .dual-input {
          display: flex;
          justify-content: space-between;
          gap: 1rem;

          .div-group {
            margin-top: 1rem;
            flex: 1;
          }

          .div-group.button-cont {
            display: flex;
            align-items: flex-end;

            button.btn {
              height: 50px;
              background-color: #13bb00;
            }
          }
        }

        .lot-result-table {
          .tableUI {
            border: none;

            .table {
              margin: 10px 0 0;
            }
          }
        }

        .goods-tabs {
          border-bottom: 1px solid #00a8ff;
          position: relative;

          .nav-item {
            border-bottom: 1px solid #00a8ff;

            .nav-link {
              background-color: #fff;
              color: #00a8ff;
            }

            .nav-link.active {
              background-color: #00a8ff;
              color: #fff;
            }
          }

          .nav-item:last-child {
            display: flex;
            position: absolute;
            right: 0;

            .nav-link {
              background-color: #00a8ff;
              color: #fff;
            }
          }
        }

        .purchase-information,
        .item-receiving,
        .receive-report {
          .form-check {
            height: 100%;
            padding-top: 1.5rem;
          }

          .see-form {
            color: #00a8ff;
            background: none;
            border: none;
          }

          .radios-cont {
            display: flex;
            gap: 1rem;
            justify-content: space-between;

            .form-check {
              padding: 10px 0 10px 1em;
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          align-items: center;

          button.btn.update-btn {
            background-color: #06b10c;
            border: none;
          }

          button.btn.cancel-btn {
            background-color: #c8c8c8;
            border: none;
          }
        }

        .tab-content {
          & > .active {
            padding-bottom: 0 !important;
          }
        }
      }
    }
  }
}
