.add-new-item-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .add-new-item-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .add-item-form {
        padding: 2em;

        .search-item,
        .item-information,
        .item-lots-and-expiration {
          .buttonWrapper {
            display: flex;
            justify-content: flex-start;
            gap: 1em;
            height: 100%;
            align-items: flex-end !important;

            Button.btn {
              height: 50px;
            }
          }

          label {
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }

          .basic-search {
            .bg-grey.form-control {
              border-top-right-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
            }

            .btn.btn-search {
              margin-left: 1em !important;
              border-radius: 10px !important;
            }
          }

          .advanced-search {
            .accordion-item {
              border: none;

              .accordion-header {
                .accordion-button {
                  font-weight: bold;
                  padding: 1rem 1.5rem;
                  color: #00a8ff;
                  background-color: #fff;
                  box-shadow: none;
                  border-bottom: 2px solid #000;

                  &.collapsed {
                    color: #000;
                    border-bottom: none;
                  }

                  &::after {
                    margin-right: auto;
                    position: absolute;
                    left: 0;
                  }
                }
              }
            }
          }

          .form-check {
            position: relative;

            input {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 0;
            }
          }

          h3 {
            font-weight: bold;
            font-size: 20px;
          }

          .selected-item {
            border-bottom: solid 1px #c8c8c8;
            padding-bottom: 1em;
          }

          .inline-group {
            display: flex;
            align-items: center;

            label,
            input,
            select,
            .locking-input {
              flex: 1;
            }

            .locking-input {
              display: flex;
              justify-content: space-between;
              gap: 1em;
            }

            .min-max {
              flex: 1;
              display: flex;
              gap: 10px;
            }
          }

          .switch-group {
            .form-check.form-switch {
              justify-content: flex-end;

              input {
                position: relative;
                margin: 0 10px 0 0;
                width: 36px;
              }
            }
          }
        }

        .item-lots-and-expiration {
          h3 {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .search-table {
          .tableUI {
            border-top: none;

            .table {
              tbody {
                tr {
                  td {
                    button {
                      padding: 0;
                      background-color: transparent;
                      color: #00a8ff;
                      border: none;
                      text-transform: capitalize;
                      font-weight: bold;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.add-button {
            background-color: #02961C;
            border: none;
          }

          .btn.cancel-button {
            background-color: #b4b4b4;
            border: none;
          }
        }
      }
    }
  }
}
