.chart-of-account-table {
  // .tableUI {
    border: none;
    table {
      border: 1px solid #6c757d;
      margin-top: 20px;
      thead {
        border: 1px solid #6c757d;
        
        th {
          background-color: transparent;
          border: none;
          color: #00a8ff;
          font-size: 14px;
          text-transform: uppercase;
          padding: 15px 8px;
        }
      }
      tbody {
        tr {
          border: none;
        }
        td {
          font-size: 12px;
          border: none;
          vertical-align:middle;

          button {
            font-size: 12px;
            text-transform: capitalize;
            font-weight: 600;
            text-decoration: none;
          }

          .edit_btn {
            color: #01b425;
            border: 2px solid #01b425;
            padding: 4px 12px;
            border-radius: 16px;
          }
        }
        tr {
          td:nth-of-type(3) {
            font-weight: bold;
          }
          .available {
            width: 100%;
            padding: 0.5em 1em;
            color: #ffffff;
            font-size: 14px;
            border-radius: 10px;
            background: #08a502;
          }
          .waiting {
            width: 100%;
            padding: 0.5em 1em;
            color: #ffffff;
            font-size: 14px;
            border-radius: 10px;
            background: #E2BD00;
          }
          .threedots:after {
            content: "\2026";
            font-size: 30px;
          }
          /* .status {

          } */
        }
      }

      .batch_edit_input,
      .batch_edit_select {
        border-radius: 0;
        font-size: 12px !important;
        // border: 1px solid #ced4da;
        // border: none;
        // background-color: transparent;
        box-shadow: none;
      }
    }

    .page_controls {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .result_count {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 16px;
        margin-right: 15px;
        border: 2px solid #01b425;
        padding: 4px 12px;
        border-radius: 16px;
        color: #01b425
      }

      .pagination {
        .active > span {
          background-color: #00a8ff !important;
        }
  
        li {
          margin-left: 5px;
        }
  
        li > span,
        li > a {
          border-radius: 0 !important;
          font-size: 14px;
          padding: 4px 12px;
          // border: 1px solid #6c757d;
        }
      }
    }
  // }

  .disable_caret {
    background-image: none !important;
  }
}
