.badge {
  width: auto;
  padding: 10px 5px;

  text-transform: capitalize;
}

.bg-in-progress {
  background-color: #00A8FF66;
}

.bg-completed {
  background-color: #02961C4D;
}

.bg-denied {
  background-color: #DE00004D;
}

.bg-neutral {
  background-color: #f2f2f2;
}
