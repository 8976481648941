.housekeeping {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button.btn {
        background-color: #19B187;
        border: none;
        text-transform: capitalize;
    }
}