.order-work-list {
  .work-list-tabs {
    ul.nav.nav-tabs {
      .nav-item {
        margin-right: 1.5em;

        .nav-link {
          color: #b4b4b4;
          background-color: transparent;
          border: none;
          font-size: 20px;
          font-weight: bold;
          padding: 0.5rem 0 0;
        }

        .nav-link.active {
          color: #00a8ff;
          background-color: transparent;
          border: none;
          border-bottom: solid 3px #00a8ff;
        }
      }
    }

    .tab-content {
        .tab-pane.active {
            padding: 1em 0 2em;

            .tableUI {
                border-top: none;
            }
        }
    }
  }
}
