.filterCardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  padding: 0;

  & > div {
    max-width: 18%;
    flex: 1 0 18%;
  }

  .card__body,
  .card__footer {
    border-radius: 0 !important;
  }
}
