.containerLogin {
  width: 100%;
  max-width: 480px;

  .containerLogin__header {
    width: 100%;
    margin-bottom: 2em;

    .containerLogin__logo {
      width: 100%;
    }
  }

  .containerLogin__body {
    width: 100%;
    padding: 2em;
    border-radius: 20px;
    background: #ffffff;
  }
}

@media (max-width: 575px) {
  .loginUI {
    justify-content: flex-start;
  }

  .containerLogin {
    .containerLogin__body {
      width: auto;
      padding: 1rem;
      margin: 0 1rem;
    }
  }
}
