.appointmentListFilter {
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;

    &>button {
      margin-left: 1em;
    }
  }
}

#doctors-autocomplete {
  cursor: pointer;
  color: #404040;
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5rem;
  padding-left: 10px;
  ;
}

#doctors-autocomplete:hover {
  background-color: #707070;
}