.formVIewPatient {
  padding: 2em;
  border-radius: 10px;
  background: #ffffff;

  .avatar__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    padding: 4em 0 2em;

    .uploadedAvatar {
      width: 160px;
      height: 160px;
      margin: 0 auto;
      border-radius: 80px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }

  .svg__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    border: 2px solid #707070;
    border-radius: 110px;

    svg {
      width: 140px;
    }
  }

  .avatar-details {
    font-family: "Poppins", sans-serif;

    .patient-name {
      text-align: center;
      font-size:38px;
      font-weight: 500;
    }

    .patient-id {
      text-align: center;
      font-size:20px;
      font-weight: 500;
      color: #00A8FF;
    }

    .patient-gender-age {
      text-align: right;
      font-size:18px;
      padding-right: 4em;
    }

    .patient-birthdate {
      text-align: left;
      font-size:18px;
      padding-left: 4em;
    }
  }

  .patient-details {
    background: #00a8ff;
    border-radius: 9px;
    border-color: #00a8ff;
    padding: 13px;
    color: #ffffff;
    margin: 2em 0;
    font-size: 20px;
      h3 {
        font-weight: bolder;
      }
      .col {
        margin: 5px;
      }

    .right {
      border-right-color: #fff;
      border-right-style: solid;
    }

    .left {
      border-left-color: #fff;
      border-left-style: solid;
      padding-left: 30px;
    }
  }

  .accordionUI {

    .accordion-header {

      .accordion-button {
        font-size: 25px;
        border-bottom: 1px solid #25aaff !important;
      }
    }
  }

}
