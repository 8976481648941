.add-financial-statement {
  padding: 1rem 0;

  .financial-details {
    padding: 1em 0 0;

    h5 {
      font-size: 20px;
      color: #00aeef;
      font-weight: bold;
    }

    h6 {
      font-weight: bold;
      font-size: 13px;
    }

    .bold {
      width: 100%;
      display: block;
      height: 1px;
      background-color: #000;
    }

    .hr {
      border-bottom: solid 1px #707070;
      width: 100%;
      height: 1px;
    }

    .inline-label {
      position: relative;
      width: 100%;

      label {
        position: absolute;
        background-color: #fff;
        font-size: 13px;
        top: -9px;
        left: 14px;
        font-weight: bold;
      }
    }

    .inline-label.width-80 {
      width: 80%;
      margin-left: auto;
    }

    .inline-label.width-70 {
      width: 70%;
    }

    .left-auto {
      margin-left: auto;
    }

    .right-auto {
      margin-right: auto;
    }

    .total-cont {
      p {
        display: inline-flex;
        gap: 1rem;
        margin: 0;
        font-weight: bold;
        font-size: 25px;

        span {
          color: #ff0000;
          font-size: 25px;
        }
      }
    }

    .inline-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      label {
        flex: 2;
        margin: 0 !important;
        font-weight: bold;
      }

      input {
        flex: 2;

        &::placeholder {
          color: #00aeef;
        }
      }

      input.no-border {
        border: none;
        padding: 0;
      }
    }

    .form-buttons {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;

      button.btn.print {
        border: none;
        color: #fff;
        background-color: #ff7b7b;
      }

      button.btn.export {
        border: none;
        color: #fff;
        background-color: #07c700;
      }
    }

    .total-container {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;

      p {
        display: inline-flex;
        gap: 1rem;
        margin: 0;
        font-weight: bold;
        font-size: 16px;

        span {
          color: #ff0000;
          font-size: 16px;
        }
      }

      .inline-group {
        width: 100%;

        label,
        input {
          font-size: 1.5rem;
        }

        input {
          color: #ff0000;

          &::placeholder {
            color: #ff0000;
          }
        }
      }
    }
  }
}
