.department-inventory {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .department-inventory-header {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .add-item-form {
        padding: 2em;

        .search-item,
        .item-information,
        .item-lots-and-expiration {
          label {
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }

          .basic-search {
            .bg-grey.form-control {
              border-top-right-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
            }

            .btn.btn-search {
              margin-left: 1em !important;
              border-radius: 10px !important;
            }
          }

          .form-check {
            position: relative;

            input {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 0;
            }
          }

          h3 {
            font-weight: bold;
            font-size: 20px;
          }

          .selected-item {
            border-bottom: solid 1px #c8c8c8;
            padding-bottom: 1em;
          }

          .inline-group {
            display: flex;
            align-items: center;

            label,
            input,
            select,
            .locking-input {
              flex: 1;
            }

            .locking-input {
              display: flex;
              justify-content: space-between;
              gap: 1em;
            }
          }

          .buttonWrapper {
            display: flex;
            justify-content: flex-start;
            gap: 1em;
            height: 100%;
            align-items: flex-end;

            Button.btn {
              height: 50px;
              background: #02961c;
            }

            Button.btn.btn-secondary {
              background-color: #C8C8C8;
              border-color: #C8C8C8;
            }
          }

          .buttonWrapper.add-item {
            justify-content: flex-end;

            Button.btn {
              background-color: #b4b4b4;
              border-color: #b4b4b4;

              &.true {
                background-color: #01b425;
              }
            }
          }
        }

        .item-lots-and-expiration {
          h3 {
            font-size: 20px;
            font-weight: bold;
          }
        }

        .search-table {
          h3 {
            font-weight: bold;
            font-size: 1rem;
            color: #00a8ff;
            background-color: #fff;
            box-shadow: none;
          }

          .tableUI {
            border-top: none;

            .table {
              thead {
                tr {
                  th {
                    font-size: 12px;
                    width: 1ch;
                  }
                }
              }

              tbody {
                tr {
                  td {
                    font-size: 12px;

                    button {
                      padding: 0;
                      background-color: transparent;
                      color: #00a8ff;
                      border: none;
                      text-transform: capitalize;
                      font-weight: bold;
                      font-size: 15px;
                    }
                  }
                }
              }
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;

          .btn.add-button {
            background-color: #02961c;
            border: none;
          }

          .btn.clear-button {
            background-color: #b4b4b4;
            border: none;
          }
        }

        .advanced-search {
          .accordion-item {
            border: none;

            .accordion-header {
              .accordion-button {
                font-weight: bold;
                padding: 1rem 1.5rem;
                color: #e9ca00;
                background-color: #fff;
                box-shadow: none;
                border-bottom: 2px solid #e9ca00;

                &.collapsed {
                  color: #000;
                  border-bottom: none;

                  &::after {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                  }
                }

                &::after {
                  margin-right: auto;
                  position: absolute;
                  left: 0;
                  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23E9CA00'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
                }
              }
            }

            .accordion-body {
              padding: 1rem 0;

              .request-details {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .inline-group {
                  display: flex;
                  flex-wrap: nowrap;
                  align-items: center;

                  label {
                    flex: 2;
                    margin: 0 !important;
                    font-weight: bold;
                  }

                  input {
                    flex: 2;
                    color: #006397;

                    &::placeholder {
                      color: #00aeef;
                    }
                  }

                  input.no-border {
                    border: none;
                    padding: 0;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }

      .remove-request-form {
        padding: 2em;

        h3 {
          font-size: 1rem;
          font-weight: bold;
          text-align: center;
        }

        label {
          font-weight: bold;
        }

        input {
          border: solid 2px #707070;
        }

        .buttons {
          display: flex;
          gap: 1rem;
          justify-content: center;
          align-items: center;

          Button.btn.remove-button,
          Button.btn.no-button {
            background-color: #ff0000;
            border-color: #ff0000;
          }

          Button.btn.submit-button {
            background-color: #02961C;
            border-color: #02961C;
          }

          Button.btn.cancel-button {
            background-color: #707070;
            border-color: #707070;
          }

          Button.btn.yes-button {
            background-color: #24bb00;
            border-color: #24bb00;
          }
        }
      }
    }
  }
}
