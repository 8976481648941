.case-history {
  .buttonWrapper {
    .filter-button {
      margin-right: 1em;
      padding: 0.2em 0.5em;
      text-transform: capitalize;
    }
  }

  .case-history-list {
    .header-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 1rem;

      h5 {
        margin: 0;
        padding: 0;
      }
    }
  }
}
