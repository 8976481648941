.modal-content {
  .modal-header {
    background-color: #00aeef;

    .disbursement-header {
      padding: 1.2em;
      width: 100%;

      .header {
        justify-content: space-between;
        align-items: center;

        p {
          color: #fff;
          text-transform: uppercase;
          font-family: 'Poppins', sans-serif;
          font-size: 25px;
          margin: 0;
          font-weight: bold;
        }
      }
    }
  }

  .modal-body {
    padding:  20px 50px 50px;
    p {
      margin: 0;
      letter-spacing: 0;
      color: #5E5B5B;
    }

    span {
      color: #000000;
      font-weight: bold;
    }

    h5 {
      color: #000000;
      font-weight: bold;
    }

    .amount-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .label {
      font-size: 14px;
      text-transform: capitalize;
    }

    .danger {
      color: #FF0000;
    }

    .flex-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2em;
    }

    .payment-mode {
      width: 242px;
      height: 92px;
      color: #fff;

      background: #D5D5D5 0% 0% no-repeat padding-box;
      border-radius: 10px;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1em;

      &:hover {
        background: #00A8FF 0 0 no-repeat padding-box;
      }

      &.is-active {
        background: #00A8FF 0 0 no-repeat padding-box;
      }
    }

    .btn-payment {
      width: 100%;
      max-width: 200px;
      box-sizing: border-box;
    }
  }

}

