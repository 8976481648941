.credentials-container {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 50vh;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;

  .box-container {
    width: 100%;
    display: flex;
    padding: 1.5rem 2rem;
    background-color: #fff;
    gap: 2rem;

    .inline-group {
      gap: 3rem;
      display: flex;
      align-items: center;
      white-space: nowrap;

      label {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 1.3rem;
      }

      input.blue-control {
        border: none;
        height: 46px;
        background-color: #80d7f7;
        border-radius: 7px !important;

        &::placeholder {
          color: #00aeef;
        }

        &:focus,
        &:hover {
          box-shadow: none;
        }
      }
    }

    .employee-detail {
      gap: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
        padding: 0;
        color: #000;
        font-size: 1rem;
        font-weight: bold;
      }

      span {
        color: #00aeef;
      }
    }

    &.blue {
      background-color: #00aeef;
    }

    .website-logo {
      width: 5rem;
      height: 5rem;
    }

    .credentials-tabs {
      width: 100%;

      .tabsUI {
        .nav.nav-tabs {
          border: none;

          .nav-item {
            margin-right: 0.5em;
          }

          .nav-link {
            border: none;
            padding: 1em;
            font-size: 15px;
            color: #b4b4b4;
            font-weight: bold;
            padding-bottom: 0.5em;
            background-color: transparent;
          }

          .nav-link:hover,
          .nav-link:focus,
          .nav-link:active {
            border-color: transparent;
          }

          .nav-link.active {
            color: #00a8ff;
            border-color: #00a8ff;
            border: none;
            border-bottom: 4px solid #00a8ff;
            background-color: transparent;
          }
        }

        .tab-content {
          .tab-pane.active {
            padding: 0;
          }
        }
      }
    }

    .credentials-details {
      width: 100%;
      border-top: solid 2px #e9e9e9;

      .details-header {
        gap: 1.5rem;
        display: flex;
        padding: 2rem 0;
        justify-content: flex-start;

        p {
          margin: 0;
          padding: 0;
          color: #00AEEF;
          font-weight: bold;

          span {
            color: #000;
          }

          span.badge {
            display: inline-block;
            padding: 5px 4px;

            &.Approved {
              color: #047700;
              background-color: #07C700;
            }
          }
        }
      }

      .details-content {

        textarea.form-control,
        select.form-select {
          border-radius: 7px !important;
        }

        select.form-select {
          max-width: 20rem;
        }

        .main-content {
          gap: 1rem;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
  
          h5 {
            font-weight: bold;
            color:#00aeef;
          }
  
          .vertical-align {
            flex: 1;
  
            label {
              font-weight: bold;
            }
  
            input.border-seven {
              height: 46px;
              border-radius: 7px !important;
            }
          }
        }
      }

      .details-footer {
        gap: 1rem;
        display: flex;
        padding-top: 4rem;
        justify-content: flex-end;
        border-top: solid 2px #e9e9e9;

        button.btn.save {
          border-color: #07C700;
          background-color: #07C700;
        }

        button.btn.cancel {
          border-color: #C4C4C4;
          background-color: #C4C4C4;
        }
      }
    }
  }
}