.advances-table {
  width: 100%;
  border-top: solid 2px #e9e9e9;

  .main-table {
    .tableUI {
      border: none;

      table {
        margin: 0;

        thead {
          border-bottom: 1px solid #DEE2E6;

          tr {
            th {
              color: #00AEEF;
              background-color: #fff;
            }
          }
        }

        tbody {
          tr {
            td {
              .badge {
                font-size: 12px;

                &.green {
                  background: #07c700;
                }
              }

              .advance-actions {
                gap: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                button.btn.add-payroll {
                  width: 7rem;
                  height: 32px;
                  font-size: 8px;
                  border-radius: 7px;
                  border-color: #FF8282;
                  background-color: #FF8282;
                }
              }

              .avatar {
                width: 5rem;
                height: 5rem;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
}