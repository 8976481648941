.loans-container {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 50vh;
  align-items: flex-start;
  border-radius: 10px;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;

  .box-container {
    gap: 2rem;
    width: 100%;
    display: flex;
    padding: 1.5rem 2rem;
    background-color: #fff;
    justify-content: space-between;

    .inline-group {
      gap: 3rem;
      display: flex;
      align-items: center;

      label {
        margin: 0;
        padding: 0;
        color: #fff;
        font-size: 1.3rem;
        display: block;
        width: 100%;
      }

      input.blue-control {
        border: none;
        height: 46px;
        background-color: #80d7f7;
        border-radius: 7px !important;

        &::placeholder {
          color: #00aeef;
        }

        &:focus,
        &:hover {
          box-shadow: none;
        }
      }
    }

    .employee-detail {
      gap: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      p {
        margin: 0;
        padding: 0;
        color: #000;
        font-size: 1rem;
        font-weight: bold;
      }

      span {
        color: #00aeef;
      }
    }

    &.blue {
      background-color: #00aeef;
    }

    .website-logo {
      width: 5rem;
      height: 5rem;
    }

    button.btn.add-record-button {
      color: #00AEEF;
      border-color: #fff;
      background-color: #fff;
    }

    .view-record,
    .add-record {
      width: 100%;

      h4 {
        color: #00AEEF;
        font-weight: bold;
      }

      .inline-group {
        label {
          color: #000000;
          font-size: 1rem;
        }

        input.form-control {
          padding: 0;
          border: none;
          color: #00AEEF;


          &::placeholder {
            color: #00AEEF;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      .table-data {
        p.title {
          padding: 0;
          margin: 0;
          font-size: 1rem;
          color: #000;
        }

        .tableUI {
          border: 0;

          table {
            margin: 0;

            .advance-actions {
              display: flex;
              gap: 5px;

              button.btn {
                font-size: 12px;
                padding: 3px 5px;
                border-radius: 7px;

                &.accept {
                  border-color: #07C700;
                  background-color: #07C700;
                }

                &.decline {
                  border-color: #FF8282;
                  background-color: #FF8282;
                }
              }
            }

            .badge {

              &.green {
                color: #07C700;
              }

              &.red {
                color: #FF0000;
              }

              &.blue {
                color: #0080AF;
              }
            }
          }
        }
      }

      .content-footer {
        gap: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .back-button {
          border-color: #C4C4C4;
          background-color: #C4C4C4;
        }

        .save-button {
          border-color: #07C700;
          background-color: #07C700;
        }
      }
    }

    .add-record {
      input.blue-control {
        border-radius: 7px !important;

        &:focus,
        &:hover {
          box-shadow: none;
        }
      }

      .inline-check {
        display: flex;
        align-items: center;
        gap: 10px;

        label {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}