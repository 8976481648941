.shifting-view {
  .tabsUI {
    .nav.nav-tabs {
      border: none;

      .nav-item {
        margin-right: 0.5em;
      }

      .nav-link {
        color: #b4b4b4;
        font-size: 15px;
        background-color: transparent;
        border: none;
        padding: 1em;
      }

      .nav-link:hover,
      .nav-link:focus,
      .nav-link:active {
        border-color: transparent;
      }

      .nav-link.active {
        color: #00a8ff;
        border-color: #00a8ff;
        border: none;
        border-bottom: 4px solid #00a8ff;
        background-color: transparent;
      }
    }

    .tab-content {
      .tab-pane.active {
        padding: 0;
      }
    }
  }

  .view-shift {
    background: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0.125rem 60px rgb(0 0 0 / 8%);

    h3 {
      background-color: #00aeef;
      color: #fff;
      text-transform: uppercase;
      padding: 1rem 2rem;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      font-size: 20px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 15px;
        cursor: pointer;
        text-transform: capitalize;
      }
    }

    .hr {
      border-bottom: solid 1px #707070;
      width: 100%;
      height: 1px;
    }

    .employee-details {
      padding: 0 1rem;

      .search-btn {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        button.btn {
          height: 50px;
          border: none;
          background-color: #07c700;
        }
      }

      h5 {
        color: #00aeef;
        font-size: 20px;
      }

      h6 {
        color: #000000;
        font-size: 17px;
      }

      .inline-label {
        position: relative;
        width: 100%;

        label {
          position: absolute;
          background-color: #fff;
          font-size: 13px;
          top: -9px;
          left: 14px;
        }
      }

      .inline-label.width-80 {
        width: 80%;
        margin-left: auto;
      }

      .inline-label.width-70 {
        width: 70%;
      }

      .inline-label.width-50 {
        width: 50%;
      }

      .side {
        margin-left: 15rem;
      }

      .left-auto {
        margin-left: auto;
      }

      .right-auto {
        margin-right: auto;
      }

      .file-upload {
        input {
          display: none;
        }

        label {
          z-index: 99;
        }

        label.header-text {
          top: -19px;
        }

        .upload {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: relative;
          z-index: 10;
          border: solid 1px #b2b2b2;
          border-radius: 10px;
          height: 50px;
          left: 0;

          span {
            background-color: #07c700;
            border-radius: 0 10px 10px 0;
            color: #fff;
            padding: 0 2rem;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }
      }

      .profile-upload {
        display: flex;
        height: 100%;
        justify-content: center;

        input {
          display: none;
        }

        label {
          display: block;
          cursor: pointer;
          position: static;
        }
      }

      .buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;
        border-top: solid 1px #e4e4e4;
        padding-top: 1rem;

        button.btn.save-btn {
          background-color: #07c700;
          color: #fff;
          border-color: #07c700;
        }

        button.btn.cancel-btn {
          background-color: #c4c4c4;
          color: #fff;
          border-color: #c4c4c4;
        }
      }

      .search-wrapper {
        .input-group {
          input.form-control {
            max-width: unset;
          }
        }
      }
    }

    .employee-details.employee-information {
      img {
        width: 150px;
      }

      // .p-header {
      //   text-align: center;
      //   font-weight: bold;
      //   margin: 0;

      //   span {
      //     color: #00aeef;
      //     display: block;
      //     font-weight: normal;
      //   }
      // }

      .p-header {
        margin-top: 1rem;
        padding: 0;

        p {
          font-size: 30px;
          font-weight: bold;
          margin: 0;
        }

        span {
          color: #00aeef;
          display: block;
        }
      }

      .shifting-tab {
        padding: 1rem 2rem;

        p {
          margin: 0;
        }

        .tableUI {
          border-top: none;

          .table {
            margin-top: 5px;
          }
        }
      }
    }

    .employee-details.employee-information.shifting-information {
      img {
        border-radius: 10px;
      }
    }
  }
}