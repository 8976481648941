.add-doctor-status-table {
  .tableUI {
    .table {
      tbody {
        tr {
          .btn.view-result {
            background-color: #07c900;
            color: #ffff;
            border-radius: 7px;
            font-size: 12px;
            padding: 2px 10px;
            border: none;
          }

          span {
            display: block;
            background-color: red;
            color: #fff;
            text-align: center;
            padding: 2px 10px;
            border-radius: 7px;

            &.pending {
              background-color: #cbb000;
            }

            &.approved {
              background-color: #07c900;
            }
          }

          span.awaiting-bed {
            background-color: #E2BD00;
            cursor: pointer;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 1em;
      align-items: center;

      button.active {
        background-color: #0069f2;
      }

      button {
        background-color: #c8c8c8;
        border: none;
        height: min-content;
      }
    }
  }
}
