.request-laboratory-table {
  .tableUI {
    .table {
      tbody {
        tr {
          td:nth-of-type(2) {
            font-weight: bold;
          }

          td:nth-of-type(3) {
            font-weight: bold;
          }

          td:nth-of-type(7) {
            font-weight: bold;
          }

          span {
            display: block;
            background-color: red;
            color: #fff;
            text-align: center;
            padding: 2px 0;
            border-radius: 7px;

            &.PENDING {
              background-color: #CBB000;
            }

            &.COMPLETED {
              background-color: #07C900;
            }
          }
        }
      }
    }
  }
}