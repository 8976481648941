.cashier-tabs {
  .tabsUI {
    .nav.nav-tabs {
      border-radius: 5px;

      .nav-item {
        margin-right: 0.5em;
      }

      .nav-link {
        color: #fff;
        font-size: 15px;
        background-color: #B4B4B4;
        border-radius: 5px;
      }

      .nav-link:hover,
      .nav-link:focus,
      .nav-link:active {
        border-color: transparent;
      }

      .nav-link.active {
        color: #fff;
        font-size: 15px;
        border-radius: 5px;
        background-color: #0079C9;
      }
    }

    .tab-content {
      .tab-pane.active {
        padding: 0;
      }
    }
  }
}