.add-template-modal {
  .modal-dialog.modal-lg {
    max-width: 1200px;
  }

  .modal-content {
    border-radius: 7px !important;

    .modal-body {
      padding: 0;
      border-radius: 7px !important;

      .add-template-request {
        padding: 2em;
        background-color: #00a8ff;

        .header {
          justify-content: space-between;
          align-items: center;

          p {
            color: #fff;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            font-size: 25px;
            margin: 0;
            font-weight: bold;
          }
        }
      }

      .add-template-form {
        padding: 2em;

        .search-item {
          min-height: 20em;

          .buttonWrapper {
            display: flex;
            justify-content: flex-start;
            gap: 1em;
            height: 100%;
            align-items: center;
          }

          label {
            font-family: 'Poppins', sans-serif;
            font-weight: bold;
          }

          .basic-search {
            .bg-grey.form-control {
              border-top-right-radius: 10px !important;
              border-bottom-right-radius: 10px !important;
            }

            .btn.btn-search {
              margin-left: 1em !important;
              border-radius: 10px !important;
            }
          }
        }

        .buttons-cont {
          display: flex;
          justify-content: center;
          gap: 1em;
          align-items: flex-end;

          .btn.add-button {
            background-color: #00aeef;
            border: none;
            height: 50px;
          }

          .btn.add-text {
            background-color: #ff8282;
            border: none;
            height: 50px;
          }
        }

        .footer-buttons-cont {
          display: flex;
          justify-content: flex-end;
          gap: 1em;
          align-items: flex-end;

          .btn.add-button {
            background-color: #07c700;
            border: none;
            height: 50px;
          }

          .btn.cancel-button {
            background-color: #c4c4c4;
            border: none;
            height: 50px;
          }
        }
      }
    }
  }
}
