.layout {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;

  & > .layout__body {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #f4f6fd;
  }

  @media (max-width: 991px) {
    position: relative;
  }
}

#gradient-header {
  position: absolute;
  margin-top: 10px;
  left: 2rem;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
