@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.add-room-cont {
    .buttonWrapper {
        display: flex;
        justify-content: flex-end;
        gap: 1em;
    }

    label {
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
    }

    .basic-search {
        .bg-grey.form-control {
            border-top-right-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
            width: auto;
        }

        .btn.btn-search {
            margin-left: 1em !important;
            border-radius: 10px !important;
        }

        .input-group-text {
            border: 1px solid #d4d4d4;
            border-right: none;
            border-radius: 10px;
            background: #eeeeee;

            svg {
                width: 20px;
            }
        }
    }

    .advanced-search {
        .accordion-item {
            border: none;

            .accordion-header {
                .accordion-button {
                    font-weight: bold;
                    padding: 1rem 1.5rem;
                    color: #00a8ff;
                    background-color: #fff;
                    box-shadow: none;
                    border-bottom: 2px solid #000;

                    &.collapsed {
                        color: #000;
                        border-bottom: none;
                    }

                    &::after {
                        margin-right: auto;
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }

    .form-check {
        position: relative;

        input {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 0;
        }
    }

    .category-buttons {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 1rem;

        .btn.btn-secondary,
        .btn.btn-primary {
            border: none;
            height: 45px;
            padding: 0 1em;
            font-weight: 600;
            font-size: 14px;
        }

        .btn.btn-primary.add-button {
            background-color: #6BC9DD;
            ;
        }

        .btn.btn-secondary.reset-button {
            background-color: #B4B4B4;
        }
    }

    h2 {
        font-size: 20px;
        font-weight: bold;
        border-bottom: none;
        padding-bottom: 10px;

        &.header {
            border-bottom: 1px solid #00a8ff;
            padding-bottom: 10px;
        }
    }
}