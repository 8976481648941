@font-face {
  font-family: 'PoppinsExtraBold';
  src: url('../../../assets/fonts/Poppins-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

.topbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  left: 0;
  display: flex;
  align-items: center;
  height: 74px;
  padding: 1em 1.5em 1em 0;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(83, 83, 83, 0.2);
  z-index: 1000;

  .topbar__header {
    max-width: 300px;
    width: 100%;
    text-align: center;

    .topbar__brand {
      color: #1f9c95;
      font-family: 'PoppinsExtraBold', sans-serif;
      font-size: 24px;
    }
  }

  .user-btn {
    margin-right: 1em;
    background-color: #6ac9de;
    border-radius: 100%;
    padding: 8px 1px 8px 9px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.30);

    .user-account {
      margin-left: 0;
    }

    .userSVG {
      width: 28px;
    }

    #circle-user {
      fill: #fff;
    }

  }

  &>button {
    margin: 0;
    padding: 1em;
    margin: 1.5em;
    border: none;
    background: none;
    box-shadow: none;
    outline: none;

    &>svg:nth-of-type(1) {
      width: 30px;
      fill: #6ac9de;
    }

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
      outline: none;
    }
  }

  &>.input-group {
    &>.input-group-text {
      border: 1px solid #d4d4d4;
      border-right: none;
      border-radius: 10px;
      background: none;

      svg {
        width: 20px;
      }
    }

    &>.form-control {
      width: 100%;
      max-width: 280px;
      padding: 0.5em 1em 0.5em 0;
      border: 1px solid #d4d4d4;
      border-left: none;
      border-radius: 10px;

      &::placeholder {
        color: #d4d4d4;
      }

      &:focus,
      &:hover {
        background: none;
        box-shadow: none;
      }
    }
  }

  &>svg {
    width: 40px;
    fill: #6ac9de;
  }

  Button.btn.btn-primary {

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
      outline: none;
    }
  }

  .search-container {
    max-width: 350px;
    width: 100%;
    margin-left: 6em;

    .magnifying-background {
      background-color: #6ac9de;
      border-radius: 100px;
      padding: 1px 6px;

      .magnifying {
        fill: white;
      }

      #magnifying-glass {
        opacity: 1;
      }
    }

    width: 300px;

  }

  @media (max-width: 1200px) {
    padding: 10px;

    &>button {
      margin: 0;
    }

    Button.btn.btn-primary {
      margin-right: 10px;

      svg {
        width: 25px;
      }
    }

    .search-container {
      width: 250px;

      .select-search {
        span {
          font-size: 14px;
        }
      }

      // . css-6j8wv5-Input
    }
  }

  @media (max-width: 565px) {
    .search-container {
      width: 180px;
    }
  }
}