.basic-search-container {
  position: relative;

  .basicSearch {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;

    // .buttonWrapper {
    //   display: flex;
    //   align-items: center;
    //   background-color: red;

    //   & > button {
    //     margin-left: 1em;
    //     background-color: green !important;
    //   }
    // }
  }
}
