.filterUI {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;

  .form-label {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    line-height: 100%;
  }

  .form-control,
  .form-select {
    padding: 0.75em 1em;
    color: #404040;
    border-color: #c8c8c8;
    border-radius: 10px;

    &:hover,
    &:focus,
    &:active {
      border-color: #c8c8c8;
      box-shadow: none;
    }

    &::placeholder {
      color: #c8c8c8;
    }
  }

  .form-check {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    & > input {
      width: 20px;
      height: 20px;
      margin-bottom: 0;

      &:hover,
      &:focus,
      &:active {
        border-color: #c8c8c8;
        box-shadow: none;
      }
    }

    & > label {
      margin: 0;
      color: #404040;
      font-size: 14px;
      line-height: 100%;
    }
  }

  .input-group {
    flex-wrap: nowrap;

    & > .input-group-text {
      border: 1px solid #d4d4d4;
      border-right: none;
      border-radius: 10px;
      background: #ffffff;

      svg {
        width: 20px;
      }
    }

    & > .form-control {
      padding: 0.75em 1em 0.75em 0;
      border: 1px solid #d4d4d4;
      border-left: none;
      border-radius: 10px;
      background: #ffffff;

      &::placeholder {
        color: #d4d4d4;
      }

      &:focus,
      &:hover {
        background: #ffffff;
        box-shadow: none;
      }
    }
  }
}
