.deleteModal-confirmation {
  .modal-content {
    .modal-header {
      background-color: #00aeef;
    }

    .modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      button {
        flex: 1;
      }
    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 32px;
    }

    &.btn.remove {
      background-color: #ff7280;
    }

    &.btn.approve {
      background-color: #35A100;
    }

    &.btn.cancel {
      background-color: #707070;
    }
  }
}
