.confirmation-component-modal {
  .modal-content {
    .modal-header {
      background-color: #00aeef;

      button {
        padding: 0;

        &:focus,
        &:hover {
          border: none;
          background: none;
          box-shadow: none;
        }
      }
    }

    .modal-body {
      display: block;
      width: 100%;

      button {
        flex: 1;
        padding: 10px 20px;
      }

      button.primary {
        background-color: #0069F2;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
      }

      .fields {
        label {
          text-transform: capitalize;
        }
      }
    }
  }

  h6 {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
  }

  button {
    border: none;
    background: none;
    text-transform: capitalize;

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none;
    }

    svg {
      width: 32px;
    }

    &.btn.remove {
      background-color: #ff7280;
    }

    &.btn.approve {
      background-color: #35a100;
    }

    &.btn.cancel {
      background-color: #707070;
    }
  }
}
