.billingHistoryTable {
  .tableUI {
    border: none;

    .table {
      thead {
        tr {
          th {
          }
        }
      }

      tbody {
        tr {
          td {
            .table-buttons {
              display: flex;
              gap: 5px;

              button {
                border: none;
                height: 2rem;
                font-size: 13px;
                padding: 5px 10px;
              }

              button.btn.export {
                background-color: #009714;
              }

              button.btn.print {
                background-color: #ff7474;
              }
            }
          }

          td:nth-of-type(3) {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.check {
  cursor: pointer;
  fill: #00c41a !important;
}
